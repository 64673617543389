import { Box } from "@mui/material";
import React, { useState } from "react";
import DrawerAdminComponent from "../components/drawer_admin";
import TopBarAdmin from "../components/top_bar_admin";
import { useSelector } from "react-redux";
import CompleteRegister from "./complete_register";

export default function RequestsAdmin() {
  const userData = useSelector((state) => state.auth.userData);

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <CompleteRegister />
    </Box>
  );
}
