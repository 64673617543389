import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  ListItemButton,
} from "@mui/material";
// import DrawerComponent from "../components/drawer_component";
import {
  AccessTimeTwoTone,
  ArrowRight,
  AttachMoneyTwoTone,
  DiscountTwoTone,
  GroupTwoTone,
  PersonTwoTone,
  PointOfSaleTwoTone,
  Receipt,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import ProfileBankData from "../components/profile_bank_data";
import ProfilePersonalData from "../components/profile_personal_data";
import ProfileProfesionalData from "../components/profile_profesional_data";
import SecretariesComponent from "../components/secretaries_componet";
//===============================================Secretaries Importations =========================================================
import DialogNewSecretary from "../components/dialog_new_secretary";
import Sales from "../components/sales_component";
import AddDialog from "../components/dialog_sale";
import TopBar from "../components/top_bar";
import Schedule from "../components/schedule";
import ValueHour from "../components/value_hour";
import SettingsPos from "../components/settings_pos";
import SettingsSII from "../components/settings_sii";
import FireNav from "../components/fire_nav"; // Asegúrate de importar correctamente
import NavPaper from "../components/nav_paper"; // Import NavPaper component
import { UpdateSalesProvider } from "../context/UpdateSales";
import { UpdateSecretariesProvider } from "../context/UpdateSrecretaries";

// import NewDrawerComponent from "../components/new_drawer";
import { OpenChangeImageProvider } from "../context/OpenChangeImage";

export default function Administration() {
  const [viewByRole, setViewByRole] = useState([]);
  const dataDoctor = useSelector((state) => state.auth.userData);
  // console.log("object", dataDoctor);
  // const [route, setRoute] = useState(
  //   dataDoctor.role === "doctor" && !dataDoctor.medicalCenterId
  //     ? "personal"
  //     : dataDoctor.role === "medicalCenter"
  //     ? "secretaries"
  //     : "schedule"
  // );
  const [route, setRoute] = useState(
    dataDoctor.medicalCenter || dataDoctor.role === "secretary"
      ? "schedule"
      : "personal"
  );
  const [openSecretariesDialog, setOpenSecretariesDialog] = useState(false);
  const [openSalesDialog, setOpenSalesDialog] = useState(false);
  const [type, setType] = useState("");
  const [salesData, setSalesData] = useState();
  const [secretarieData, setSecretarieData] = useState();
  const [typeSecretaries, setTypeSecretaries] = useState("");
  const [showList, setShowList] = useState(true);

  const handleOpenDialog = () => setOpenSecretariesDialog(true);
  const handleCloseDialog = () => setOpenSecretariesDialog(false);
  const handleOpenSalesDialog = () => setOpenSalesDialog(true);
  const handleCloseSalesDialog = () => setOpenSalesDialog(false);
  const [role, setRole] = useState("");
  const [changeImage, setChangeImage] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const puntoDeQuiebre = 1300;
  const breakVariable = windowWidth >= puntoDeQuiebre;

  const rolesMenus = {
    doctor: [
      { value: "personal", label: "Datos personales", icon: <PersonTwoTone /> },
      { value: "bank", label: "Datos bancarios", icon: <AttachMoneyTwoTone /> },
      {
        value: "profesional",
        label: "Datos profesionales",
        icon: <PointOfSaleTwoTone />,
      },
      { value: "secretaries", label: "Secretarias", icon: <GroupTwoTone /> },
      { value: "sales", label: "Ofertas", icon: <DiscountTwoTone /> },
      { value: "schedule", label: "Horario", icon: <AccessTimeTwoTone /> },
      {
        value: "valueHour",
        label: "Valor de Reserva",
        icon: <AttachMoneyTwoTone />,
      },
      { value: "settingsSII", label: "Configuración SII", icon: <Receipt /> },
    ],
    centerMedical: [
      { value: "personal", label: "Datos personales", icon: <PersonTwoTone /> },
      { value: "bank", label: "Datos bancarios", icon: <AttachMoneyTwoTone /> },
      {
        value: "profesional",
        label: "Datos profesionales",
        icon: <PointOfSaleTwoTone />,
      },
      { value: "secretaries", label: "Secretarias", icon: <GroupTwoTone /> },
      { value: "sales", label: "Ofertas", icon: <DiscountTwoTone /> },
      { value: "settingsSII", label: "Configuración SII", icon: <Receipt /> },
    ],
    secretary: [
      { value: "secretaries", label: "Secretarias", icon: <GroupTwoTone /> },
      { value: "sales", label: "Ofertas", icon: <DiscountTwoTone /> },
      { value: "schedule", label: "Horario", icon: <AccessTimeTwoTone /> },
      {
        value: "valueHour",
        label: "Valor de Reserva",
        icon: <AttachMoneyTwoTone />,
      },
    ],
    medicalCenterSecretary: [
      { value: "sales", label: "Ofertas", icon: <DiscountTwoTone /> },
      { value: "schedule", label: "Horario", icon: <AccessTimeTwoTone /> },
      {
        value: "valueHour",
        label: "Valor de Reserva",
        icon: <AttachMoneyTwoTone />,
      },
    ],
    medicalCenterDoctor: [
      { value: "personal", label: "Datos personales", icon: <PersonTwoTone /> },
      { value: "bank", label: "Datos bancarios", icon: <AttachMoneyTwoTone /> },
      {
        value: "profesional",
        label: "Datos profesionales",
        icon: <PointOfSaleTwoTone />,
      },
      { value: "schedule", label: "Horario", icon: <AccessTimeTwoTone /> },
      {
        value: "valueHour",
        label: "Valor de Reserva",
        icon: <AttachMoneyTwoTone />,
      },
      { value: "settingsSII", label: "Configuración SII", icon: <Receipt /> },
    ],
  };
  useEffect(() => {
    const { role, medicalCenterId, medicalCenter } = dataDoctor;
    let assignedRole = "";

    if (role === "doctor") {
      assignedRole = medicalCenterId ? "medicalCenterDoctor" : "doctor";
    } else if (role === "secretary") {
      assignedRole = medicalCenter ? "medicalCenterSecretary" : "secretary";
    } else if (role === "medicalCenter") {
      assignedRole = "centerMedical";
    }

    setRole(assignedRole);
    setViewByRole(rolesMenus[assignedRole]);
  }, [dataDoctor]);

  const menuItems = useMemo(() => viewByRole, [viewByRole]);

  const handleRouteChange = (newRoute) => {
    setRoute(newRoute); // Assuming setRoute is defined somewhere
    if (window.innerWidth <= 960) {
      setShowList(false); // Assuming setShowList is defined somewhere
    }
  };
  const paperNav = (
    <List
      sx={{
        p: 2,
      }}
    >
      <Typography variant="subtitle2">Perfil</Typography>
      <FireNav variant="secondary">
        {menuItems.slice(0, 3).map((item) => (
          <ListItemButton
            key={item.value}
            onClick={() => handleRouteChange(item.value)}
            selected={route === item.value}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              sx={{ cursor: "pointer", paddingLeft: { md: "0", lg: "20px" } }}
              primary={item.label}
            />
            {route === item.value && (
              <ListItemIcon sx={{ minWidth: "40px", color: "#77F8E0" }}>
                <ArrowRight />
              </ListItemIcon>
            )}
          </ListItemButton>
        ))}
      </FireNav>
      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Getmed
      </Typography>
      <FireNav component="nav" variant="secondary">
        {menuItems.slice(3).map((item) => (
          <ListItemButton
            key={item.value}
            onClick={() => handleRouteChange(item.value)}
            selected={route === item.value}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              sx={{ cursor: "pointer", paddingLeft: { md: "0", lg: "20px" } }}
              primary={item.label}
            />
            {route === item.value && (
              <ListItemIcon sx={{ minWidth: "40px", color: "#77F8E0" }}>
                <ArrowRight />
              </ListItemIcon>
            )}
          </ListItemButton>
        ))}
      </FireNav>
    </List>
  );

  const paperContentHeader = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <Typography variant="titleLarge">
        {menuItems.find((item) => item.value === route)?.label || ""}
      </Typography>
    </Box>
  );

  const paperContent = (
    <Box
      sx={{
        height: "100%",
        p: 0,
        overflowY: "auto",
        scrollbarWidth: "thin",
        position: "relative",
      }}
    >
      {route === "personal" ? (
        <ProfilePersonalData />
      ) : route === "bank" ? (
        <ProfileBankData />
      ) : (
        route === "profesional" && <ProfileProfesionalData />
      )}
      {route === "secretaries" && (
        <SecretariesComponent
          handleOpenDialog={handleOpenDialog}
          setType={setType}
          setSecretarieData={setSecretarieData}
        />
      )}
      {route === "sales" && (
        <Sales
          handleOpenSalesDialog={handleOpenSalesDialog}
          setSalesData={setSalesData}
          setType={setTypeSecretaries}
        />
      )}
      {route === "schedule" && <Schedule />}
      {route === "valueHour" && <ValueHour />}
      {route === "settingsPOS" && <SettingsPos />}
      {route === "settingsSII" && <SettingsSII />}
    </Box>
  );

  return (
    <UpdateSecretariesProvider>
      <UpdateSalesProvider>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            p: 0,
          }}
        >
          <OpenChangeImageProvider>
            <NavPaper
              paperTitle={<Typography variant="h6">Administración</Typography>}
              paperNav={paperNav}
              paperContentHeader={paperContentHeader}
              paperContent={paperContent}
              showList={showList}
              setShowList={setShowList}
            />

            <DialogNewSecretary
              open={openSecretariesDialog}
              close={handleCloseDialog}
              type={type}
              data={secretarieData}
            />
            <AddDialog
              open={openSalesDialog}
              close={handleCloseSalesDialog}
              data={salesData}
              type={typeSecretaries}
            />
          </OpenChangeImageProvider>
        </Box>
      </UpdateSalesProvider>
    </UpdateSecretariesProvider>
  );
}
