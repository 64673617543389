import { Container, Typography } from "@mui/material";
import React from "react";
import { capitalize } from "../utils/functions";

export default function AdminViewDataComponent({ doctorSelected }) {
  return (
    <Container
      sx={{
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #DCE3E9",
          padding: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#71787D",
            fontSize: "1em",
          }}
        >
          RUT
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "1em",
          }}
        >
          {doctorSelected.rut}
        </Typography>
      </Container>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #DCE3E9",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#71787D",
            fontSize: "1em",
          }}
        >
          Nombre
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "1em",
          }}
        >
          {doctorSelected.name}
        </Typography>
      </Container>
      {doctorSelected.role !== "medicalCenter" && (
        <>
          {" "}
          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottom: "1px solid #DCE3E9",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Typography
              sx={{
                color: "#71787D",
                fontSize: "1em",
              }}
            >
              Apellido
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: "1em",
              }}
            >
              {doctorSelected.lastName}
            </Typography>
          </Container>
          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottom: "1px solid #DCE3E9",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Typography
              sx={{
                color: "#71787D",
                fontSize: "1em",
              }}
            >
              Fecha de nacimiento
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: "1em",
              }}
            >
              {doctorSelected.birthdate}
            </Typography>
          </Container>
          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottom: "1px solid #DCE3E9",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Typography
              sx={{
                color: "#71787D",
                fontSize: "1em",
              }}
            >
              Género
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: "1em",
              }}
            >
              {doctorSelected.genre}
            </Typography>
          </Container>
        </>
      )}

      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #DCE3E9",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#71787D",
            fontSize: "1em",
          }}
        >
          Email
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "1em",
          }}
        >
          {doctorSelected.email}
        </Typography>
      </Container>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #DCE3E9",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#71787D",
            fontSize: "1em",
          }}
        >
          Fecha de creación
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "1em",
          }}
        >
          {doctorSelected.createdAt.toDate().toLocaleDateString()}
        </Typography>
      </Container>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #DCE3E9",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#71787D",
            fontSize: "1em",
          }}
        >
          Descripción
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "1em",
            fontStyle: "italic",
          }}
        >
          "{capitalize(doctorSelected.docDescription)}"
        </Typography>
      </Container>
    </Container>
  );
}
