import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

export default function DialogMedicalData({ data, open, close }) {
  const keysFields = [
    {
      label: "allergies",
      text: "Alergias",
    },
    // {
    //     label: 'antecedents',
    //     text: 'Antecedentes'
    // },
    // {
    //     label: 'armpitTemp',
    //     text: 'Temperatura axilar'
    // },
    {
      label: "cardiacFrequency",
      text: "Frecuencia cardiaca",
    },
    {
      label: "diastolicPressure",
      text: "Presión diastólica",
    },
    {
      label: "evaScale",
      text: "Escala Eva",
    },
    {
      label: "glasgow",
      text: "Glasgow",
    },
    {
      label: "hemoGlucose",
      text: "Hemoglucosa",
    },
    {
      label: "interconsult",
      text: "Interconsulta",
    },
    {
      label: "oxygenSaturation",
      text: "Saturación de oxígeno",
    },
    {
      label: "pulse",
      text: "Pulso",
    },
    {
      label: "respiratoryFrequency",
      text: "Frecuencia respiratoria",
    },
    {
      label: "systolicPressure",
      text: "Presión sistólica",
    },
    {
      label: "weight",
      text: "Peso",
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={close}
      sx={{ zIndex: "2000" }}
      PaperProps={{
        style: {
          backgroundColor: "#EEE8F4",
          borderRadius: "25px",
          width: "80vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0px",
          margin: "0px",
        },
      }}
    >
      <DialogContent
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flex: 1, padding: "20px", width: "100%" }}>
          <Typography variant="h5" sx={{ color: "black" }}>
            Datos médicos
          </Typography>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              width: "100%",
            }}
          >
            {keysFields.map((field, index) => (
              <ListItemText
                key={index}
                primaryTypographyProps={{
                  fontSize: "13px",
                }}
                secondaryTypographyProps={{
                  color: "black",
                }}
                primary={field.text}
                sx={{
                  color: "#5A5A5A",
                  margin: 0,
                  marginBottom: 1,
                  padding: 0,
                }}
                secondary={data[field.label] ? data[field.label] : "N/A"}
              />
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
