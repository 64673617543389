import {
  Container,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Avatar,
} from "@mui/material";
import Swal from "sweetalert2";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useState } from "react";
import { useDispatch } from "react-redux";

import loadingSVG from "../assets/logo_Loading.gif";

export default function DialogSelectDoctor({ open, close, data, userData }) {
  const dispatch = useDispatch();

  const handleClose = (e) => {
    if (e.type !== "click") {
      close(false);
    }
  };

  const handleViewSecretari = (e) => {
    if (e.firstTime) {
      Swal.fire({
        title: "¡Atención!",
        text: "El doctor no ha completado su información, por favor, espere a que lo haga.",
        icon: "warning",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#006686",
      });
    } else {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      if (userData.idDoctor === e.id) {
        Swal.close();
        Swal.fire({
          title: "¡Opss..!",
          text: "Ya se encuentra seleccionado este profesional.",
          icon: "info",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#006686",
        });
      } else {
        const updatedUserData = {
          ...userData,
          idDoctor: e.id,
          userSelected: e,
        };

        dispatch({
          type: "UPDATE_USER_DATA",
          payload: updatedUserData,
        });
        Swal.close();
        Swal.fire({
          title: "¡Éxito!",
          text: "Se ha cambiado de profesional correctamente.",
          icon: "success",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#006686",
        }).then(() => {
          close(false);

          window.location.reload(true);
        });
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(e) => handleClose(e)}
        fullScreen
        disableEscapeKeyDown={true}
        // hideBackdrop={true}
        // oncl
        // fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#EEE8F4",
            borderRadius: "25px 25px 25px 25px",
            width: "30%",
            heigh: "auto",
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "black",
            fontSize: "20px",
            fontWeight: 300,
            display: "flex",
            flexDirection: "row",
          }}
        >
          Seleccione un profesional para Continuar
        </DialogTitle>
        <Container sx={{ marginTop: "20px" }}>
          <List sx={{ width: "100%" }} className="arrayDoctorsContainer">
            <ListItem sx={{ width: "100%", display: "flex", flex: "start" }}>
              <ListItemText
                primary="Profesionales"
                primaryTypographyProps={{
                  color: "#006686",
                  fontSize: "17px",
                }}
              />
            </ListItem>
            {data.length > 0 &&
              data.map((doctor, index) => (
                <ListItem
                  button
                  key={index}
                  sx={{
                    backgroundColor: "#F3F4F6",
                    border: "1px solid rgba(192, 199, 205, 1)",
                    borderRadius: "25px",
                    // height: "15%",
                    width: "100%",
                    marginTop: "1vh",
                    marginBottom: "1vh",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    // gridTemplateColumns: "auto 1fr", // Columna para el avatar y para el contenido
                    gap: "10px", // Espacio entre las áreas
                    padding: "10px",
                  }}
                  onClick={() => {
                    handleViewSecretari(doctor);
                  }}
                >
                  <Avatar src={doctor.imageProfile ? doctor.imageProfile : ""}>
                    {doctor.name.charAt(0) + doctor.lastName.charAt(0)}
                  </Avatar>
                  <ListItemText
                    primary={doctor.name}
                    secondary={
                      doctor.mainSpecialty
                        ? doctor.mainSpecialty
                        : "Sin especialidad"
                    }
                    sx={{ marginLeft: "10px" }}
                  />
                  <KeyboardArrowRight />
                </ListItem>
              ))}
          </List>
        </Container>
      </Dialog>
      {/* <DialogCompletedRequest loadData={loadData} open={openComplete} close={setOpenComplete} data={data} cerrar={close} handleOpened={handleOpened} /> */}
    </>
  );
}
