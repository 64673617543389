import React from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Hidden,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsOutlined from "@mui/icons-material/NotificationsOutlined";
import { useSelector } from "react-redux";
import { capitalizeAll } from "../utils/functions";

const AppBarHeader = ({
  title,
  handleDrawerToggle,
  appBarHeight,
  drawerWidth,
}) => {
  const dataDoctor = useSelector((state) => state.auth.userData);
  // console.log("dataDoctor", dataDoctor);
  if (!dataDoctor) {
    return null; // O puedes retornar un componente de carga o algún contenido alternativo
  }

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        width: { lg: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: "rgb(192, 232, 255)",
        height: `${appBarHeight}px`,
      }}
    >
      <Toolbar>
        <IconButton
          color="primary"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="titleLarge" noWrap component="div">
          {title}
        </Typography>
        {dataDoctor.role === "secretary" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: "auto",
            }}
          >
            <Avatar
              sx={{
                mr: 1,
              }}
              src={
                dataDoctor.role === "doctor"
                  ? dataDoctor?.imageProfile
                  : dataDoctor.role === "medicalCenter"
                  ? dataDoctor?.imageProfile
                  : dataDoctor?.image
              }
            />
            <Hidden mdDown>
              <p
                style={{
                  color: "#006686",
                  fontWeight: "bold",
                }}
              >
                {dataDoctor.role === "doctor"
                  ? `Dr. ${capitalizeAll(dataDoctor?.lastName)}`
                  : `${capitalizeAll(dataDoctor?.name)}`}
              </p>
            </Hidden>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

AppBarHeader.propTypes = {
  title: PropTypes.string.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  drawerWidth: PropTypes.number.isRequired,
  appBarHeight: PropTypes.number.isRequired,
};

export default AppBarHeader;
