import { Download } from "@mui/icons-material";
import {
  Avatar,
  Container,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

export default function MessageChat({
  isMine,
  message,
  date,
  type,
  user,
  data,
}) {
  const [fileName, setFileName] = useState("");
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (type === "file" || type === "image") {
      const storageRef = firebase.storage().refFromURL(message);

      storageRef
        .getMetadata()
        .then((metadata) => {
          const extension = metadata.contentType.split("/")[1];
          setFileName(metadata.name + "." + extension);
        })
        .catch((error) => {
          console.error(
            "Error al obtener la meta información del archivo:",
            error
          );
        });
    }
  }, [message, type]);

  const handleDownload = () => {
    const storageRef = firebase.storage().refFromURL(message);

    storageRef
      .getDownloadURL()
      .then((url) => {
        window.open(url, "_blank");
      })
      .catch((error) => {
        console.error("Error al descargar el archivo:", error);
      });
  };

  return (
    <div
      style={{
        width: "100%",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Container
        key={date}
        sx={
          isMine
            ? {
                display: "flex",
                justifyContent: "flex-end",
                alignSelf: "flex-end",
              }
            : type === "initMessage"
            ? {
                display: "flex",
                justifyContent: "flex-start",
                alignSelf: "flex-start",
              }
            : {
                display: "flex",
                justifyContent: "flex-start",
                alignSelf: "flex-start",
              }
        }
      >
        {user === "client" ? (
          <Paper
            elevation={10}
            sx={{
              backgroundColor: "#ffffff",
              color: "#003547",
              border: "0.5px solid #C0C7CD",
              borderRadius: "16px",
              my: "7px",
              padding: "8px",
              display: "inline-block",
              maxWidth: "50vw",
              wordWrap: "break-word",
            }}
            style={{ boxShadow: "none" }}
          >
            {type === "text" ? (
              message
            ) : type === "file" ? (
              <Container sx={{ display: "flex" }}>
                <p>{fileName}</p>
                <IconButton onClick={handleDownload}>
                  <Download />
                </IconButton>
              </Container>
            ) : type === "image" ? (
              <Container
                sx={{
                  width: "300px",
                  maxHeight: "300px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={message}
                  alt=""
                  onClick={handleDownload}
                  style={{
                    cursor: "pointer",
                    borderRadius: "5px",
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Container>
            ) : type === "newExam" ? (
              <div
                onClick={() => {
                  console.log(data);
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <Paper
                  elevation={10}
                  sx={{
                    cursor: "pointer",
                    borderRadius: "16px",
                    color: "#006686",
                    display: "inline-block",
                    maxWidth: "50vw",
                    fontWeight: "bold",
                    wordWrap: "break-word",
                  }}
                  style={{ boxShadow: "none" }}
                >
                  {message}
                </Paper>
              </div>
            ) : (
              <>...</>
            )}
          </Paper>
        ) : (
          user === "doctor" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <Paper
                elevation={10}
                sx={{
                  backgroundColor: "#006686",
                  color: "#FFFFFF",
                  border: "0.5px solid #C0C7CD",
                  borderRadius: "16px",
                  my: "7px",
                  padding: "8px",
                  display: "inline-block",
                  maxWidth: "50vw",
                  wordWrap: "break-word",
                }}
                style={{ boxShadow: "none" }}
              >
                {type === "text" ? (
                  message
                ) : type === "file" ? (
                  <Container sx={{ display: "flex" }}>
                    <p>{fileName}</p>
                    <IconButton onClick={handleDownload}>
                      <Download />
                    </IconButton>
                  </Container>
                ) : type === "image" ? (
                  <Container
                    sx={{
                      width: "300px",
                      maxHeight: "300px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={message}
                      alt=""
                      onClick={handleDownload}
                      style={{
                        cursor: "pointer",
                        borderRadius: "5px",
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Container>
                ) : (
                  <>Hola</>
                )}
              </Paper>
            </div>
          )
        )}
      </Container>
      {hovered && (
        <div
          style={
            isMine
              ? {
                  display: "flex",
                  justifyContent: "flex-end",
                  alignSelf: "flex-end",
                  marginRight: "30px",
                }
              : type === "initMessage"
              ? {
                  display: "flex",
                  justifyContent: "flex-start",
                  alignSelf: "flex-start",
                }
              : {
                  display: "flex",
                  justifyContent: "flex-start",
                  alignSelf: "flex-start",
                  marginLeft: "30px",
                }
          }
        >
          <p
            style={{
              fontSize: "0.8em",
              color: "#C0C7CD",
              margin: "0",
            }}
          >
            {date.toDate().toLocaleDateString()} a las{" "}
            {date.toDate().toLocaleTimeString()}
          </p>
        </div>
      )}
    </div>
  );
}
