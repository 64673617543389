import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import FingerprintOutlinedIcon from "@mui/icons-material/FingerprintOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import { CalendarMonthOutlined, CircleOutlined } from "@mui/icons-material";
import NumbersOutlinedIcon from "@mui/icons-material/NumbersOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import langEs from "date-fns/locale/es";
import moment from "moment";
import { createRegisterRequest } from "../database/register_request";
import PersonIcon from "@mui/icons-material/Person";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RegisterClinic from "./registerClinic";
import { countrys } from "../utils/countries";
import logo from "../assets/logo.svg";

export default function Register() {
  const [rut, setRut] = useState("");
  const [numeroDeSerieCedula, setNumeroDeSerieCedula] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [sex, setSex] = useState("");
  const [nationality, setNationality] = useState("");
  const [email, setEmail] = useState("");
  const [registerNumber, setRegisterNumber] = useState("");
  const [password, setPassword] = useState("");

  const validarRutChileno = (rut) => {
    // Elimina caracteres no válidos y convierte a mayúsculas
    try {
      const cleanRut = rut.replace(/[^\dKk]/g, "").toUpperCase();
      if (!/^\d{7,8}[0-9Kk]{1}$/.test(cleanRut)) return false; // Verifica el formato

      const rutDigits = cleanRut.slice(0, -1);
      const verificador = cleanRut.slice(-1);

      // Convierte 'K' en 10
      const digitoVerificador =
        verificador === "K" ? 10 : parseInt(verificador);

      // Calcula el dígito verificador esperado
      const factor = [2, 3, 4, 5, 6, 7, 2, 3];
      let suma = 0;
      for (let i = rutDigits.length - 1, j = 0; i >= 0; i--, j++) {
        suma += parseInt(rutDigits[i]) * factor[j];
      }
      const resto = suma % 11;
      const dv = 11 - resto;

      if (dv === digitoVerificador) return cleanRut;

      return {
        clean: cleanRut,
        boolean: false,
      };
    } catch (error) {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "El RUT ingresado no es válido. Por favor, verifíquelo.",
      });
      setRut("");
    }
  };

  const navigate = useNavigate();

  const mostrar = async () => {
    const validarContraseña = (contraseña) => {
      const longitudValida = contraseña.length >= 6;

      const contieneMayuscula = /[A-Z]/.test(contraseña);
      const contieneMinuscula = /[a-z]/.test(contraseña);
      let a = longitudValida && contieneMayuscula && contieneMinuscula;
      return longitudValida && contieneMayuscula && contieneMinuscula;
    };

    let rutClean = validarRutChileno(rut);
    if (rutClean === false) {
      Swal.fire({
        icon: "info",
        title: "Error en el RUT",
        text: "El RUT ingresado no es válido. Por favor, ingréselo correctamente.",
      });
      setRut("");
    }
    setRut(
      rutClean === false ? "" : rutClean.clean ? rutClean.clean : rutClean
    );
    if (rutClean.boolean === false || rutClean === false) {
      Swal.fire({
        icon: "info",
        title: "Error en el RUT",
        text: "El RUT ingresado no es válido. Por favor, ingréselo correctamente.",
      });
    } else {
      const isEmpty = (value, fieldName) => {
        if (value === "" || value === 0) {
          dataCount.push(fieldName);
          return true;
        }
        return false;
      };

      const dataCount = [];
      isEmpty(rut, "Rut");
      isEmpty(numeroDeSerieCedula, "Número de serie de cedula");
      isEmpty(email, "Correo");
      isEmpty(name, "Nombre");
      isEmpty(lastName, "Apellido");
      isEmpty(birthdate, "Fecha de nacimiento");
      isEmpty(sex, "Género");
      isEmpty(nationality, "Nacionalidad");
      isEmpty(registerNumber, "Número de registro");
      isEmpty(password, "Contraseña");
      let contraseñaValida = validarContraseña(password);

      if (dataCount.length > 0) {
        Swal.fire({
          icon: "info",
          title: "Campos vacíos",
          text: "Por favor complete los campos: " + dataCount.join(", "),
        });
      } else {
        let request = {
          rut: rut,
          serialNumber: numeroDeSerieCedula,
          email: email,
          name: name,
          lastName: lastName,
          birthdate: birthdate,
          genre: sex,
          nationality: nationality,
          registerNumber: registerNumber,
          password: password,
        };

        if (contraseñaValida === false) {
          Swal.fire({
            icon: "info",
            title: "Contraseña poco segura",
            text: "Por favor, ingrese una contraseña con más de seis carácteres, minimo una mayuscula y una minuscula.",
          });
        } else {
          const reqSend = await createRegisterRequest(request);
          if (reqSend) {
            navigate("/");
            Swal.fire({
              icon: "success",
              title: "Registro completado",
              text: "Estamos revisando sus datos, por favor espere mientras se realiza la verificación.",
            });
          } else {
            navigate("/");
            Swal.fire({
              icon: "error",
              title: "Registro no completado",
              text: "Se ha producido un error durante el proceso de registro. Por favor, inténtelo nuevamente más tarde.",
            });
          }
        }
      }
    }
  };

  const [showPass, setShowPass] = useState("password");
  const [handleFrom, setHandleFrom] = useState(true);
  const handleClickShowPassword = () => setShowPass((show) => !show);
  // const checkDuplicates = () => {
  //     const uniqueNames = [...new Set(countris)];
  //     uniqueNames.sort(); // Ordena alfabéticamente
  //     setCountris(uniqueNames); // Actualiza el estado con la lista de nombres únicos y ordenados
  //     console.log("2", countris);
  //     return uniqueNames; // Devuelve la lista de nombres únicos y ordenados
  // };
  function ProfesionalDiv() {
    return (
      <>
        <TextField
          margin="normal"
          required
          placeholder="Rut"
          value={rut}
          type="text"
          onChange={(e) => setRut(e.target.value)}
          variant="filled"
          endad
          autoComplete="rut"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FingerprintOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="normal"
          required
          placeholder="Número de serie de cedula de indentidad"
          type="text"
          onChange={(e) => setNumeroDeSerieCedula(e.target.value)}
          variant="filled"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BadgeOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="filled"
          margin="normal"
          required
          placeholder="Nombre"
          type="text"
          autoComplete="name"
          fullWidth
          onChange={(e) => {
            let eventName = e.target.value
              .toLowerCase()
              .normalize("NFD")
              .replace(
                /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
                "$1"
              );
            setName(eventName);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="normal"
          required
          placeholder="Apellido"
          type="text"
          autoComplete="lastName"
          onChange={(e) => {
            let eventLastName = e.target.value
              .toLowerCase()
              .normalize("NFD")
              .replace(
                /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
                "$1"
              );
            setLastName(eventLastName);
          }}
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />

        <LocalizationProvider
          adapterLocale={langEs}
          dateAdapter={AdapterDateFns}
        >
          <DatePicker
            sx={{
              marginTop: "10px",
            }}
            margin="normal"
            required
            hiddenLabel
            placeholder="Fecha de nacimiento"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarMonthOutlined />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setBirthdate(moment(e).format("YYYY-MM-DD"));
            }}
          />
        </LocalizationProvider>

        <Select
          sx={{
            backgroundColor: "#DEE3EB",
            marginTop: "20px",
            height: "50px",
          }}
          margin="normal"
          defaultValue={0}
          required
          variant="standard"
          startAdornment={
            <InputAdornment position="start">
              <CircleOutlined />
            </InputAdornment>
          }
          onChange={(e) => setSex(e.target.value)}
        >
          <MenuItem value={0}>Seleccione género</MenuItem>
          <MenuItem value={"Masculino"}>Masculino</MenuItem>
          <MenuItem value={"Femenino"}>Femenino</MenuItem>
          <MenuItem value={"Otro"}>Prefiero no decirlo</MenuItem>
        </Select>
        <Select
          sx={{
            width: "80%",
            backgroundColor: "#DEE3EB",
            marginTop: "20px",
            height: "50px",
          }}
          margin="normal"
          required
          variant="standard"
          label="Nacionalidad"
          startAdornment={
            <InputAdornment position="start">
              <FlagOutlinedIcon />
            </InputAdornment>
          }
          defaultValue={0}
          onChange={(e) => setNationality(e.target.value)}
        >
          <MenuItem value={0}>Seleccione nacionalidad</MenuItem>

          {countrys.map((e, i) => (
            <MenuItem value={e || ""} key={i}>
              {e.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
        <TextField
          sx={{
            width: "80%",
            backgroundColor: "#DEE3EB",
          }}
          margin="normal"
          required
          hiddenLabel
          placeholder="Correo Electrónico"
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon />
              </InputAdornment>
            ),
            style: {
              fontSize: "1.2em", // Cambia el tamaño de la fuente
              padding: "3%", // Aumenta el padding
            },
          }}
          inputProps={{
            style: {
              height: "90%", // Aumenta la altura
            },
          }}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          sx={{
            width: "80%",
            backgroundColor: "#DEE3EB",
          }}
          type={showPass ? "password" : "text"}
          margin="normal"
          required
          hiddenLabel
          placeholder="Contraseña"
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PasswordOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment>
                <IconButton onClick={handleClickShowPassword}>
                  {showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          sx={{
            width: "80%",
            backgroundColor: "#DEE3EB",
          }}
          margin="normal"
          required
          hiddenLabel
          placeholder="Número de registro"
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <NumbersOutlinedIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setRegisterNumber(e.target.value)}
        />
      </>
    );
  }
  const handleRadioChange = (event) => {
    if (event.target.value === "pro") {
      setHandleFrom(true);
    } else if (event.target.value === "admin") {
      setHandleFrom(false);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 2, // Ajusta el padding según sea necesario
        }}
      >
        <Box
          component="img"
          src={logo}
          sx={{
            height: "50px",
          }}
        />
      </Box>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#FFFFFF",
              borderRadius: "28px",
              px: { xs: 2, md: 4, lg: 6 },
              py: 4,
              transition: "all 0.3s",
            }}
          >
            <Typography component="h1" variant="h5">
              Registrarme
            </Typography>

            <Box
              sx={{
                display: "flex",
                py: 2,
                mt: 2,
              }}
            >
              <Typography variant="titleSmall">Tipo de cuenta</Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                mb: 1,
              }}
            >
              <RadioGroup
                aria-label="gender"
                name="gender1"
                row
                onChange={handleRadioChange}
                defaultValue={"pro"}
              >
                <FormControlLabel
                  value="pro"
                  control={<Radio />}
                  label="Profesional"
                />
                <FormControlLabel
                  value="admin"
                  control={<Radio />}
                  label="Administrativa"
                />

                {/* <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" /> */}
              </RadioGroup>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Typography variant="body">
                *Las cuentas administrativas están destinadas a un colectivo de
                profesionales.
              </Typography>
            </Box>

            {handleFrom ? (
              <>
                <TextField
                  margin="normal"
                  required
                  placeholder="Rut"
                  value={rut}
                  type="text"
                  onChange={(e) => setRut(e.target.value)}
                  variant="filled"
                  endad
                  fullWidth
                  autoComplete="rut"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FingerprintOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 12,
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  placeholder="Número de serie de cedula de indentidad"
                  type="text"
                  onChange={(e) => setNumeroDeSerieCedula(e.target.value)}
                  variant="filled"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BadgeOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{}}
                />
                <TextField
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  placeholder="Nombre"
                  type="text"
                  autoComplete="name"
                  onChange={(e) => {
                    let eventName = e.target.value
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(
                        /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
                        "$1"
                      );
                    setName(eventName);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{}}
                />
                <TextField
                  margin="normal"
                  required
                  placeholder="Apellido"
                  type="text"
                  autoComplete="lastName"
                  fullWidth
                  onChange={(e) => {
                    let eventLastName = e.target.value
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(
                        /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
                        "$1"
                      );
                    setLastName(eventLastName);
                  }}
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <LocalizationProvider
                  adapterLocale={langEs}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    sx={{
                      marginTop: "10px",
                    }}
                    margin="normal"
                    required
                    hiddenLabel
                    placeholder="Fecha de nacimiento"
                    variant="filled"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarMonthOutlined />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setBirthdate(moment(e).format("YYYY-MM-DD"));
                    }}
                  />
                </LocalizationProvider>

                <Select
                  sx={{
                    marginTop: "16px",
                  }}
                  margin="normal"
                  defaultValue={0}
                  required
                  variant="filled"
                  startAdornment={
                    <InputAdornment position="start">
                      <CircleOutlined />
                    </InputAdornment>
                  }
                  onChange={(e) => setSex(e.target.value)}
                >
                  <MenuItem value={0}>Seleccione género</MenuItem>
                  <MenuItem value={"Hombre"}>Masculino</MenuItem>
                  <MenuItem value={"Mujer"}>Femenino</MenuItem>
                  <MenuItem value={"Otro"}>Prefiero no decirlo</MenuItem>
                </Select>
                <Select
                  sx={{
                    marginTop: "16px",
                  }}
                  margin="normal"
                  required
                  variant="filled"
                  label="Nacionalidad"
                  startAdornment={
                    <InputAdornment position="start">
                      <FlagOutlinedIcon />
                    </InputAdornment>
                  }
                  defaultValue={0}
                  onChange={(e) => setNationality(e.target.value)}
                >
                  <MenuItem value={0}>Seleccione nacionalidad</MenuItem>

                  {countrys.map((e, i) => (
                    <MenuItem value={e || ""} key={i}>
                      {e.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  margin="normal"
                  required
                  hiddenLabel
                  placeholder="Correo Electrónico"
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  type={showPass ? "password" : "text"}
                  margin="normal"
                  required
                  hiddenLabel
                  placeholder="Contraseña"
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={handleClickShowPassword}>
                          {showPass ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  hiddenLabel
                  placeholder="Número de registro"
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <NumbersOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setRegisterNumber(e.target.value)}
                />

                <Button onClick={mostrar} variant="contained" disableElevation>
                  REGISTRARME
                </Button>
              </>
            ) : (
              <RegisterClinic />
            )}

            <Button
              type="text"
              onClick={() => {
                navigate("/login");
              }}
            >
              Ya tengo una cuenta
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
