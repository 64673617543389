import { db } from "./config";
import { collection, getDocs,serverTimestamp,addDoc,query } from 'firebase/firestore'

export async function getTypes() {
    const types = await getDocs(collection(db, 'type'));
    return types.docs.map(doc => doc.data());
}
export async function getOperations() {
    const operationType = await getDocs(collection(db, 'operationType'));
    return operationType.docs.map(doc => doc.data());
}

export async function createTypeDoc(dataTypeDoc) {
    dataTypeDoc.createdAt = serverTimestamp()
    const queryData = query(collection(db, 'type'));
    await addDoc(queryData, dataTypeDoc);
    return true;
}
export async function createOperationData(operationType) {
    operationType.createdAt = serverTimestamp()
    const queryData = query(collection(db, 'operationType'));
    await addDoc(queryData, operationType);
    return true;
}