import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Uploader from "./image_uploader_component";
import Loading from "./loading_component";
import { createSecretary, updateSecretary } from "../database/secretaries";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
import Files from "react-files";
import { useSelector } from "react-redux";
import { useUpdateSecretaries } from "../context/UpdateSrecretaries";

export default function DialogNewSecretary({ open, close, myId, data, type }) {
  const { updateSecretariesAsync } = useUpdateSecretaries();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const [image, setImage] = useState(null);
  const [imageView, setImageView] = useState(null);

  const [imageEdit, setImageEdit] = useState(null);
  const [imageUpdate, setImageUpdate] = useState(null);

  const doctorId = useSelector((state) => state.auth.idUser);
  const role = useSelector((state) => state.auth.userData.role);
  const userData = useSelector((state) => state.auth.userData);

  useEffect(() => {
    if (type === "edit") {
      setName(data.name);
      setPhone(data.phone);
      setEmail(data.email);
      setLastName(data.lastName);
      setImageEdit(data.image ? data.image : null);
    }
  }, [data]);

  const handleEditImageChange = (e) => {
    let file = e[0];
    let vista = file.preview.url;
    if (type === "edit") {
      setImageEdit(vista);
      setImageUpdate(file);
    } else {
      setImage(file);
      setImageView(file.preview.url);
    }
  };

  const clearData = async () => {
    setImage(null);
    setName("");
    setPhone("");
    setEmail("");
    setLastName("");
    setPassword("");
    setImageView(null);
    setImageEdit(null);
  };
  const styleImage = {
    display: "flex",
    width: "100%",
    height: "30vh",
    padding: "0px 164px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    background: "white",
    border: "1px dashed #006686",
    color: "white",
    borderRadius: "25px 25px 25px 25px",
    cursor: "pointer",
    marginTop: "20px",
    marginBottom: "20px",
  };

  const createSecretaryFunc = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 200,
      imageHeight: 200,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    const secretaryData = {
      name: name,
      lastName: lastName,
      phone: phone,
      email: email,
      image: image,
      idDoctor: doctorId,
      password: password,
      role: role === "medicalCenter" ? "medicalCenter" : null,
      ...(role === "medicalCenter" ? { medicalCenter: doctorId } : {}),
    };

    let validate = [];
    if (secretaryData.name === "") {
      validate.push("Nombre");
    }
    if (secretaryData.lastName === "") {
      validate.push("Apellido");
    }
    if (secretaryData.phone === "") {
      validate.push("Numero de telefono");
    }
    if (secretaryData.password === "") {
      validate.push("Contraseña");
    }
    if (secretaryData.email === "") {
      validate.push("Correo");
    }

    if (validate.length > 0) {
      Swal.close();

      Swal.fire({
        icon: "warning",
        title: "Campos incompletos",
        text: `Por favor complete el/los campo(s): ${validate.join(", ")}`,
        zIndex: "9000",
      });
    } else if (secretaryData.password.length < 6) {
      Swal.close();

      Swal.fire({
        icon: "warning",
        title: "Contraseña demasiado corta",
        text: `La contraseña debe tener al menos 6 caracteres`,
        zIndex: "3000",
      });
    } else if (secretaryData.image === null) {
      Swal.close();
      Swal.fire({
        icon: "warning",
        title: "Sin imagen",
        text: `¿Estás seguro que quiere ingresar esta/e secretaria/o sin imagen de perfil?`,
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No, no guardar",
        confirmButtonColor: "#146C94",
        cancelButtonColor: "#D7283A",
        // zIndex: "3000",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.close();
          Swal.fire({
            imageUrl: loadingSVG,
            imageWidth: 200,
            imageHeight: 200,
            title: "Cargando...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            zIndex: "3000",
          });
          const response = await createSecretary(secretaryData);
          if (!response) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Secretaria creada",
              text: "La secretaria fue creada con exito",
            }).then(() => {
              close();
              updateSecretariesAsync();
              clearData();
            });
          }
          if (
            response.data ===
            "Failed to create user: Error: The email address is already in use by another account."
          ) {
            Swal.close();
            Swal.fire({
              icon: "info",
              title: "Email en uso",
              text: "Porfavor verifique que el correo ingresado no se encuentra en uso.",
              zIndex: "3000",
            });
            if (response.data) {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Secretaria creada",
                text: "La secretaria fue creada con exito",
              }).then(() => {
                close();
                updateSecretariesAsync();
                clearData();
              });
            }
          } else if (response) {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "No se pudo ingresar la/el secretaria/o",
              zIndex: "3000",
            });
            // console.log("response2", response.data);
          }
        } else {
          close();
          clearData();
          Swal.close();
        }
      });
    } else {
      Swal.close();
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 200,
        imageHeight: 200,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        zIndex: "3000",
      });
      const response = await createSecretary(secretaryData);
      if (!response) {
        // clearData();
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Secretaria creada",
          text: "La secretaria fue creada con exito",
          zIndex: "3000",
        }).then(() => {
          close();
          updateSecretariesAsync();
          clearData();
        });
        // console.log("response3", response);
      } else {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudo ingresar la/el secretaria/o",
          zIndex: "3000",
        });
        // console.log("response3", response);
      }
    }

    // const
  };

  const editSecretary = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 200,
      imageHeight: 200,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    const secretaryData = {
      name: name,
      lastName: lastName,
      phone: phone,
      email: email,
      image: imageUpdate,
      idDoctor: doctorId,
    };
    let validate = [];
    if (secretaryData.name === "") {
      validate.push("Nombre");
    }
    if (secretaryData.lastName === "") {
      validate.push("Apellido");
    }
    if (secretaryData.phone === "") {
      validate.push("Numero de telefono");
    }
    if (secretaryData.email === "") {
      validate.push("Correo");
    }

    if (validate.length > 0) {
      Swal.close();
      Swal.fire({
        icon: "warning",
        title: "Campos incompletos",
        text: `Por favor complete el/los campo(s): ${validate.join(", ")}`,
        zIndex: "9000",
      });
    } else {
      let response = await updateSecretary(data.id, secretaryData);
      if (response) {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Secretaria editada",
          text: "La secretaria fue editada con exito",
          zIndex: "3000",
        }).then(() => {
          close();
          updateSecretariesAsync();
          clearData();
        });
      } else {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudo editar la/el secretaria/o",
          zIndex: "3000",
        });
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        sx={{ zIndex: "2000" }}
        PaperProps={{
          style: {
            backgroundColor: "#EEE8F4",
            borderRadius: "25px 25px 25px 25px",
            display: "flex",
            width: "744px",
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <DialogContent>
          <Container
            sx={{
              display: "flex",
              width: "500px",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                alignSelf: "stretch",
                fontSize: "20px",
                mb: 3,
              }}
            >
              {type === "edit"
                ? "Editar secretaria (o)"
                : "Crear nueva(o) secretaria(o)"}
            </Typography>
            <TextField
              fullWidth
              required
              autoFocus
              margin="dense"
              id="name"
              label="Nombre"
              value={name}
              type="text"
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
            />
            {/* <br /> */}
            <TextField
              fullWidth
              required
              margin="dense"
              id="lastName"
              label="Apellido"
              value={lastName}
              type="text"
              variant="outlined"
              inputProps={{ maxLength: 140 }}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              fullWidth
              required
              margin="dense"
              id="phone"
              label="Teléfono"
              value={phone}
              type="phone"
              variant="outlined"
              inputProps={{ maxLength: 140 }}
              onChange={(e) => setPhone(e.target.value)}
            />
            <TextField
              fullWidth
              required
              margin="dense"
              id="email"
              label="Correo"
              value={email}
              type="email"
              variant="outlined"
              inputProps={{ maxLength: 140 }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              required
              margin="dense"
              id="pass"
              label="Contraseña"
              value={type === "edit" ? "************************" : password}
              type="password"
              variant="outlined"
              inputProps={{ maxLength: 8 }}
              onChange={(e) => setPassword(e.target.value)}
              disabled={type === "edit" ? true : false}
            />
            <br />
            {type === "edit" ? (
              <Container sx={styleImage}>
                <br />

                {imageEdit !== null ? (
                  <img
                    style={{
                      objectFit: "contain",
                      width: "25vw",
                      height: "25vh",
                    }}
                    className="files-gallery-item"
                    key={imageEdit}
                    src={type === "edit" ? imageEdit : imageView}
                    alt="photo"
                    onClick={() => {
                      setImageEdit(null);
                      // setImageView(null);
                    }}
                  />
                ) : (
                  <Container sx={styleImage}>
                    <Files
                      className="files-dropzone"
                      onChange={(e) => {
                        handleEditImageChange(e);
                      }}
                      // onError={handleError}
                      accepts={["image/*"]}
                      clickable
                      style={{
                        width: "60%",
                        height: "27vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "white",
                        color: "#006686",
                        borderRadius: "25px 25px 25px 25px",
                        cursor: "pointer",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                      maxFiles={1}
                    >
                      Arrastre o haga click para seleccionar
                    </Files>
                  </Container>
                )}
              </Container>
            ) : image === null ? (
              <Container sx={styleImage}>
                <Files
                  className="files-dropzone"
                  onChange={(e) => {
                    handleEditImageChange(e);
                  }}
                  // onError={handleError}
                  accepts={["image/*"]}
                  clickable
                  style={{
                    width: "60%",
                    height: "27vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "white",
                    color: "#006686",
                    borderRadius: "25px 25px 25px 25px",
                    cursor: "pointer",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  maxFiles={1}
                >
                  Arrastre o haga click para seleccionar
                </Files>
              </Container>
            ) : (
              <Container sx={styleImage}>
                <br />
                <img
                  style={{
                    objectFit: "contain",
                    width: "25vw",
                    height: "25vh",
                  }}
                  className="files-gallery-item"
                  key={imageView}
                  src={imageView}
                  alt="photo"
                  onClick={() => {
                    setImage(null);
                    setImageView(null);
                  }}
                />
              </Container>
            )}
          </Container>
          <br />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="text"
            sx={{
              backgroundColor: "transparent",
              color: "#006686",
            }}
            onClick={() => {
              clearData();
              close();
              setImage(null);
            }}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            sx={{
              borderRadius: "100px",
              background: "#006686",
              color: "#77F8E0",
            }}
            onClick={async () => {
              if (type === "edit") {
                setLoading(true);
                await editSecretary();

                setLoading(false);
              } else {
                try {
                  setLoading(true);
                  createSecretaryFunc();

                  setLoading(false);
                } catch (error) {
                  console.log(error);
                }
              }
            }}
          >
            {type === "edit" ? "Editar secretaria" : "Crear secretaria"}
          </Button>
        </DialogActions>
        <Loading loadingOn={loading} loadingOff={() => setLoading(false)} />
      </Dialog>
    </>
  );
}
