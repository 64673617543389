import { db } from "./config";
import {
  collection,
  setDoc,
  addDoc,
  doc,
  serverTimestamp,
  getDocs,
  getDoc,
  updateDoc,
  deleteField,
  where,
  query,
  deleteDoc,
} from "firebase/firestore";
import "firebase/compat/storage";

export async function updateSchedule(day, newSchedule, userId) {
  try {
    const docRef = doc(db, "schedules", userId);
    const docSnap = await getDoc(docRef);
    const scheduleExists =
      docSnap.exists() &&
      docSnap.data().schedule &&
      docSnap.data().schedule[day];

    if (scheduleExists) {
      await updateDoc(docRef, { [`schedule.${day}`]: newSchedule });
    } else {
      await setDoc(
        docRef,
        { idDoctor: userId, schedule: { [day]: newSchedule } },
        { merge: true }
      );
    }

    return true;
  } catch (error) {
    console.error("Error updating document: ", error);
    return error;
  }
}
export async function deleteScheduleDay(day, userId) {
  try {
    const docRef = doc(db, "schedules", userId);
    await updateDoc(docRef, { [`schedule.${day}`]: deleteField() });
    return true;
  } catch (error) {
    console.error("Error deleting schedule day: ", error);
    return error;
  }
}
export async function UploadSchedule(data, userId) {
  try {
    const docRef = doc(collection(db, "schedules"), userId);
    await setDoc(docRef, { ...data, createdAt: serverTimestamp() });
    return true;
  } catch (error) {
    return error;
  }
}
export async function getSchedules(userId) {
  try {
    const docRef = doc(db, "schedules", userId);
    // console.log("docRef",docRef);
    const docSnap = await getDoc(docRef);
    // console.log("docSnap",docSnap);
    if (docSnap.exists()) {
      return {
        id: docSnap.id,
        ...docSnap.data(),
      };
    } else {
      return null;
    }
  } catch (error) {
    return error;
  }
}

export async function deleteScheduleByDay(idDoctor, idDocument) {
  try {
    const documentRef = doc(db, `schedules/${idDoctor}/schedules`, idDocument);
    await deleteDoc(documentRef);
    return true;
  } catch (error) {
    console.error("Error deleting schedule: ", error);
    return false;
  }
}

export async function addHourToSchedule(day, idDoctor, data) {
  // console.log("addHourToSchedule", day, idDoctor, data);
  try {
    const docRef = doc(db, "schedules", idDoctor);
    const docSnap = await getDoc(docRef);
    const scheduleExists = docSnap.exists();
    const parentDocRef = doc(db, "schedules", idDoctor);
    const dateObject = new Date(day);
    dateObject.setUTCDate(dateObject.getUTCDate() + 1);
    // const newDateObject = new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate());
    await setDoc(parentDocRef, {
      status: "active",
      idDoctor: idDoctor,
      createdAt: serverTimestamp(),
    });
    const messagesCollectionRef = collection(parentDocRef, "schedules");
    await addDoc(messagesCollectionRef, {
      day: day,
      hours: data,
      dayFormatted: dateObject,
    });

    return true;
  } catch (error) {
    console.error("Error updating document: ", error);
    return error;
  }
}

export async function updateHourToSchedule(day, idDoctor, data, idDocument) {
  // console.log("updateData", data);
  try {
    // Obtener la referencia al documento que se va a actualizar
    const documentRef = doc(db, `schedules/${idDoctor}/schedules`, idDocument);

    // Obtener los datos actuales del documento
    const docSnap = await getDoc(documentRef);
    if (!docSnap.exists()) {
      console.error("El documento especificado no existe.");
      return false;
    }

    // Actualizar el documento con los datos modificados
    await updateDoc(documentRef, {
      hours: data,
    });

    return true;
  } catch (error) {
    console.error("Error updating document: ", error);
    return false;
  }
}

export async function getNewSchedule(idUser) {
  const now = new Date();
  // console.log("buscando horarios", idUser);
  const queryData = query(
    collection(db, "schedules", idUser, "schedules"),
    where("dayFormatted", ">", now)
  );
  const types = await getDocs(queryData);
  // console.log("retorna horarios", types.docs.length);
  return types.docs.map((doc) => ({
    id: doc.id,
    title: "Horario del día",
    start: doc.data().day,
    data: { id: doc.id, ...doc.data() },
  }));
}

export async function getHoursFree(day, idDoctor) {
  const formattedDay = new Date(day).toISOString().split("T")[0];
  const queryData = query(
    collection(db, "schedules", idDoctor, "schedules"),
    where("day", "==", formattedDay)
  );

  const querySnapshot = await getDocs(queryData);
  // console.log("querySnapshot", querySnapshot.docs[0].data());
  if (querySnapshot.empty) {
    return [];
  } else {
    const hoursFree = querySnapshot.docs[0]
      .data()
      .hours.filter((hour) => hour.status === "free");
    return hoursFree;
  }
}
export async function getHoursReserved(day, idDoctor) {
  const queryData = query(
    collection(db, "schedules", idDoctor, "schedules"),
    where("day", "==", day)
  );
}
