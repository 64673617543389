import React, { useState } from "react";
// import NewDrawerComponent from "../components/new_drawer";
import TopBar from "../components/top_bar";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  List,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { getDoctorsByCenter, updateDoctor } from "../database/doctors";
import { getClinicById } from "../database/clinic";

import {
  Typography,
  ListItem,
  Avatar,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import userMaleImage from "../assets/user_male_light.png";
import userFemaleImage from "../assets/user_female_light.png";
import loadingSVG from "../assets/logo_Loading.gif";
import { capitalizeAll } from "../utils/functions";
import {
  ArrowRight,
  Circle,
  DeleteOutline,
  ImageOutlined,
} from "@mui/icons-material";
import imageNull from "../assets/request_select.svg";
import Swal from "sweetalert2";
import { getOperations, getTypes } from "../database/types";

export default function Professionals() {
  const dataDoctor = useSelector((state) => state.auth.userData);
  const myId = useSelector((state) => state.auth.idUser);
  const [myDoctors, setMyDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Datos personales");

  const [docName, setDocName] = useState("");
  const [docLastName, setDocLastName] = useState("");
  const [docEmail, setDocEmail] = useState("");
  const [docDescription, setDocDescription] = useState("");
  const [docAddress, setDocAddress] = useState("");

  const [rut, setRut] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bank, setBank] = useState("");
  const [bankTypeAccount, setBankTypeAccount] = useState("");

  //Data DB
  const [allOperations, setAllOperations] = useState([]);
  const [allSpecialty, setAllSpecialty] = useState([]);

  //Handler local array
  const [localOperations, setLocalOperations] = useState([]);
  const [localSpecialties, setLocalSpecialties] = useState([]);

  const [operationSelectedForSelect, setOperationSelectedForSelect] =
    useState("1");
  const [specialtySelectedForSelect, setSpecialtySelectedForSelect] =
    useState("1");

  const deleteOperation = (index) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Deseas eliminar esta operación?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006686",
      cancelButtonColor: "grey",
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        const tempOperations = localOperations.filter((el, i) => i !== index);
        setLocalOperations(tempOperations);
      }
    });
  };

  const clear = () => {
    setDocDescription("");
    setDocAddress("");
    setBank("");
    setBankTypeAccount("");
    setAccountNumber("");
    setValue("");
    setLocalOperations([]);
    setLocalSpecialties([]);
    setSelectedTab("Datos personales");
  };

  const savePersonalData = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    const data = {
      name: docName,
      lastName: docLastName,
      docDescription: docDescription,
    };

    const update = await updateDoctor(data, selectedDoctor.id);
    if (update) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Actualizado",
        text: "Se han actualizado los datos correctamente",
      });
    } else {
      Swal.close();

      Swal.fire({
        icon: "error",
        title: "Upss..",
        text: "Se ha producido un error al momento de actualizar los datos",
      });
    }
  };

  const updateProfesionalData = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    const countEmpty = [];
    const countUndefined = [];
    let cleanLocalOperations = localOperations
      .filter((item) => item.value !== undefined)
      .map((item) => ({ value: item.value }));

    let cleanLocalSpecialties = localSpecialties
      .filter((item) => item.value !== undefined)
      .map((item) => ({ value: item.value }));

    const data = {
      operationsList: cleanLocalOperations,
      typeDoctor: cleanLocalSpecialties,
      valueHour: value,
    };

    if (cleanLocalOperations.length === 0) {
      countEmpty.push("Operaciones");
    }

    if (cleanLocalSpecialties.length === 0) {
      countEmpty.push("Especialidades");
    }

    if (countUndefined.length === 2) {
      Swal.fire({
        icon: "info",
        title: "No se han realizado cambios",
        // text:"No se ralizo ningun cambio en los datos personales"
      });
    } else if (countEmpty.length > 0) {
      Swal.fire({
        icon: "info",
        title: "Campos vacios",
        text: "Por favor complete los campos: " + countEmpty.join(", "),
      });
    } else {
      const update = await updateDoctor(data, selectedDoctor.id);
      if (update) {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Actualizado",
          text: "Se han actualizado los datos correctamente",
        });
      } else {
        Swal.close();

        Swal.fire({
          icon: "error",
          title: "Upss..",
          text: "Se ha producido un error al momento de actualizar los datos",
        });
      }
    }
  };
  const deleteSpecialty = (index) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Deseas eliminar esta especialidad?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006686",
      cancelButtonColor: "grey",
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        const tempSpecialties = localSpecialties.filter((el, i) => i !== index);
        setLocalSpecialties(tempSpecialties);
      }
    });
  };

  const handleNewSpecialty = (event) => {
    const newSpecialty = {
      label: event.target.value,
      value: event.target.value,
    };

    try {
      setLocalSpecialties((prevSpecialties) => [
        ...prevSpecialties,
        newSpecialty,
      ]);
      setAllSpecialty((allSpecialty) =>
        allSpecialty.filter((item) => item.name !== event.target.value)
      );
      setSpecialtySelectedForSelect("1");
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewOperation = (event) => {
    const newOperation = {
      label: event.target.value,
      value: event.target.value,
    };

    try {
      setLocalOperations((prevOperations) => [...prevOperations, newOperation]);
      setAllOperations((allOperations) =>
        allOperations.filter((item) => item.operation !== event.target.value)
      );
      setOperationSelectedForSelect("1");
    } catch (error) {
      console.log(error);
    }
  };

  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  function formatearRut(rut) {
    // Eliminar cualquier caracter que no sea dígito o la letra 'k' si está al final
    rut = rut.replace(/[^\dkK]/g, "");

    // Separar el rut y el dígito verificador
    var rutSinDigito = rut.slice(0, -1);
    var digitoVerificador = rut.slice(-1).toUpperCase();

    // Formatear el rut con puntos y guión
    var rutFormateado = "";
    var contador = 0;
    for (var i = rutSinDigito.length - 1; i >= 0; i--) {
      rutFormateado = rutSinDigito.charAt(i) + rutFormateado;
      contador++;
      if (contador % 3 === 0 && contador !== rutSinDigito.length) {
        rutFormateado = "." + rutFormateado;
      }
    }

    // Agregar el guión y el dígito verificador
    rutFormateado += "-" + digitoVerificador;

    return rutFormateado;
  }

  const handleBank = (event) => {
    setBank(event.target.value);
  };

  const handleTypeAccount = (event) => {
    setBankTypeAccount(event.target.value);
  };

  const handleInputChange = (event, setState) => {
    const value = event.target.value
      .toLowerCase()
      .normalize("NFD")
      .replace(
        /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
        "$1"
      );
    setState(value);
  };

  useEffect(() => {
    const tempData = async () => {
      let a = await getClinicById(dataDoctor.id);
    };

    async function getMyDoctors() {
      const doctors = await getDoctorsByCenter(myId);
      setMyDoctors(doctors);
      const allOperationsData = await getOperations();
      const allSpecialtyData = await getTypes();
      setAllOperations(
        allOperationsData.filter(
          (operation) =>
            !localOperations.some(
              (localOp) => localOp.value == operation.operation
            )
        )
      );
      setAllSpecialty(
        allSpecialtyData.filter(
          (specialty) =>
            !localSpecialties.some(
              (localSpec) => localSpec.value == specialty.name
            )
        )
      );
    }
    getMyDoctors();
    tempData();
  }, []);

  return (
    <Box
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Container
        style={{
          backgroundColor: "red",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          border: "1px solid #F0F4F8",
          backgroundColor: "white",
          minWidth: "95%",
          height: "90vh",
          color: "#006686",
          textTransform: "none",
          margin: "0px",
          padding: "0px",
          marginTop: "2vh",
          borderRadius: "25px 25px 25px 25px",
        }}
      >
        <List
          sx={{
            margin: "0px",
            padding: "0px",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            maxWidth: "16vw",
            borderRight: "1px solid rgba(192, 199, 205, 1)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              padding: "10px",
              height: "4%",
              // marginBottom: '2px',
              borderBottom: "1px solid rgba(192, 199, 205, 1)",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                color: "black",
                fontFamily: "Nunito",
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              {capitalizeAll(dataDoctor.name)}
            </Typography>
            {/* <Button style={{
                                        textTransform: 'none',
                                        justifyContent: 'center',
                                        color: '#006686',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                    }}><Add style={{
                                        color: '#006686',
                                        fontSize: '20px'
                                    }} /> Agregar</Button> */}
          </div>
          {myDoctors.length === 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  marginTop: "20px",
                  color: "#006686",
                  fontFamily: "Nunito",
                  fontWeight: "400",
                  fontSize: { md: "20px", lg: "27px" },
                }}
              >
                No hay doctores registrados
              </Typography>
            </div>
          ) : (
            myDoctors.map((doctor, index) => {
              return (
                <ListItem
                  key={index}
                  button
                  onClick={async () => {
                    setSelectedDoctor(doctor);
                    if (doctor.firstTime === false) {
                      setDocName(doctor.name);
                      setDocLastName(doctor.lastName);
                      setDocEmail(doctor.email);
                      setDocDescription(doctor.docDescription);
                      setDocAddress(
                        doctor.location.address +
                          ", " +
                          doctor.location.comuna +
                          ", " +
                          doctor.location.region
                      );
                      setBank(doctor.bankAccount.bankSelected);
                      setBankTypeAccount(
                        doctor.bankAccount.typeAccountSelected
                      );
                      setAccountNumber(doctor.bankAccount.numAccount);
                      setRut(doctor.rut);
                      setValue(doctor.valueHour);
                      setLocalOperations(doctor.operationsList);
                      setLocalSpecialties(doctor.typeDoctor);
                      setSelectedTab("Datos personales");
                    } else {
                      // clear();
                      setDocName(doctor.name);
                      setDocLastName(doctor.lastName);
                      setDocEmail(doctor.email);
                      clear();

                      Swal.fire({
                        icon: "info",
                        title: "Advertencia",
                        text: "Este doctor no ha ingresado a GetMED, por lo que no se pueden ver ni editar sus datos.",
                      });
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "5px",
                    borderBottom: "1px solid rgba(192, 199, 205, 1)",
                    backgroundColor:
                      selectedDoctor?.id === doctor.id ? "#DCE3E9" : "white",
                    cursor: "pointer",
                    margin: "0px",
                  }}
                >
                  <Avatar
                    alt=""
                    src={
                      doctor.imageProfile === "default" &&
                      doctor.genre === "Masculino"
                        ? userMaleImage
                        : doctor.imageProfile == "default" &&
                          doctor.genre == "Femenino"
                        ? userFemaleImage
                        : doctor.imageProfile
                    }
                    sx={{
                      width: 50,
                      height: 50,
                      marginRight: "10px",
                    }}
                  />
                  <ListItemText
                    primary={
                      capitalizeAll(doctor.name) +
                      " " +
                      capitalizeAll(doctor.lastName)
                    }
                    // secondary={`${request.request.date} ${request.request.hour} hrs.`}
                    // secondary={
                    //     isToday(doctor.dateCitation.toDate())
                    //         ? format(
                    //             doctor.dateCitation.toDate(),
                    //             "'Hoy a las' HH:mm 'hrs.'",
                    //             { locale: langEs }
                    //         )
                    //         : format(
                    //             doctor.dateCitation.toDate(),
                    //             "dd/MM/yyyy 'a las' HH:mm 'hrs.'",
                    //             { locale: langEs }
                    //         )
                    // }
                    sx={{
                      color: "#191C1E",
                      fontFamily: "Nunito",
                    }}
                  />
                  <ListItemIcon
                    sx={{
                      color:
                        selectedDoctor?.id === doctor.id ? "#006686" : "black",
                    }}
                  >
                    <ArrowRight />
                  </ListItemIcon>
                </ListItem>
              );
            })
          )}
        </List>
        {selectedDoctor === null ? (
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <img
              src={imageNull}
              style={{
                width: "60%",
              }}
            />
            <p
              style={{
                fontSize: "20px",
                textTransform: "none",
              }}
            >
              Selecciona a un doctor para ver sus datos
            </p>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                height: "8%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                flexDirection: "row",
                borderBottom: "1px solid rgba(192, 199, 205, 1)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Avatar
                  alt=""
                  src={
                    selectedDoctor.imageProfile === "default" &&
                    selectedDoctor.genre === "Masculino"
                      ? userMaleImage
                      : selectedDoctor.imageProfile == "default" &&
                        selectedDoctor.genre == "Femenino"
                      ? userFemaleImage
                      : selectedDoctor.imageProfile
                  }
                  sx={{
                    width: 50,
                    height: 50,
                    marginRight: "10px",
                  }}
                />
                <div>
                  <p
                    style={{
                      margin: "0px",
                      padding: "0px",
                      color: "black",
                      fontSize: "25px",
                      textTransform: "none",
                    }}
                  >
                    {capitalizeAll(selectedDoctor.name)}{" "}
                    {capitalizeAll(selectedDoctor.lastName)}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      padding: "0px",
                      fontSize: "15px",
                      color: "gray",
                    }}
                  >
                    {selectedDoctor.mainSpecialty}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Circle
                  style={{
                    color: selectedDoctor.firstTime === false ? "green" : "red",
                    fontSize: "20px",
                  }}
                />
                <p>
                  {selectedDoctor.firstTime === false
                    ? "Activo"
                    : "No ha ingresado a GetMED"}
                </p>
              </div>
            </div>
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid rgba(192, 199, 205, 1)",
              }}
            >
              <Tab
                value={"Datos personales"}
                sx={{
                  flex: 1,
                }}
                label="Datos personales"
              />
              {/* <Divider orientation="vertical" flexItem /> */}
              <Tab
                value={"Datos bancarios"}
                sx={{
                  flex: 1,
                }}
                label="Datos bancarios"
              />
              {/* <Divider orientation="vertical" flexItem /> */}
              <Tab
                value={"Datos profesionales"}
                sx={{
                  flex: 1,
                }}
                label="Datos profesionales"
              />
            </Tabs>

            {selectedTab === "Datos personales" && (
              <div
                style={{
                  marginTop: "2%",
                  flex: 1,
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "45%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      justifyItems: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <Avatar
                      alt=""
                      src={
                        selectedDoctor.imageProfile === "default" &&
                        selectedDoctor.genre === "Masculino"
                          ? userMaleImage
                          : selectedDoctor.imageProfile == "default" &&
                            selectedDoctor.genre == "Femenino"
                          ? userFemaleImage
                          : selectedDoctor.imageProfile
                      }
                      sx={{
                        width: 200,
                        height: 200,
                        margin: "10px",
                        alignSelf: "center",
                        borderRadius: "10%",
                      }}
                    />
                    {/* <Container
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                textTransform: "none",
                                marginTop: "3vh",
                                marginRight: "10px",
                                color: "rgba(0, 0, 0, 0.87)",
                                borderColor: "rgba(0, 0, 0, 0.12)",
                              }}
                              startIcon={
                                <ImageOutlined
                                  sx={{
                                    color: "rgba(0, 0, 0, 0.87)",
                                  }}
                                />
                              }
                            >
                              {"Cambiar foto"}
                            </Button>

                            <Button
                              variant="outlined"
                              sx={{
                                textTransform: "none",
                                marginTop: "3vh",
                                marginRight: "10px",
                                color: "rgba(186, 26, 26, 1)",
                                borderColor: "rgba(255, 0, 0, 0.12)",
                              }}
                              startIcon={
                                <DeleteOutline
                                  sx={{
                                    color: "rgba(186, 26, 26, 1)",
                                  }}
                                />
                              }
                            >
                              {"Eliminar foto"}
                            </Button>
                          </Container> */}
                  </div>

                  <div
                    style={{
                      width: "45%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      justifyItems: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <Avatar
                      alt=""
                      src={selectedDoctor.logo}
                      sx={{
                        width: 200,
                        height: 200,
                        margin: "10px",
                        alignSelf: "center",
                        borderRadius: "10%",
                      }}
                    />
                    {/* <Container
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                textTransform: "none",
                                marginTop: "3vh",
                                marginRight: "10px",
                                color: "rgba(0, 0, 0, 0.87)",
                                borderColor: "rgba(0, 0, 0, 0.12)",
                              }}
                              startIcon={
                                <ImageOutlined
                                  sx={{
                                    color: "rgba(0, 0, 0, 0.87)",
                                  }}
                                />
                              }
                            >
                              {"Cambiar logo"}
                            </Button>

                            <Button
                              variant="outlined"
                              sx={{
                                textTransform: "none",
                                marginTop: "3vh",
                                marginRight: "10px",
                                color: "rgba(186, 26, 26, 1)",
                                borderColor: "rgba(255, 0, 0, 0.12)",
                              }}
                              startIcon={
                                <DeleteOutline
                                  sx={{
                                    color: "rgba(186, 26, 26, 1)",
                                  }}
                                />
                              }
                            >
                              {"Eliminar logo"}
                            </Button>
                          </Container> */}
                  </div>
                </div>
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1vh",
                    justifyContent: "space-between",
                  }}
                >
                  {selectedDoctor.name && (
                    <TextField
                      sx={{
                        marginTop: "1vh",
                        width: "25vw",
                        backgroundColor: "rgba(220, 227, 233, 1)",
                      }}
                      placeholder="Nombre"
                      variant="filled"
                      id="name"
                      label="Nombre"
                      name="name"
                      disabled
                      value={docName}
                      // defaultValue={docName}
                      onChange={(event) => handleInputChange(event, setDocName)}
                    />
                  )}
                  {selectedDoctor.lastName && (
                    <TextField
                      disabled
                      sx={{
                        marginTop: "1vh",
                        width: "25vw",
                        ml: 6,
                        backgroundColor: "rgba(220, 227, 233, 1)",
                      }}
                      placeholder="Apellidos"
                      variant="filled"
                      id="lastname"
                      label="Apellidos"
                      name="lastname"
                      value={docLastName}
                      // defaultValue={docLastName}
                      onChange={(event) =>
                        handleInputChange(event, setDocLastName)
                      }
                    />
                  )}
                </Container>
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1vh",
                    justifyContent: "space-between",
                  }}
                >
                  {selectedDoctor.name && (
                    <TextField
                      sx={{
                        marginTop: "1vh",
                        width: "25vw",
                        backgroundColor: "rgba(220, 227, 233, 1)",
                      }}
                      placeholder="Email"
                      variant="filled"
                      id="email"
                      disabled
                      label="Correo electrónico"
                      name="email"
                      value={docEmail}
                      // defaultValue={docEmail}
                    />
                  )}
                  {selectedDoctor.lastName && (
                    <TextField
                      sx={{
                        marginTop: "1vh",
                        width: "25vw",
                        ml: 6,
                        backgroundColor: "rgba(220, 227, 233, 1)",
                      }}
                      placeholder="Dirección"
                      variant="filled"
                      id="address"
                      disabled
                      label="Dirección"
                      name="address"
                      value={docAddress}
                      // defaultValue={docAddress}
                    />
                  )}
                </Container>
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1vh",
                    justifyContent: "space-between",
                  }}
                >
                  {selectedDoctor.name && (
                    <TextField
                      sx={{
                        marginTop: "1vh",
                        width: "100%",
                        backgroundColor: "rgba(220, 227, 233, 1)",
                      }}
                      placeholder="Descripción"
                      variant="filled"
                      id="description"
                      disabled
                      multiline
                      rows={3}
                      label="Descripción"
                      name="description"
                      value={docDescription}
                      // defaultValue={docDescription}
                      onChange={(event) =>
                        handleInputChange(event, setDocDescription)
                      }
                    />
                  )}
                </Container>
                {/* <div
                        style={{
                          width: "95%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end",
                          marginTop: "2vh",
                        }}
                      >
                        {selectedDoctor.firstTime === false && (
                          <Button
                            onClick={savePersonalData}
                            variant="contained"
                            style={{
                              borderRadius: "50px",
                              height: "4vh",
                              backgroundColor: "#006686",
                              textTransform: "none",
                              justifyContent: "center",
                              color: "#77F8E0",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Guardar datos personales
                          </Button>
                        )}
                      </div> */}
              </div>
            )}

            {selectedTab === "Datos bancarios" && (
              <div>
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2vh",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    disabled
                    sx={{
                      marginTop: "4vh",
                      width: "25vw",
                      backgroundColor: "rgba(220, 227, 233, 1)",
                      color: "black",
                    }}
                    placeholder="RUT de titular"
                    variant="filled"
                    type="text"
                    id="rut"
                    label="RUT del titular"
                    name="rut"
                    value={rut}
                    onChange={(e) => {
                      setRut(formatearRut(e.target.value));
                    }}
                  />

                  <TextField
                    disabled
                    sx={{
                      marginTop: "4vh",
                      width: "25vw",
                      backgroundColor: "rgba(220, 227, 233, 1)",
                      color: "black",
                    }}
                    placeholder="Número de cuenta"
                    variant="filled"
                    id="numberAccount"
                    label="Número de cuenta"
                    name="numberAccount"
                    defaultValue={accountNumber}
                    onChange={(e) => {
                      setAccountNumber(e.target.value);
                    }}
                  />
                </Container>

                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2vh",
                    justifyContent: "space-between",
                  }}
                >
                  <Select
                    disabled
                    sx={{
                      marginTop: "3vh",
                      width: "25vw",
                      backgroundColor: "rgba(220, 227, 233, 1)",
                    }}
                    placeholder="Selecciona tu banco"
                    variant="filled"
                    id="select-bank"
                    value={bank}
                    label="Banco"
                    onChange={handleBank}
                  >
                    <MenuItem key={"selecciona tipo"} value={""}>
                      Selecciona un banco
                    </MenuItem>
                    {bankOptions.map((option, index) => (
                      <MenuItem
                        sx={{
                          backgroundColor:
                            index % 2 === 0
                              ? "rgba(248, 249, 251, 1)"
                              : "rgba(220, 227, 233, 1)",
                        }}
                        key={index}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>

                  <Select
                    disabled
                    sx={{
                      marginTop: "3vh",
                      width: "25vw",
                      backgroundColor: "rgba(220, 227, 233, 1)",
                    }}
                    placeholder="Selecciona tu tipo de cuenta"
                    variant="filled"
                    id="select-type"
                    value={bankTypeAccount}
                    label="Tipo de cuenta"
                    onChange={handleTypeAccount}
                  >
                    <MenuItem key={"selecciona cuenta"} value={""}>
                      Selecciona un tipo de cuenta
                    </MenuItem>
                    {typeAccountOptions.map((option, index) => (
                      <MenuItem
                        sx={{
                          backgroundColor:
                            index % 2 === 0
                              ? "rgba(248, 249, 251, 1)"
                              : "rgba(220, 227, 233, 1)",
                        }}
                        key={index}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Container>
              </div>
            )}

            {selectedTab === "Datos profesionales" && (
              <div
                style={{
                  width: "100%",
                  marginTop: "2vh",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div style={{ width: "25vw" }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Nunito",
                        fontSize: "16px",
                      }}
                    >
                      Operaciones
                    </Typography>
                    {localOperations.length >= 1 && (
                      <div
                        style={{
                          height: "20vh",
                          overflowY: "scroll",
                        }}
                      >
                        {localOperations.map((el, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {el.value}
                            {/* <ListItemIcon
                                    onClick={() => deleteOperation(index)}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <DeleteOutline />
                                  </ListItemIcon> */}
                          </ListItem>
                        ))}
                      </div>
                    )}
                    {/* <Select
                            sx={{
                              color: "black",
                              width: "95%",
                              backgroundColor: "rgba(220, 227, 233, 1)",
                            }}
                            defaultValue={"1"}
                            disabled
                            placeholder="Selecciona una operación"
                            variant="filled"
                            id="select-operation"
                            onChange={handleNewOperation}
                            label="Banco"
                            value={operationSelectedForSelect}
                            onFocus={() => {
                              setAllOperations(
                                allOperations.filter(
                                  (operation) =>
                                    !localOperations.some(
                                      (localOp) =>
                                        localOp.value == operation.operation
                                    )
                                )
                              );
                            }}
                          >
                            <MenuItem
                              disabled
                              key={"selecciona operation"}
                              value={"1"}
                            >
                              Selecciona una operación
                            </MenuItem>
                            {allOperations.map((option, index) => (
                              <MenuItem
                                sx={{
                                  backgroundColor:
                                    index % 2 === 0
                                      ? "rgba(248, 249, 251, 1)"
                                      : "rgba(220, 227, 233, 1)",
                                }}
                                key={index}
                                value={option.operation}
                              >
                                {option.operation}
                              </MenuItem>
                            ))}
                          </Select> */}
                  </div>
                  <div style={{ width: "25vw" }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Nunito",
                        fontSize: "16px",
                      }}
                    >
                      Mis especialidades
                    </Typography>
                    {localSpecialties.length >= 1 && (
                      <div
                        style={{
                          height: "20vh",
                          overflowY: "scroll",
                        }}
                      >
                        {localSpecialties.map((el, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {el.value}
                            {/* <ListItemIcon
                                    onClick={() => deleteSpecialty(index)}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <DeleteOutline />
                                  </ListItemIcon> */}
                          </ListItem>
                        ))}
                      </div>
                    )}
                    {/* <Select
                            sx={{
                              color: "black",
                              width: "95%",
                              backgroundColor: "rgba(220, 227, 233, 1)",
                            }}
                            defaultValue={"1"}
                            placeholder="Selecciona una especialidad"
                            variant="filled"
                            id="select-specialty"
                            disabled
                            label="specialties"
                            onChange={handleNewSpecialty}
                            onFocus={() => {
                              //Quiero remover los elementos que ya estan en el arreglo local para no poder seleccionarlo 2 veces
                              setAllOperations(
                                allOperations.filter(
                                  (operation) =>
                                    !localOperations.some(
                                      (localOp) =>
                                        localOp.value == operation.operation
                                    )
                                )
                              );
                              setAllSpecialty(
                                allSpecialty.filter(
                                  (specialty) =>
                                    !localSpecialties.some(
                                      (localSpec) =>
                                        localSpec.value == specialty.name
                                    )
                                )
                              );
                            }}
                            value={specialtySelectedForSelect}
                          >
                            <MenuItem
                              disabled
                              key={"selecciona specialty"}
                              value={"1"}
                            >
                              Selecciona una especialidad
                            </MenuItem>
                            {allSpecialty.map((option, index) => (
                              <MenuItem
                                sx={{
                                  backgroundColor:
                                    index % 2 === 0
                                      ? "rgba(248, 249, 251, 1)"
                                      : "rgba(220, 227, 233, 1)",
                                }}
                                key={index}
                                value={option.name}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select> */}
                  </div>
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div style={{ width: "25vw" }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Nunito",
                        fontSize: "16px",
                      }}
                    >
                      Valor de reserva
                    </Typography>

                    <TextField
                      sx={{
                        marginTop: "1vh",
                        width: "24vw",
                        backgroundColor: "rgba(220, 227, 233, 1)",
                        color: "black",
                      }}
                      type="text"
                      value={value}
                      onChange={handleChange}
                      label="Valor de la reserva en peso chileno"
                      variant="filled"
                      placeholder="Ingrese el monto en peso chileno"
                    />
                  </div>
                  <div
                    style={{
                      width: "25vw",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                    }}
                  >
                    {selectedDoctor.firstTime === false && (
                      <Button
                        variant="contained"
                        onClick={updateProfesionalData}
                        style={{
                          borderRadius: "50px",
                          backgroundColor: "#006686",
                          textTransform: "none",
                          justifyContent: "center",
                          height: "4vh",
                          color: "#77F8E0",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Guardar datos profesionales
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* <CardActions style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'end',
                                            marginTop: '1vh',
                                            color: '#77F8E0',
                                        }}>
                                            <Button variant='contained' style={{
                                                borderRadius: '50px',
                                                backgroundColor: '#006686',
                                                textTransform: 'none',
                                                justifyContent: 'center',
                                                color: '#77F8E0',
                                                alignContent: 'center',
                                                alignItems: 'center',
                                            }}>Guardar</Button>
                                        </CardActions> */}
          </div>
        )}
      </Container>
    </Box>
  );
}

const bankOptions = [
  { value: "Banco estado", label: "Banco estado" },
  { value: "Banco Chile/Edwards", label: "Banco Chile/Edwards" },
  { value: "Banco Internacional", label: "Banco Internacional" },
  { value: "Banco Scotiabank", label: "Banco Scotiabank" },
  { value: "Banco BCI", label: "Banco BCI" },
  { value: "Banco do Brasil S.A.", label: "Banco do Brasil S.A." },
  { value: "Corpbanca", label: "Corpbanca" },
  { value: "Banco BICE", label: "Banco BICE" },
  { value: "Banco HSBC Bank", label: "Banco HSBC Bank" },
  { value: "Banco Santander", label: "Banco Santander" },
  { value: "Banco ITAU", label: "Banco ITAU" },
  { value: "Banco Security", label: "Banco Security" },
  { value: "Banco Falabella", label: "Banco Falabella" },
  { value: "Banco Ripley", label: "Banco Ripley" },
  { value: "Banco Security", label: "Banco Security" },
  { value: "RaboBank", label: "RaboBank" },
  { value: "Banco Consorcio", label: "Banco Consorcio" },
  { value: "Banco Paris", label: "Banco Paris" },
  { value: "BBVA", label: "BBVA" },
  { value: "Banco del Desarrollo", label: "Banco del Desarrollo" },
  { value: "Coopeuch", label: "Coopeuch" },
  { value: "Prepago Los Heroes", label: "Prepago Los Heroes" },
  { value: "Tenpo Prepago", label: "Tenpo Prepago" },
  { value: "TAPP Caja Los Andes", label: "TAPP Caja Los Andes" },
  { value: "Copec Pay", label: "Copec Pay" },
  { value: "Mercado Pago", label: "Mercado Pago" },
];
const typeAccountOptions = [
  { value: "Cuenta vista", label: "Cuenta vista" },
  { value: "Cuenta corriente", label: "Cuenta corriente" },
  { value: "Cuenta de ahorro", label: "Cuenta de ahorro" },
];
