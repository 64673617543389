import {
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Button,
  Card,
  Box,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CamImg from "../assets/camLogo.png";
import backgroundCall from "../assets/backgroundVideocall.svg";
import { getVideoCallRequest } from "../database/doctors";
import axios from "axios";
import { updateRequestForCall } from "../database/request";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";

//REDUX IMPORTS
import { useSelector } from "react-redux";
import { AgoraManager } from "../components/agora_manager.jsx";
import { AgoraRTCProvider, useRTCClient } from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";
import NavPaper from "../components/nav_paper";

export default function Videocalls() {
  const [data, setData] = useState([]);
  const [dataDoctor, setDataDoctor] = useState({});
  const [inCall, setInCall] = useState(false);
  const [selectedCall, setSelectedCall] = useState(null);
  const [token, setToken] = useState(null);
  const [showList, setShowList] = useState(true);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const puntoDeQuiebre = 1300;
  const breakVariable = windowWidth >= puntoDeQuiebre;

  const myId = useSelector((state) => state.auth.idUser);
  const userData = useSelector((state) => state.auth.userData);

  const agoraEngine = useRTCClient(
    AgoraRTC.createClient({ codec: "vp8", mode: "rtc" })
  );

  const handleEndCall = () => {
    setInCall(false);
  };

  useEffect(() => {
    async function getData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      setDataDoctor(userData);
      const dataRes = await getVideoCallRequest(myId);
      setData(dataRes);
      Swal.close();
    }
    getData();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myId, userData]);

  const enterCall = async () => {
    try {
      const responseToken = await axios.get(
        `https://us-central1-dfinder-5f8ae.cloudfunctions.net/app/agora/rtc/testing/publisher/uid/0`
      );
      let eltoken = responseToken.data.rtcToken;
      setToken(responseToken.data.rtcToken);

      await updateRequestForCall(
        selectedCall.id,
        selectedCall.client.id,
        selectedCall.doctor.id,
        selectedCall.doctor,
        eltoken
      );

      setInCall(true);
    } catch (error) {
      console.log("Error al entrar a la llamada", error);
    }
  };

  const paperNav = (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}
    >
      {data.map((item, index) => (
        <ListItem
          key={index}
          onClick={() => {
            setSelectedCall(item);
            setShowList(false);
          }}
          sx={{
            ":hover": { backgroundColor: "rgba(0,0,0,0.2)" },
            height: { md: "10vh", lg: "8vh" },
            backgroundColor:
              selectedCall === item ? "rgba(0,0,0,0.3)" : "rgba(0,0,0,0)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ListItemAvatar>
            <Avatar src={item.client.imageProfile} />
          </ListItemAvatar>
          <ListItemText
            primary={item.client.name + " " + item.client.lastName}
            secondary={
              window.innerWidth >= 1300
                ? item.request.date + " " + item.request.hour + " hrs."
                : item.request.hour + " hrs."
            }
            primaryTypographyProps={{
              color: "Black",
              fontWeight: "medium",
              variant: "body2",
              fontSize: { md: "15px", lg: "16px" },
            }}
            secondaryTypographyProps={{
              color: "gray",
              fontWeight: "normal",
              variant: "body2",
            }}
          />
          <ArrowRightIcon sx={{ fontSize: "25px" }} />
        </ListItem>
      ))}
    </List>
  );

  const paperContentHeader = selectedCall && (
    <>
      <Avatar
        src={selectedCall.client.imageProfile}
        sx={{
          height: { md: "40px", lg: "55px" },
          width: { md: "40px", lg: "55px" },
        }}
      />
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "start",
        }}
      >
        <div>
          <Typography
            variant="body2"
            sx={{ fontSize: { md: "17px", lg: "25px" } }}
          >
            {selectedCall.client.name + " " + selectedCall.client.lastName}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: { md: "10px", lg: "15px" } }}
          >
            #{" "}
            {selectedCall.request.date +
              " " +
              selectedCall.request.hour +
              " hrs."}
          </Typography>
        </div>
      </Container>
    </>
  );

  const paperContent = inCall ? (
    /* <Container
          sx={{
            width: "100%",
            height: "10vh",
            borderBottom: "1px solid rgba(0,0,0,0.3)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img alt="cam" src={CamImg} sx={{ height: "65px", width: "65px" }} />
          <Container sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
            <Typography variant="body2" sx={{ fontSize: "25px" }}>
              {selectedCall.client.name + " " + selectedCall.client.lastName}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "15px" }}>
              {selectedCall.request.date + " " + selectedCall.request.hour + " hrs."}
            </Typography>
          </Container>
        </Container> */

    <AgoraRTCProvider client={agoraEngine}>
      <AgoraManager
        data={{
          token: token,
          channel: "testing",
          dataAtention: selectedCall,
        }}
        func={handleEndCall}
        style={{
          backgroundColor: "red",
        }}
      />
    </AgoraRTCProvider>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        p: 4,
      }}
    >
      <img
        alt="BackgroundCall"
        src={backgroundCall}
        style={{
          width: "40%",
          minWidth: "200px",
        }}
      />
      <Typography
        variant="titleMedium"
        sx={{
          textAlign: "center",
          marginTop: "30px",
          color: "#006686",
        }}
      >
        {selectedCall
          ? window.innerWidth >= 1300
            ? "Puedes iniciar la llamada con " +
              selectedCall.client.name +
              " " +
              selectedCall.client.lastName +
              " a las " +
              selectedCall.request.hour +
              " hrs."
            : "Esta llamada con " +
              selectedCall.client.name +
              " incia a las " +
              selectedCall.request.hour +
              " hrs."
          : "Selecciona un usuario para iniciar la llamada"}
      </Typography>
      {selectedCall ? (
        <Button
          disableElevation
          /* fullWidth */
          variant="contained"
          onClick={() => {
            enterCall();
          }}
          sx={{
            mt: 3,
            mb: 2,
            width: "128px",
            marginBottom: "10%",
            ":hover": { background: "77F8E0", color: "006686" },
          }}
        >
          Iniciar ahora
        </Button>
      ) : null}
    </Box>
  );

  return (
    <NavPaper
      paperTitle={
        <Typography variant="h6" sx={{}}>
          Lista de Videollamadas
        </Typography>
      }
      paperNav={paperNav}
      paperContentHeader={paperContentHeader}
      paperContent={paperContent}
      showList={showList}
      setShowList={setShowList}
    />
  );
}
