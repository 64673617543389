import { Box, Button, Container } from "@mui/material";
import { useState, useEffect } from "react";
import CardSale from "../components/card_sale_component";
import { getSales } from "../database/sale";
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
//REDUX IMPORTS
import { useSelector } from "react-redux";
import { useUpdateSales } from "../context/UpdateSales";

export default function Sales({
  handleOpenSalesDialog,
  setSalesData,
  setType,
  dataSaleDoctor,
}) {
  const { salesData } = useUpdateSales();
  const [open, setOpen] = useState(false);
  const [sales, setSales] = useState(salesData);
  const userData = useSelector((state) => state.auth.userData);
  const [myId, setMyId] = useState(
    userData.idDoctor ? userData.idDoctor : userData.id
  );

  const closeDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    setSalesData({ id: myId });

    async function getData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      setSales(salesData);
      Swal.close();
    }
    getData();
  }, [myId, salesData]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          overflowY: "scroll",
          flexDirection: "column",
        }}
      >
        {sales.length > 0 ? (
          <Grid container>
            {sales.map((sale) => (
              <Grid item key={sale.url}>
                <CardSale
                  key={sale.createdAt}
                  setData={setSalesData}
                  data={sale}
                  openSaleDialog={handleOpenSalesDialog}
                  cerrar={closeDialog}
                  setType={setType}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          ""
        )}
      </Box>

      <div
        style={{
          width: "100%",
          height: "10%",
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
          borderTop: "1px solid rgba(0,0,0,0.3)",
        }}
      >
        <Button
          onClick={() => {
            handleOpenSalesDialog();
          }}
          style={{
            backgroundColor: "#006686",
            color: "#77F8E0",
            borderRadius: "25px",
            padding: "10px",
            marginRight: "10px",
          }}
        >
          Nueva oferta
        </Button>
      </div>
    </>
  );
}
