import { db } from "./config";
import {
  collection,
  setDoc,
  doc,
  serverTimestamp,
  getDocs,
  getDoc,
  query,
  where,
  orderBy,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

export async function createDoctor(data, userId) {
  try {
    data.password = null;

    const docRef = doc(collection(db, "users"), userId);
    const ress = await setDoc(docRef, {
      ...data,
      role: "doctor",
      createdAt: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function createDoctores(data) {
  try {
    const docRef = doc(collection(db, "users")); // Reference to the "doctors" collection
    const ress = await addDoc(docRef, {
      ...data,
      role: "doctor",
      createdAt: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

// export async function createBatchDoctors(doctorsArray) {
//   const batchWrite = writeBatch(db);

//   try {
//     doctorsArray.forEach(async (doctor) => {
//       const docRef = doc(collection(db, "users"));
//       batchWrite.set(docRef, {
//         ...doctor,
//         role: "doctor",
//         createdAt: serverTimestamp(),
//       });
//     });
//     await batchWrite.commit();
//     console.log("Batch de creación de usuarios ejecutado exitosamente");
//     return true;
//   } catch (error) {
//     console.error("Error al ejecutar el batch de creación de usuarios:", error);
//     return false;
//   }
// }

export async function getAllDoctors(status) {
  const queryData = query(
    collection(db, "users"),
    where("role", "==", "doctor"),
    where("status", "==", status)
  );
  const doctors = await getDocs(queryData);
  return doctors.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
}

export async function updateDoctor(data, userId) {
  try {
    const doctorData = { ...data, id: userId };

    const docRef = doc(collection(db, "users"), userId);
    const res = await updateDoc(docRef, doctorData);

    return true;
  } catch (error) {
    console.error("Error al actualizar el doctor:", error);
    return false;
  }
}

export async function changeStatusDoctor(userId, status) {
  try {
    const docRef = doc(db, "users", userId);
    await updateDoc(docRef, { status: status });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getIsapres() {
  const types = await getDocs(collection(db, "isapres"));
  return types.docs.map((doc) => doc.data());
}

export async function uploadFile(files, id, type) {
  let data = {};
  try {
    const storageRef = firebase.storage().ref();
    const fileExtension = files.name.split(".").pop();

    const imageRef2 = storageRef.child(
      `doctors/${generarTextoAleatorio(20)}-${Date.now()}.${fileExtension}`
    );

    try {
      // Sube la imagen a Firebase Storage
      await imageRef2.put(files);
      // Obtiene la URL de descarga de la imagen
      const url2 = await imageRef2.getDownloadURL();
      // Retorna la URL de descarga
      if (type === "logo") {
        data.logo = url2;
      } else {
        data.imageProfile = url2;
      }
    } catch (error) {
      console.error(error);
    }
    // if (data.imageProfile) {
    //   data.status = "enabled";
    // } else {
    //   data.status = "pending";
    // }
    data.firstTime = false;
    await updateDoctor(data, id);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

function generarTextoAleatorio(longitud) {
  let texto = "";
  const caracteresPermitidos =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < longitud; i++) {
    let indice = Math.floor(Math.random() * caracteresPermitidos.length);
    texto += caracteresPermitidos.charAt(indice);
  }
  return texto;
}

export async function getDoctorById(userId) {
  const docRef = doc(db, "users", userId);
  // console.log("docRef",docRef);
  const docSnap = await getDoc(docRef);
  // console.log("docSnap",docSnap);
  if (docSnap.exists()) {
    return {
      id: docSnap.id,
      ...docSnap.data(),
    };
  } else {
    return null;
  }
}

export async function updateHourValue(userId, value) {
  try {
    const docRef = doc(db, "users", userId);
    await updateDoc(docRef, { valueHour: value });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateSettingsPOS(
  userId,
  codePos,
  rutPos,
  accId,
  secretKey,
  secretKeyPOS
) {
  try {
    const docRef = doc(db, "users", userId);
    await updateDoc(docRef, {
      codePos: codePos,
      rutPos: rutPos,
      accountId: accId,
      secretKey: secretKey,
      secretKeyPOS: secretKeyPOS,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getDoctorsByCenter(centerId) {
  const queryData = query(
    collection(db, "users"),
    where("medicalCenterId", "==", centerId)
  );
  const doctors = await getDocs(queryData);
  return doctors.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
}

export async function getVideoCallRequest(doctorId) {
  const today = new Date();
  const nextMonth = new Date(today);
  nextMonth.setDate(today.getDate() + 30);
  const requestsQuery = query(
    collection(db, "requests"),
    where("idDoctor", "==", doctorId),
    where("status", "==", "accepted"),
    where("dateCitation", ">", today),
    where("dateCitation", "<", nextMonth),
    where("request.payData.status", "!=", "pending"),
    where("request.type", "==", "videollamada"),
    orderBy("dateCitation", "desc")
  );

  const querySnapshot = await getDocs(requestsQuery);
  const requests = [];

  querySnapshot.forEach((doc) => {
    requests.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return requests;
}

export async function getCenterMedicalDoctors(idCenterMed) {
  const queryData = query(
    collection(db, "users"),
    where("role", "==", "doctor"),
    where("medicalCenterId", "==", idCenterMed)
  );
  const doctors = await getDocs(queryData);
  return doctors.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
}
