import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Container, MenuItem, Select, Typography } from "@mui/material";
import { updateSale, uploadSale } from "../database/sale";
import { useEffect, useState } from "react";
import langEs from "date-fns/locale/es";
import "../App.css";
import Loading from "./loading_component";
import Swal from "sweetalert2";
import moment from "moment";
import Files from "react-files";
import { ImageTwoTone, InsertPhotoTwoTone, Margin } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useUpdateSales } from "../context/UpdateSales";

export default function AddDialog({ open, close, data, type }) {
  let myId = useSelector((state) => state.auth.idUser);
  const myData = useSelector((state) => state.auth.userData);
  const [idDoctor, setIdDoctor] = useState(
    myData.role === "secretary" ? myData.idDoctor : myId
  );

  const styleImage = {
    display: "flex",
    width: "100%",
    height: "30vh",
    padding: "0px 164px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    background: "white",
    border: "1px dashed #006686",
    color: "white",
    borderRadius: "25px 25px 25px 25px",
    cursor: "pointer",
    marginTop: "20px",
    marginBottom: "20px",
  };

  const { updateSalesAsync } = useUpdateSales();

  const [selectedDate, setSelectedDate] = useState(null);
  const [imageView, setImageView] = useState(null);
  const [image, setImage] = useState(null);
  const [imageEdit, setImageEdit] = useState(null);
  const [imageUpdate, setImageUpdate] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [offerType, setOfferType] = useState(0);

  useEffect(() => {
    if (myData.role !== "doctor") {
      myId = myData.idDoctor;
    }
    if (type === "edit") {
      const milisegundos = data.selectedDate?.seconds * 1000;
      let date = new Date(milisegundos);
      setTitle(data.title);
      setDescription(data.description);
      setSelectedDate(date);
      setImageEdit(data.url);
      setOfferType(data.offerType);
      // setImageView(data.url);
      // setImage(data.url);
    } else {
      setImageEdit(null);
    }
  }, [data, type]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleUpdateSales = () => {
    updateSalesAsync();
  };

  const handleEditImageChange = (e) => {
    let file = e[0];
    let vista = file.preview.url;

    if (type === "edit") {
      setImageEdit(vista);
      setImageUpdate(file);
    } else {
      setImage(file);
      setImageView(file.preview.url);
    }
  };

  const upload = async () => {
    // console.log("Hola", title, image, description, selectedDate)
    let validate = [];
    if (image === null) {
      setImage("default");
    }
    if (title === "") {
      validate.push("Titulo");
    }
    if (description === "") {
      validate.push("Descripción");
    }
    if (selectedDate === null) {
      validate.push("Fecha");
    }
    if (offerType === 0) {
      validate.push("Tipo de oferta");
    }

    if (validate.length > 0) {
      Swal.fire({
        icon: "warning",
        title: "Opss..",
        text: `Por favor complete el/los campo(s): ${validate.join(", ")}`,
      });
    } else {
      const res = await uploadSale(image, {
        idUser: myData.role === "secretary" ? idDoctor : myId,
        title: title,
        description: description,
        selectedDate: selectedDate,
        offerType: offerType,
      });
      if (res) {
        limpiarCampos();
        close();
        Swal.fire({
          icon: "success",
          title: "Oferta creada",
          text: "Se ha creado la oferta correctamente",
        }).then(() => {
          handleUpdateSales();
        });
      } else {
        close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al crear la oferta, intentelo nuevamente",
        });
      }
    }
  };
  const editSale = async () => {
    let validate = [];
    if (imageEdit === null) {
      validate.push("Imagen");
    }
    if (title === "") {
      validate.push("Titulo");
    }
    if (description === "") {
      validate.push("Descripción");
    }
    if (selectedDate === null) {
      validate.push("Fecha");
    }
    if (offerType === 0) {
      validate.push("Tipo de oferta");
    }

    if (validate.length > 0) {
      Swal.fire({
        icon: "warning",
        title: "Opss..",
        text: `Por favor complete el/los campo(s): ${validate.join(", ")}`,
      });
    } else {
    
      const res = await updateSale(data.id, {
        title: title,
        description: description,
        selectedDate: selectedDate,
        url: imageEdit,
        offerType: offerType,
      });
      if (res) {
        limpiarCampos();
        close();
        Swal.fire({
          icon: "success",
          title: "oferta actualizada",
          text: "Se ha actualizado la oferta",
        }).then(() => {
          handleUpdateSales();
        });
      } else {
        close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al actualizar la oferta, intentelo nuevamente",
        });
      }
    }
  };

  const limpiarCampos = () => {
    setTitle("");
    setOfferType(0);
    setDescription("");
    setSelectedDate(null);
    setImage(null);
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      sx={{ zIndex: "1200" }}
      PaperProps={{
        style: {
          backgroundColor: "#EEE8F4",
          borderRadius: "25px 25px 25px 25px",
          display: "flex",
          width: "744px",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <DialogContent>
        <br />

        <Container
          sx={{
            display: "flex",
            width: "500px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              alignSelf: "stretch",
              fontSize: "20px",
              mb: 3,
            }}
          >
            {type === "edit" ? "Editar oferta" : "Crear nueva oferta"}
          </Typography>
          <Select
            fullWidth
            label="Age"
            value={offerType}
            onChange={(e) => setOfferType(e.target.value)}
          >
            <MenuItem value={0}>Seleccione tipo de oferta</MenuItem>
            <MenuItem value={"descuento en productos"}>
              Descuento en productos
            </MenuItem>
            <MenuItem value={"descuento en operaciones"}>
              Descuento en operaciones
            </MenuItem>
            <MenuItem value={"descuento en hora médica"}>
              Descuento en hora médica
            </MenuItem>
          </Select>
          <br />
          <TextField
            fullWidth
            required
            margin="dense"
            id="Title"
            placeholder="Agregar titulo a la oferta"
            label="Titulo"
            value={title}
            type="text"
            variant="outlined"
            onChange={(e) => setTitle(e.target.value)}
          />
          <br />
          <TextField
            fullWidth
            required
            margin="dense"
            id="description"
            label="Descripción"
            placeholder="Agregar descripción a la oferta"
            value={description}
            type="text"
            multiline
            rows={4}
            variant="outlined"
            inputProps={{ maxLength: 140 }}
            onChange={(e) => setDescription(e.target.value)}
          />
          <br />

          <LocalizationProvider
            adapterLocale={langEs}
            dateAdapter={AdapterDateFns}
          >
            <DatePicker
              slotProps={{ textField: { fullWidth: true, required: true } }}
              label="Seleccione la fecha de término"
              value={selectedDate ? selectedDate : null}
              onChange={handleDateChange}
              variant="filled"
              minDate={type !== "edit" && moment().toDate()}
            />
          </LocalizationProvider>
          <br />
          {type === "edit" ? (
            <Container sx={styleImage}>
              <br />
              {imageEdit !== null ? (
                <img
                  style={{
                    objectFit: "contain",
                    width: "25vw",
                    height: "25vh",
                  }}
                  className="files-gallery-item"
                  key={imageEdit}
                  src={type === "edit" ? imageEdit : imageView}
                  alt="photo"
                  onClick={() => {
                    setImageEdit(null);
                    // setImageView(null);
                  }}
                />
              ) : (
                <Container sx={styleImage}>
                  <Files
                    className="files-dropzone"
                    onChange={(e) => {
                      handleEditImageChange(e);
                    }}
                    // onError={handleError}
                    accepts={["image/*"]}
                    clickable
                    style={{
                      width: "60%",
                      height: "27vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "white",
                      color: "#006686",
                      borderRadius: "25px 25px 25px 25px",
                      cursor: "pointer",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                    maxFiles={1}
                  >
                    Arrastre o haga click para seleccionar
                  </Files>
                </Container>
              )}
            </Container>
          ) : image === null ? (
            <Container sx={styleImage}>
              <Files
                className="files-dropzone"
                onChange={(e) => {
                  handleEditImageChange(e);
                }}
                // onError={handleError}
                accepts={["image/*"]}
                clickable
                style={{
                  width: "60%",
                  height: "27vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "white",
                  color: "#006686",
                  borderRadius: "25px 25px 25px 25px",
                  cursor: "pointer",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                maxFiles={1}
              >
                Arrastre o haga click para seleccionar
              </Files>
            </Container>
          ) : (
            <Container sx={styleImage}>
              <br />
              <img
                style={{
                  objectFit: "contain",
                  width: "25vw",
                  height: "25vh",
                }}
                className="files-gallery-item"
                key={imageView}
                src={imageView}
                alt="photo"
                onClick={() => {
                  setImage(null);
                  setImageView(null);
                }}
              />
            </Container>
          )}
        </Container>
        <br />
      </DialogContent>
      {/* <Divider /> */}
      <DialogActions sx={{ ml: 25, mb: 2 }}>
        <Button
          variant="text"
          sx={{
            backgroundColor: "transparent",
            color: "#006686",
            // "&:hover": {
            //   backgroundColor: "#ffc107",
            //   color: "white",
            // },
          }}
          onClick={() => {
            limpiarCampos();
            close();
            setImage(null);
          }}
        >
          Cancelar
        </Button>

        <Button
          variant="outlined"
          sx={{
            borderRadius: "100px",
            background: "#006686",
            color: "#77F8E0",

            // "&:hover": {
            //   backgroundColor: "#4caf50",
            //   color: "white",
            // },
          }}
          onClick={async () => {
            if (type === "edit") {
              setLoading(true);
              await editSale();
              setLoading(false);
            } else {
              try {
                setLoading(true);
                await upload();
                handleUpdateSales();
                setLoading(false);
              } catch (error) {
                console.log(error);
              }
            }
          }}
        >
          {type === "edit" ? "Editar oferta" : "Añadir oferta"}
        </Button>
      </DialogActions>
      <Loading loadingOn={loading} loadingOff={() => setLoading(false)} />
    </Dialog>
  );
}
