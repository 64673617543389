import { db } from "./config";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { limpiarRut } from "../utils/functions";

async function getUserById(userId) {
  // console.log("Buscando por id", userId);
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
}

async function getUserByRut(rutUser) {
  let rut = limpiarRut(rutUser);
  let usersArr = [];
  const queryData = query(collection(db, "users"), where("rut", "==", rut));
  const users = await getDocs(queryData);
  users.docs.map((doc) => {
    usersArr.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return usersArr;
}

async function getUserByEmail(email) {
  let usersArr = [];
  const queryData = query(collection(db, "users"), where("email", "==", email));
  const users = await getDocs(queryData);
  users.docs.map((doc) => {
    usersArr.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return usersArr;
}

async function createUser(data) {
  try {
    const responseApi = await axios.post(
      "https://us-central1-dfinder-5f8ae.cloudfunctions.net/app/createUserFirebase",
      {
        email: data.email,
        password: data.password,
      }
    );
    const res = responseApi.data.user;

    if (data.image) {
      const imageUrl = await uploadImage(data.image);
      data.image = imageUrl;
    }

    const docRef = doc(collection(db, "users"), res.uid);

    await setDoc(docRef, {
      ...data,
      role: "user",
      createdIn: "web",
      id: res.uid,
      password: null,
      createdAt: serverTimestamp(),
    });

    return res.uid;
  } catch (error) {
    console.log(error);
    return error;
  }
}

async function uploadImage(file) {
  const storageRef = firebase.storage().ref();
  const imageRef = storageRef.child(`secretaries/${file.name}-${Date.now()}`);

  try {
    // Sube la imagen a Firebase Storage
    await imageRef.put(file);

    // Obtiene la URL de descarga de la imagen
    const url = await imageRef.getDownloadURL();

    // Retorna la URL de descarga
    return url;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export { getUserById, createUser, getUserByRut, getUserByEmail };
