import { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteSale } from "../database/sale";
import Loading from "./loading_component";
import Swal from "sweetalert2";
import { Button } from "@mui/material";
import { useUpdateSales } from "../context/UpdateSales";
import Product from "../assets/Productos.png";
import Operation from "../assets/Operaciones.png";
import Medical from "../assets/Citas.png";

export default function CardSale({
  data,
  openSaleDialog,
  setData,
  setType,
  loadSaleData,
}) {
  const { updateSalesAsync } = useUpdateSales();
  // console.log(data);
  const expDate = (
    <Typography
      variant="body2"
      color="text.secondary"
      style={{ color: "black", fontSize: "15px", marginTop: "5px" }}
    >
      Hasta {data.selectedDate.toDate().toLocaleDateString()}
    </Typography>
  );
  const [loading, setLoading] = useState(false);

  const deleteSale1 = () => {
    Swal.fire({
      icon: "question",
      title: "Espera...",
      text: "¿Estás seguro que deseas eliminar esta oferta?",
      showCancelButton: "Cancelar",
      cancelButtonColor: "RGB(166,0,38)",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#006686",
      confirmButtonText: "Eliminar",
    }).then(async (el) => {
      if (el.isConfirmed) {
        setLoading(true);
        await deleteSale(data.id);
        updateSalesAsync();
        setLoading(false);
      }
    });
  };

  const imageToShow = () => {
    if (data.url !== "default") {
      return (
        <CardMedia
          component="img"
          height="194"
          image={data.url}
          alt="CardImg"
        />
      );
    }

    const offerTypeToImage = {
      "descuento en operaciones": Operation,
      "descuento en hora médica": Medical,
      "descuento en productos": Product,
    };
    const image = offerTypeToImage[data.offerType] || null;

    return image ? (
      <CardMedia component="img" height="194" image={image} alt="CardImg" />
    ) : null;
  };

  return (
    <>
      <Card
        elevation={5}
        sx={{
          minWidth: 300,
          maxWidth: 300,
          margin: 1,
          color: "black",
          borderRadius: "3%",
          mt: 1,
        }}
      >
        <CardHeader sx={{}} title={"Descuento"} subheader={expDate} />
        {imageToShow()}
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {data.description}
          </Typography>
        </CardContent>
        <CardActions sx={{ display: "flex", flexDirection: "row", ml: 10 }}>
          <Button
            variant="outlined"
            sx={{ borderRadius: "25px" }}
            onClick={async () => {
              deleteSale1();
            }}
          >
            Eliminar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setType("edit");
              setData(data);
              openSaleDialog();
            }}
            sx={{
              backgroundColor: "#006399",
              color: "#fff",
              borderRadius: "25px",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#005580";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = "#006399";
            }}
          >
            Editar
          </Button>
        </CardActions>
      </Card>
      <Loading
        loadingOn={loading}
        loadingOff={() => {
          setLoading(false);
        }}
      />
    </>
  );
}
