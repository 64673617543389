import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Calendar from "./pages/calendar.jsx";
import Administration from "./pages/administration";
import image from "./assets/not-found.svg";
import { Container } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import PendingRequest from "./pages/pending_request";
import Login from "./pages/login";
import Register from "./pages/register";
import RecoverPass from "./pages/recover_pass";
import CompleteProfile from "./pages/complete_profile";
import ChatList from "./pages/chat_list";
import Videocalls from "./pages/videocall";
import PatientEntry from "./components/patient_entry";
import CompleteRegister from "./pages/complete_register";
import RegisterClinic from "./pages/registerClinic";
import Home from "./pages/home.jsx";
import Reserves from "./pages/reserves.jsx";
import CompleteCenterMed from "./pages/complete_center_med";
import { useSelector } from "react-redux";
import MedicalRecords from "./pages/medical_records.jsx";
import HomeAdmin from "./pages/home_admin.jsx";
import RequestsAdmin from "./pages/requests_admin.jsx";
import Reporting from "./pages/reporting";
// import ButtonDrawer from "../src/components/button_drawer";
import HomeMedicalCenter from "./pages/home_medical_center.jsx";
import Professionals from "./pages/professionals.jsx";
import MedicalCenterAdmin from "./pages/center_medical_admin";
import AdminBankRequest from "./pages/admin_bank_request.jsx";
import { TitleProvider } from "./context/TitleContext";
import Layout from "./components/main_layout.jsx";
import NotFoundImage from "./assets/not-found.svg"; // Asegúrate de tener la imagen importada correctamente

function App() {
  const logged = useSelector((state) => state.auth.logged);
  const userData = useSelector((state) => state.auth.userData);

  let role = userData ? userData.role : null;

  return (
    <TitleProvider>
      <BrowserRouter>
        <Routes>
          {/* Rutas sin proteger */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/recoverPass" element={<RecoverPass />} />
          <Route path="/registerClinic" element={<RegisterClinic />} />
          <Route
            path="/completeProfile"
            element={
              logged && role === "doctor" ? (
                <CompleteProfile />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/completeCenterMed"
            element={
              logged && role === "medicalCenter" ? (
                <CompleteCenterMed />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          {/* Rutas protegidas con Layout */}
          {role === "doctor" && userData.firstTime ? (
            <Route path="/" element={<CompleteProfile />} />
          ) : role === "medicalCenter" && userData.firstTime ? (
            <Route path="/" element={<CompleteCenterMed />} />
          ) : (
            <Route element={<Layout title="App Layout" />}>
              <Route
                path="/"
                element={
                  logged && (role === "doctor" || role === "secretary") ? (
                    userData.firstTime ? (
                      <CompleteProfile />
                    ) : (
                      <Home />
                    )
                  ) : logged && role === "admin" ? (
                    <HomeAdmin />
                  ) : logged && role === "medicalCenter" ? (
                    userData.firstTime ? (
                      <CompleteCenterMed />
                    ) : (
                      <HomeMedicalCenter />
                    )
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/home"
                element={
                  (logged && role === "doctor") ||
                  (logged && role === "secretary") ? (
                    <Home />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />

              <Route
                path="/calendar"
                element={
                  (logged && role === "doctor") ||
                  (logged && role === "secretary") ? (
                    <Calendar />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/settings"
                element={
                  (logged && role === "doctor") ||
                  (logged && role === "secretary") ||
                  (logged && role === "medicalCenter") ? (
                    <Administration />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/chatList"
                element={
                  logged && role === "doctor" ? (
                    <ChatList />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/videoCalls"
                element={
                  logged && role === "doctor" ? (
                    <Videocalls />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/patientEntry"
                element={
                  logged && role === "doctor" ? (
                    <PatientEntry />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/medicalRecords"
                element={
                  logged && role === "doctor" ? (
                    <MedicalRecords />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/reporting"
                element={
                  logged && role === "doctor" ? (
                    <Reporting />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/completeRegister"
                element={
                  logged && role === "admin" ? (
                    <CompleteRegister />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/homeAdmin"
                element={
                  logged && role === "admin" ? (
                    <HomeAdmin />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/MedicalCenterAdmin"
                element={
                  logged && role === "admin" ? (
                    <MedicalCenterAdmin />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/homeMedicalCenter"
                element={
                  logged && role === "medicalCenter" ? (
                    <HomeMedicalCenter />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/professionals"
                element={
                  logged && role === "medicalCenter" ? (
                    <Professionals />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/requestsAdmin"
                element={
                  logged && role === "admin" ? (
                    <RequestsAdmin />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/adminBankRequest"
                element={
                  logged && role === "admin" ? (
                    <AdminBankRequest />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/reserves"
                element={
                  logged ? <Reserves /> : <Navigate to="/login" replace />
                }
              />
            </Route>
          )}

          <Route
            path="*"
            element={
              <Container style={{ marginLeft: "35%" }} fixed>
                <h1 style={{ color: lightBlue }}>No hay nada aquí...</h1>
                <img src={NotFoundImage} alt="not-found" width={"50%"} />
              </Container>
            }
          />
        </Routes>
      </BrowserRouter>
    </TitleProvider>
  );
}

export default App;

//Rutas que no se utilizan de momento
/* <Route path="/types" element={<Types />} /> */
/* <Route path="/upData" element={<UpData />} /> */
/* <Route
          path="/login"
          element={logged ? <Home /> : <Navigate to="/login" replace />}
        /> */
/*<Route
        path="/pendingRequest"
        element={logged  ? <PendingRequest /> : <Navigate to="/permissionDenied" replace />}
      />*/
