import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Collapse,
  Container,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../database/config";
import { sendPasswordResetEmail } from "firebase/auth";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import logo from "../assets/logo.svg";

export default function RecoverPass() {
  const [email, setEmail] = useState(false);
  const navigate = useNavigate();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const recoverPass = () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    if (!email) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debes ingresar un correo electrónico",
      });
    } else {
      setError("");
      setSuccess("");
      sendPasswordResetEmail(auth, email)
        .then((res) => {
          setOpenSuccess(true);
          Swal.close();
          setSuccess("Se ha enviado un correo para recuperar tu contraseña.");
        })
        .catch((error) => {
          setOpenError(true);
          setError(error.message);
        });
    }
  };

    return (
        <Container>
          <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py:2, // Ajusta el padding según sea necesario
      }}
    >
      <Box
        component="img"
        src={logo}
        sx={{
          height: '50px',
        }}
      />
    </Box>

            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
                backgroundColor: 'white',
                borderRadius: '28px',
                px:3,
                py:6,
            }}>
                <Typography component="h1" variant="h5" sx={{ margin: "5px" }}>
                    Recuperar contraseña
                </Typography>
                <br />
                <Typography component="h5" sx={{ marginBottom: "15px" }}>
                    Debes ingresar tu correo electrónico para recuperar tu contraseña.
                </Typography>
                <TextField 
                sx={{
                    mb: 3
                }}
                    fullWidth
                    required
                    hiddenLabel
                    placeholder='Correo Electrónico'
                    variant="filled"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon />
                            </InputAdornment>
                        )
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    autoFocus />
                <Button
                    onClick={recoverPass}
                    fullWidth
                    variant="contained"
                    disableElevation  
                >
                    RECUPERAR CONTRASEÑA
                </Button>
        
                <p style={{ cursor: 'pointer', color: '#42474E' }} onClick={
                    () => navigate('/login')
                }>Volver a iniciar sesión</p>
            </Container>
            <Container sx={{
                marginTop: '2%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
                borderRadius: '10px'
            }}>

                {success && <Box sx={{ width: '100%' }}>
                    <Collapse in={openSuccess}>
                        <Alert action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpenSuccess(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                            sx={{ mb: 2 }} severity="success"><AlertTitle>Realizado</AlertTitle>{success}</Alert>


                    </Collapse>
                </Box>}
                {error && <Box sx={{ width: '100%' }}>
                    <Collapse in={openError}>
                        {console.log(error)}
                        <Alert action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpenError(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                            sx={{ mb: 2 }} severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
                    </Collapse>
                </Box>}
            </Container>
        </Container>
    )
}
