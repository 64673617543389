import { db } from "./config";
import {
  collection,
  query,
  getDocs,
  where,
  Timestamp,
} from "firebase/firestore";

import { format, startOfMonth, endOfMonth, eachDayOfInterval } from "date-fns";

export async function getDailyGain(idUser, date) {
  try {
    let total = 0;
    let fonasa = 0;
    let totalFonasa = 0;
    let isapre = 0;
    let totalIsapre = 0;
    let card = 0;
    let totalCard = 0;
    let efectivo = 0;
    let totalEfectivo = 0;

    let completed = 0;
    let canceled = 0;
    let waiting = 0;
    let absent = 0;

    const queryData = query(
      collection(db, "requests"),
      where("idDoctor", "==", idUser),
      where("request.date", "==", date)
      // where("status", "in", ["completed", "waiting"])
    );
    const types = await getDocs(queryData);
    // return types.docs.map((doc) => doc.data().request.payData.amount).reduce((a, b) => a + b, 0);
    types.docs.map((doc) => {
      let status = doc.data().status;
      if (
        doc.data().request.payData.amount &&
        (status === "completed" || status === "waiting")
      ) {
        const numberWithoutCurrency = doc
          .data()
          .request.payData.amount.toString()
          .replace(/\$|\.+/g, "");
        total += parseInt(numberWithoutCurrency, 10);
      }

      if (status === "completed") {
        completed++;
      } else if (status === "canceledByClient") {
        canceled++;
      } else if (status === "waiting") {
        waiting++;
      } else if (status === "clientAbsent") {
        absent++;
      }

      if (
        doc.data().request.payData.typePay === "Fonasa" &&
        (status === "completed" || status === "waiting")
      ) {
        fonasa++;
        totalFonasa += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.typePay === "Isapre" &&
        (status === "completed" || status === "waiting")
      ) {
        isapre++;
        totalIsapre += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.payMethod === "webpay" &&
        (status === "completed" || status === "waiting")
      ) {
        card++;
        totalCard += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.payMethod === "Efectivo" &&
        (status === "completed" || status === "waiting")
      ) {
        efectivo++;
        totalEfectivo += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      }
    });
    return {
      allData: types.docs.map((doc) => doc.data()),
      total: total,
      byStatus: {
        completed: completed,
        canceled: canceled,
        waiting: waiting,
        absent: absent,
      },
      byType: {
        fonasa: fonasa,
        totalFonasa: totalFonasa,
        isapre: isapre,
        totalIsapre: totalIsapre,
        card: card,
        totalCard: totalCard,
        efectivo: efectivo,
        totalEfectivo: totalEfectivo,
      },
    };
  } catch (error) {
    console.log(error);
    return false;
  }
}

// export async function getWeeklyGains(idUser) {
//     console.log("buscando ganancia diaria", idUser);
//     try {
//         const dataChart = [];
//         // Obtener la fecha actual
//         const currentDate = new Date();
//         // Calcular la fecha hace 7 días
//         const startDate = subDays(startOfDay(currentDate), 6); // Restamos 6 días para obtener el rango de los últimos 7 días
//         const endDate = endOfDay(currentDate); // Fecha final es el final del día actual
//         // Formatear las fechas de inicio y fin
//         const formattedStartDate = format(startDate, 'yyyy-MM-dd');
//         const formattedEndDate = format(endDate, 'yyyy-MM-dd');
//         // Realizar la consulta para obtener los registros dentro del rango de fechas
//         const queryData = query(
//             collection(db, "requests"),
//             where("idDoctor", "==", idUser),
//             where("request.date", ">=", formattedStartDate),
//             where("request.date", "<=", formattedEndDate),
//             where("status", "in", ["completed", "waiting"])
//         );
//         const types = await getDocs(queryData);
//         // Crear un objeto para almacenar las ganancias por fecha
//         const dailyGain = {};
//         // Iterar sobre los resultados de la consulta
//         types.docs.forEach((doc) => {
//             const amount = doc.data().request.payData.amount;
//             const requestDate = doc.data().request.date;
//             if (amount) {
//                 const numberWithoutCurrency = amount.replace(/\$|\.+/g, "");
//                 const total = parseInt(numberWithoutCurrency, 10);
//                 // Verificar si ya existe una entrada para esta fecha
//                 if (dailyGain[requestDate]) {
//                     // Si ya existe, sumar las ganancias
//                     dailyGain[requestDate] += total;
//                 } else {
//                     // Si no existe, crear una nueva entrada
//                     dailyGain[requestDate] = total;
//                 }
//             }
//         });
//         // Iterar sobre los últimos 7 días, incluyendo hoy
//         for (let i = 6; i >= 0; i--) {
//             const date = subDays(currentDate, i);
//             const formattedDate = format(date, 'yyyy-MM-dd');
//             // Agregar la ganancia correspondiente a la fecha al arreglo de datos
//             const ganancias = dailyGain[formattedDate] || 0; // Si no hay ganancia registrada para este día, establecer en 0
//             dataChart.push({ day: formattedDate, Ganancias: ganancias });
//         }
//         return dataChart;
//     } catch (error) {
//         console.log(error);
//         return false;
//     }
// }

export async function getWeeklyGains(idUser) {
  try {
    const dataChart = [];
    const currentDate = new Date();
    const monday = getMonday(currentDate);
    const sunday = getSunday(currentDate);
    const formattedStartDate = format(monday, "yyyy-MM-dd");
    const formattedEndDate = format(sunday, "yyyy-MM-dd");
    const queryData = query(
      collection(db, "requests"),
      where("idDoctor", "==", idUser),
      where("request.date", ">=", formattedStartDate),
      where("request.date", "<=", formattedEndDate)
      // where("status", "in", ["completed", "waiting"])
    );
    const types = await getDocs(queryData);
    // Crear un objeto para almacenar las ganancias por día
    const dailyGains = {};
    let completed = 0;
    let canceled = 0;
    let waiting = 0;
    let absent = 0;
    let fonasa = 0;
    let isapre = 0;
    let card = 0;
    let efectivo = 0;
    let totalEfectivo = 0;
    let totalCard = 0;
    let totalIsapre = 0;
    let totalFonasa = 0;
    // Iterar sobre los resultados de la consulta
    types.docs.forEach((doc) => {
      const amount = doc.data().request.payData.amount;
      const requestDate = doc.data().request.date;
      const status = doc.data().status;
      if (amount && (status === "completed" || status === "waiting")) {
        const numberWithoutCurrency = amount.toString().replace(/\$|\.+/g, "");
        const gain = parseInt(numberWithoutCurrency, 10);
        // Verificar si ya existe una entrada para este día
        if (dailyGains[requestDate]) {
          // Si ya existe, sumar las ganancias
          dailyGains[requestDate] += gain;
        } else {
          // Si no existe, crear una nueva entrada
          dailyGains[requestDate] = gain;
        }
      }
      if (status === "completed") {
        completed++;
      } else if (status === "canceledByClient") {
        canceled++;
      } else if (status === "waiting") {
        waiting++;
      } else if (status === "clientAbsent") {
        absent++;
      }

      if (
        doc.data().request.payData.typePay === "Fonasa" &&
        (status === "completed" || status === "waiting")
      ) {
        fonasa++;
        totalFonasa += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.typePay === "Isapre" &&
        (status === "completed" || status === "waiting")
      ) {
        isapre++;
        totalIsapre += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.payMethod === "webpay" &&
        (status === "completed" || status === "waiting")
      ) {
        card++;
        totalCard += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.payMethod === "Efectivo" &&
        (status === "completed" || status === "waiting")
      ) {
        efectivo++;
        totalEfectivo += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      }
    });
    const daysOfWeek = eachDayOfInterval({ start: monday, end: sunday });
    daysOfWeek.forEach((day) => {
      const formattedDate = format(day, "yyyy-MM-dd");
      const ganancias = dailyGains[formattedDate] || 0;
      dataChart.push({ day: formattedDate, Ganancias: ganancias });
    });
    return {
      total: dataChart.map((item) => item.Ganancias).reduce((a, b) => a + b, 0),
      dataChart: dataChart,
      byStatus: {
        completed: completed,
        canceled: canceled,
        waiting: waiting,
        absent: absent,
      },
      byType: {
        fonasa: fonasa,
        isapre: isapre,
        card: card,
        efectivo: efectivo,
        totalEfectivo: totalEfectivo,
        totalCard: totalCard,
        totalIsapre: totalIsapre,
        totalFonasa: totalFonasa,
      },
    };
  } catch (error) {
    console.log(error);
    return false;
  }
}

function getMonday(currentDate) {
  const dayOfWeek = currentDate.getDay();
  const diff = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Ajuste si el domingo es el primer día de la semana
  return new Date(currentDate.setDate(diff));
}

function getSunday(currentDate) {
  const sunday = new Date(currentDate.getTime());
  sunday.setDate(currentDate.getDate() + (7 - currentDate.getDay()));
  return sunday;
}

export async function getMonthlyGains(idUser) {
  try {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Añadimos 1 porque los meses se indexan desde 0

    const dataByStatus = {};

    // Calcular la fecha de inicio y fin del mes actual
    const startDate = startOfMonth(currentDate);
    const endDate = endOfMonth(currentDate);

    // Realizar la consulta para obtener los pagos del mes actual
    const queryData = query(
      collection(db, "requests"),
      where("idDoctor", "==", idUser),
      where("dateCitation", ">=", Timestamp.fromDate(startDate)),
      where("dateCitation", "<=", Timestamp.fromDate(endDate))
    );

    const querySnapshot = await getDocs(queryData);

    // Agrupar los datos por status
    let total = 0;
    let totalApp = 0;
    let totalWeb = 0;
    let fonasa = 0;
    let isapre = 0;
    let card = 0;
    let efectivo = 0;
    let totalEfectivo = 0;
    let totalCard = 0;
    let totalIsapre = 0;
    let totalFonasa = 0;
    let completed = 0;
    let canceled = 0;
    let waiting = 0;
    let absent = 0;

    querySnapshot.forEach((doc) => {
      const status = doc.data().status;
      if (!dataByStatus[status]) {
        dataByStatus[status] = 1;
      } else {
        dataByStatus[status]++;
      }
      if (status === "completed" || status === "waiting") {
        if (doc.data().request.payData.amount) {
          const numberWithoutCurrency = doc
            .data()
            .request.payData.amount.toString()
            .toString()
            .replace(/\$|\.+/g, "");
          total += parseInt(numberWithoutCurrency, 10);
        }
      }

      if (
        doc.data().request.payData.typePay === "Fonasa" &&
        (status === "completed" || status === "waiting")
      ) {
        fonasa++;
        totalFonasa += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.typePay === "Isapre" &&
        (status === "completed" || status === "waiting")
      ) {
        isapre++;
        totalIsapre += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.payMethod === "webpay" &&
        (status === "completed" || status === "waiting")
      ) {
        card++;
        totalCard += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.payMethod === "Efectivo" &&
        (status === "completed" || status === "waiting")
      ) {
        efectivo++;
        totalEfectivo += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      }

      if (status === "completed") {
        completed++;
      } else if (status === "canceledByClient") {
        canceled++;
      } else if (status === "waiting") {
        waiting++;
      } else if (status === "clientAbsent") {
        absent++;
      }

      if (doc.data().request.familiarData.type === "none") {
        totalWeb += 1;
      } else {
        totalApp += 1;
      }
    });

    return {
      totalMonth: total,
      dataByStatus: dataByStatus,
      allData: querySnapshot.docs.map((doc) => doc.data()),
      totalApp: totalApp,
      totalWeb: totalWeb,
      byStatus: {
        completed: completed,
        canceled: canceled,
        waiting: waiting,
        absent: absent,
      },
      byType: {
        fonasa: fonasa,
        isapre: isapre,
        card: card,
        efectivo: efectivo,
        totalEfectivo: totalEfectivo,
        totalCard: totalCard,
        totalIsapre: totalIsapre,
        totalFonasa: totalFonasa,
      },
    };
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getMonthlyDataMedicalCenter(idUser) {
  try {
    const currentDate = new Date(
      new Date().toLocaleString("en-US", { timeZone: "America/Santiago" })
    );
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Añadimos 1 porque los meses se indexan desde 0

    const monday = getMonday(currentDate);
    const sunday = getSunday(currentDate);
    const formattedStartDate = format(monday, "yyyy-MM-dd");
    const formattedEndDate = format(sunday, "yyyy-MM-dd");

    const dataByStatusMonth = {};
    const dataByStatusWeek = {};
    const dataByStatusDay = {};

    // Calcular la fecha de inicio y fin del mes actual
    const startDate = startOfMonth(currentDate);
    const endDate = endOfMonth(currentDate);

    // Realizar la consulta para obtener los pagos del mes actual
    const queryData = query(
      collection(db, "requests"),
      // where("idDoctor", "==", idUser),
      where("dateCitation", ">=", Timestamp.fromDate(startDate)),
      where("dateCitation", "<=", Timestamp.fromDate(endDate)),
      where("doctor.medicalCenterId", "==", idUser)
    );

    const querySnapshot = await getDocs(queryData);

    // Agrupar los datos por status MONTH
    let totalMonth = 0;
    let totalAppMonth = 0;
    let totalWebMonth = 0;
    let fonasaMonth = 0;
    let isapreMonth = 0;
    let cardMonth = 0;
    let efectivoMonth = 0;
    let totalEfectivoMonth = 0;
    let totalCardMonth = 0;
    let totalIsapreMonth = 0;
    let totalFonasaMonth = 0;
    let completedMonth = 0;
    let canceledMonth = 0;
    let waitingMonth = 0;
    let absentMonth = 0;

    //DATA WEEKLY
    let dailyGains = {};
    let dataChart = [];
    let totalWeek = 0;
    let totalAppWeek = 0;
    let totalWebWeek = 0;
    let fonasaWeek = 0;
    let isapreWeek = 0;
    let cardWeek = 0;
    let efectivoWeek = 0;
    let totalEfectivoWeek = 0;
    let totalCardWeek = 0;
    let totalIsapreWeek = 0;
    let totalFonasaWeek = 0;
    let completedWeek = 0;
    let canceledWeek = 0;
    let waitingWeek = 0;
    let absentWeek = 0;
    let allDataWeek = [];

    //DATA DAILY
    let totalDay = 0;
    let totalAppDay = 0;
    let totalWebDay = 0;
    let fonasaDay = 0;
    let isapreDay = 0;
    let cardDay = 0;
    let efectivoDay = 0;
    let totalEfectivoDay = 0;
    let totalCardDay = 0;
    let totalIsapreDay = 0;
    let totalFonasaDay = 0;
    let completedDay = 0;
    let canceledDay = 0;
    let waitingDay = 0;
    let absentDay = 0;
    let allDataDay = [];

    let newDate = new Date(
      new Date().toLocaleString("en-US", { timeZone: "America/Santiago" })
    ).getDate();
    querySnapshot.forEach((doc) => {
      const dateRequest = new Date(
        doc.data().request.date + "T00:00:00"
      ).getDate();


      const status = doc.data().status;
      //Con esto puedo sacar todos los datos del día actual
      if (newDate === dateRequest) {
        allDataDay.push(doc.data());
        if (!dataByStatusDay[status]) {
          dataByStatusDay[status] = 1;
        } else {
          dataByStatusDay[status]++;
        }

        if (status === "completed" || status === "waiting") {
          if (doc.data().request.payData.amount) {
            const numberWithoutCurrency = doc
              .data()
              .request.payData.amount.toString()
              .replace(/\$|\.+/g, "");
            totalDay += parseInt(numberWithoutCurrency, 10);
          }
        }

        if (
          doc.data().request.payData.typePay === "Fonasa" &&
          (status === "completed" || status === "waiting")
        ) {
          fonasaDay++;
          totalFonasaDay += parseInt(
            doc
              .data()
              .request.payData.amount.toString()
              .replace(/\$|\.+/g, ""),
            10
          );
        } else if (
          doc.data().request.payData.typePay === "Isapre" &&
          (status === "completed" || status === "waiting")
        ) {
          isapreDay++;
          totalIsapreDay += parseInt(
            doc
              .data()
              .request.payData.amount.toString()
              .replace(/\$|\.+/g, ""),
            10
          );
        } else if (
          doc.data().request.payData.payMethod === "webpay" &&
          (status === "completed" || status === "waiting")
        ) {
          cardDay++;
          totalCardDay += parseInt(
            doc
              .data()
              .request.payData.amount.toString()
              .replace(/\$|\.+/g, ""),
            10
          );
        } else if (
          doc.data().request.payData.payMethod === "Efectivo" &&
          (status === "completed" || status === "waiting")
        ) {
          efectivoDay++;
          totalEfectivoDay += parseInt(
            doc
              .data()
              .request.payData.amount.toString()
              .replace(/\$|\.+/g, ""),
            10
          );
        }

        if (status === "completed") {
          completedDay++;
        } else if (status === "canceledByClient") {
          canceledDay++;
        } else if (status === "waiting") {
          waitingDay++;
        } else if (status === "clientAbsent") {
          absentDay++;
        }

        if (doc.data().request.familiarData.type === "none") {
          totalWebDay += 1;
        } else {
          totalAppDay += 1;
        }
      }

      //Terminan los datos diarios
    });

    //-------------------------------------------------------------------------------------------------
    //Comienzan datos semanales

    querySnapshot.forEach((doc) => {
      const status = doc.data().status;
      const amount = doc.data().request.payData.amount;
      const requestDate = doc.data().request.date;
      if (amount && (status === "completed" || status === "waiting")) {
        const numberWithoutCurrency = amount.replace(/\$|\.+/g, "");
        const gain = parseInt(numberWithoutCurrency, 10);
        // Verificar si ya existe una entrada para este día
        if (dailyGains[requestDate]) {
          // Si ya existe, sumar las ganancias
          dailyGains[requestDate] += gain;
        } else {
          // Si no existe, crear una nueva entrada
          dailyGains[requestDate] = gain;
        }
      }

      if (status === "completed") {
        completedWeek++;
      } else if (status === "canceledByClient") {
        canceledWeek++;
      } else if (status === "waiting") {
        waitingWeek++;
      } else if (status === "clientAbsent") {
        absentWeek++;
      }

      if (
        doc.data().request.payData.typePay === "Fonasa" &&
        (status === "completed" || status === "waiting")
      ) {
        fonasaWeek++;
        totalFonasaWeek += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.typePay === "Isapre" &&
        (status === "completed" || status === "waiting")
      ) {
        isapreWeek++;
        totalIsapreWeek += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.payMethod === "webpay" &&
        (status === "completed" || status === "waiting")
      ) {
        cardWeek++;
        totalCardWeek += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.payMethod === "Efectivo" &&
        (status === "completed" || status === "waiting")
      ) {
        efectivoWeek++;
        totalEfectivoWeek += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      }
    });
    const daysOfWeek = eachDayOfInterval({ start: monday, end: sunday });
    daysOfWeek.forEach((day) => {
      const formattedDate = format(day, "yyyy-MM-dd");
      const ganancias = dailyGains[formattedDate] || 0;
      dataChart.push({ day: formattedDate, Ganancias: ganancias });
    });

    //Terminan datos semanales
    //-------------------------------------------------------------------------------------------------
    //Comienzan datos mensuales
    querySnapshot.forEach((doc) => {
      const status = doc.data().status;
      if (!dataByStatusMonth[status]) {
        dataByStatusMonth[status] = 1;
      } else {
        dataByStatusMonth[status]++;
      }
      if (status === "completed" || status === "waiting") {
        if (doc.data().request.payData.amount) {
          const numberWithoutCurrency = doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, "");
          totalMonth += parseInt(numberWithoutCurrency, 10);
        }
      }

      if (
        doc.data().request.payData.typePay === "Fonasa" &&
        (status === "completed" || status === "waiting")
      ) {
        fonasaMonth++;
        totalFonasaMonth += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.typePay === "Isapre" &&
        (status === "completed" || status === "waiting")
      ) {
        isapreMonth++;
        totalIsapreMonth += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.payMethod === "webpay" &&
        (status === "completed" || status === "waiting")
      ) {
        cardMonth++;
        totalCardMonth += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      } else if (
        doc.data().request.payData.payMethod === "Efectivo" &&
        (status === "completed" || status === "waiting")
      ) {
        efectivoMonth++;
        totalEfectivoMonth += parseInt(
          doc
            .data()
            .request.payData.amount.toString()
            .replace(/\$|\.+/g, ""),
          10
        );
      }

      if (status === "completed") {
        completedMonth++;
      } else if (status === "canceledByClient") {
        canceledMonth++;
      } else if (status === "waiting") {
        waitingMonth++;
      } else if (status === "clientAbsent") {
        absentMonth++;
      }

      if (doc.data().request.familiarData.type === "none") {
        totalWebMonth += 1;
      } else {
        totalAppMonth += 1;
      }
    });

    return {
      dataMonthly: {
        totalMonth: totalMonth,
        dataByStatus: dataByStatusMonth,
        allData: querySnapshot.docs.map((doc) => doc.data()),
        totalApp: totalAppMonth,
        totalWeb: totalWebMonth,
        byStatus: {
          completed: completedMonth,
          canceled: canceledMonth,
          waiting: waitingMonth,
          absent: absentMonth,
        },
        byType: {
          fonasa: fonasaMonth,
          isapre: isapreMonth,
          card: cardMonth,
          efectivo: efectivoMonth,
          totalEfectivo: totalEfectivoMonth,
          totalCard: totalCardMonth,
          totalIsapre: totalIsapreMonth,
          totalFonasa: totalFonasaMonth,
        },
      },
      dataWeekly: {
        dataChart: dataChart,
        totalWeek: totalWeek,
        dataByStatus: dataByStatusWeek,
        allData: allDataWeek,
        totalApp: totalAppWeek,
        totalWeb: totalWebWeek,
        byStatus: {
          completed: completedWeek,
          canceled: canceledWeek,
          waiting: waitingWeek,
          absent: absentWeek,
        },
        byType: {
          fonasa: fonasaWeek,
          isapre: isapreWeek,
          card: cardWeek,
          efectivo: efectivoWeek,
          totalEfectivo: totalEfectivoWeek,
          totalCard: totalCardWeek,
          totalIsapre: totalIsapreWeek,
          totalFonasa: totalFonasaWeek,
        },
      },
      dataDaily: {
        totalDay: totalDay,
        dataByStatus: dataByStatusDay,
        allData: allDataDay,
        totalApp: totalAppDay,
        totalWeb: totalWebDay,
        byStatus: {
          completed: completedDay,
          canceled: canceledDay,
          waiting: waitingDay,
          absent: absentDay,
        },
        byType: {
          fonasa: fonasaDay,
          isapre: isapreDay,
          card: cardDay,
          efectivo: efectivoDay,
          totalEfectivo: totalEfectivoDay,
          totalCard: totalCardDay,
          totalIsapre: totalIsapreDay,
          totalFonasa: totalFonasaDay,
        },
      },
    };
  } catch (error) {
    console.log(error);
    return false;
  }
}
