import React, { useState, useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, CssBaseline } from "@mui/material";
import MyDrawer from "./my_drawer";
import AppBarHeader from "./app_bar_header";
import { useTitle } from "../context/TitleContext";

const drawerWidth = 280; // Cambia este valor según sea necesario
const appBarHeight = 64;

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { title, setTitle } = useTitle();
  const location = useLocation();

  useEffect(() => {
    const pathTitles = {
      "/": "Inicio",
      "/home": "Inicio",
      "/calendar": "Agenda",
      "/settings": "Configuración",
      "/chatList": "Mensajes",
      "/videocalls": "Videollamadas",
      "/patientEntry": "Nueva atención",
      "/medicalRecords": "Registros Médicos",
      "/reporting": "Reportes",
      "/homeAdmin": "Inicio Administrador",
      "/homeMedicalCenter": "Inicio Centro Médico",
      "/professionals": "Profesionales",
      "/reserves": "Reservas",
    };
    setTitle(pathTitles[location.pathname] || "Default Title");
  }, [location.pathname, setTitle]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box 
      sx={{ 
        display: "flex",
        scrollbarWidth: "none",
      }}>
      <CssBaseline />
      <AppBarHeader
        title={title}
        handleDrawerToggle={handleDrawerToggle}
        appBarHeight={appBarHeight}
        drawerWidth={drawerWidth}
      />
      <MyDrawer
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          /* background: "red", */
          py: { xs: 0, sm: 2 },
          pl: { xs: 0, sm: 2, lg: 4 },
          pr: { xs: 0, sm: 2, lg: 4 },
          m: 0,
          mt: `${appBarHeight}px`,
          ml: { lg: `${drawerWidth}px` },
          width: { xs: `calc(100hw - ${drawerWidth}px)` },
          maxWidth: { xs: `calc(100hw - ${drawerWidth}px)` },
          height: { xs: `calc(100vh - ${appBarHeight}px)` },
          maxHeight: { xs: `calc(100vh - ${appBarHeight}px)` },
          overflow: "auto",
          scrollbarWidth: "none",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Layout;
