import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { IMicrophoneAudioTrack, ICameraVideoTrack } from "agora-rtc-sdk-ng";
import {
  useRTCClient,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  useJoin,
  useRemoteUsers,
  usePublish,
  useClientEvent,
  LocalVideoTrack,
  RemoteUser,
} from "agora-rtc-react";
import { Box, Button, CardActions, Container, IconButton, useMediaQuery } from "@mui/material";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import CallEndIcon from '@mui/icons-material/CallEnd';
import CameraAndMicrophonePermissions from "../utils/get_permissions";
import Loading from "../assets/logo_Loading.gif";
import { AssignmentOutlined, CallEnd, MicOffOutlined, MicOutlined, VideocamOffOutlined, VideocamOutlined } from "@mui/icons-material";

const AgoraContext = createContext(null);

export const AgoraProvider: React.FC<AgoraContextType> = ({
  children,
  localCameraTrack,
  localMicrophoneTrack,
}) => (
  <AgoraContext.Provider
    value={{ localCameraTrack, localMicrophoneTrack, children }}
  >
    {children}
  </AgoraContext.Provider>
);

export const AgoraManager = ({ data, func }) => {
  let dataToAtention = data.dataAtention;
  const agoraEngine = useRTCClient();
  const { isLoading: isLoadingCam, localCameraTrack } = useLocalCameraTrack();
  const { isLoading: isLoadingMic, localMicrophoneTrack } =
    useLocalMicrophoneTrack();
  const remoteUsers = useRemoteUsers();
  const [mic, setMic] = useState(true); // Estado que indica si el micrófono está activo o no
  const [cam, setCam] = useState(true); // Estado que indica si la cámara está activa o no


  const [camerePosition, setCameraPosition] = useState(true);

  let cameraStyles = {
    cameraBig: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "black",
      zIndex: "1" /* Este es el z-index más bajo */,
    },

    cameraSmall: {
      position: "absolute",
      bottom: "8px",
      right: "8px",
      height: "50%",
      width: "30%",
      backgroundColor: "black",
      zIndex: "2",
      borderRadius: "28px",
    },
  };

  const handleChangeCamera = () => {
    setCameraPosition(!camerePosition);
  };

  // const [width, setWidth] = useState(200); // Ancho inicial del div
  // const [isResizing, setIsResizing] = useState(false);

  // const handleMouseDown = (e) => {
  //   setIsResizing(true);
  // };

  // const handleMouseUp = (e) => {
  //   setIsResizing(false);
  // };

  // const handleMouseMove = (e) => {
  //   if (!isResizing) return;

  //   const newWidth = e.clientX; // Actualiza el ancho con la posición actual del mouse
  //   setWidth(newWidth);
  // };

  // Función para cambiar el estado del micrófono
  const toggleMic = () => {
    if (mic) {
      localMicrophoneTrack.setEnabled(false); // Desactiva la pista del micrófono
    } else {
      localMicrophoneTrack.setEnabled(true); // Activa la pista del micrófono
    }
    setMic(!mic); // Cambia el estado del micrófono
  };

  

  // Publish local tracks (cam and mic)
  usePublish([localMicrophoneTrack, localCameraTrack]);

  // Join the Agora channel with the specified configuration
  useJoin({
    //Hay que usar un .env para esto
    appid: "4835f36c295a4d1ea31b75a274c13814",
    channel: data.channel,
    uid: 1,
    token: data.token,
  });

  useClientEvent(agoraEngine, "user-joined", (user: any) => {
    // console.log("The user", user.uid, "has joined the channel");
  });

  useClientEvent(agoraEngine, "user-left", (user: any) => {
    // console.log("The user", user.uid, "has left the channel");
  });

  useClientEvent(
    agoraEngine,
    "user-published",
    (user: any, mediaType: string) => {
      // console.log("The user", user.uid, "has published media in the channel");
    }
  );

  useEffect(() => {
    return () => {
      localCameraTrack?.close();
      localMicrophoneTrack?.close();
      // console.log("ALO");
    };
    // console.log(object);
  }, []);

  // Check if devices are still loading
  const deviceLoading = isLoadingMic || isLoadingCam;
  if (deviceLoading)
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <img
          src={Loading}
          alt="Cargando"
          style={{ width: "10vw", height: "14vh" }}
        />
        Cargando cámara y microfono...
      </div>
    );

  // Render the AgoraProvider and associated UI components
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Box sx={{ flex: 1, position: "relative", display: "flex" }}>
        <AgoraProvider
          localCameraTrack={localCameraTrack}
          localMicrophoneTrack={localMicrophoneTrack}
        >
          <Box
            className="user"
            sx={
              camerePosition ? cameraStyles.cameraBig : cameraStyles.cameraSmall
            }
            onDoubleClick={handleChangeCamera}
          >
            {localCameraTrack && (
              <LocalVideoTrack track={localCameraTrack} play={true}>
                {/* <samp className="user-name">You</samp> */}
              </LocalVideoTrack>
            )}
          </Box>

          {remoteUsers.map((remoteUser) => (
            <div
              key={remoteUser.uid}
              style={{
                ...(
                  camerePosition 
                  ? cameraStyles.cameraSmall 
                  : cameraStyles.cameraBig
                ),
                borderRadius: "28px",
              }}
              onDoubleClick={handleChangeCamera}
            >
              <RemoteUser
                cover={
                  "https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                }
                user={remoteUser}
                playVideo={true}
                playAudio={true}
              >
                <span className="user-name">{remoteUser.uid}</span>
              </RemoteUser>
            </div>
          ))}
        </AgoraProvider>
      </Box>
      <CardActions
        sx={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "80px",
          width: "100%",
        }}
      >
        <IconButton
          onClick={toggleMic}
          sx={{
            mx: 2,
            color: "#1c1c1c",
            backgroundColor: "#f2f2f2",
            borderRadius: "50%",
            padding: 2,
          }}
        >
          {mic ? <MicOutlinedIcon /> : <MicOffOutlinedIcon />}
        </IconButton>
        <IconButton
          onClick={() => {
            if (cam) {
              localCameraTrack.setEnabled(false); // Desactiva la pista de la cámara
            } else {
              localCameraTrack.setEnabled(true); // Activa la pista de la cámara
            }
            setCam(!cam); // Cambia el estado de la cámara
          }}
          sx={{
            mx: 2,
            color: "#1c1c1c",
            backgroundColor: "#f2f2f2",
            borderRadius: "50%",
            padding: 2,
          }}
        >
          {cam ? <VideocamOutlinedIcon /> : <VideocamOffOutlinedIcon />}
        </IconButton>

        <IconButton
          onClick={() => {
            const datosCodificados = encodeURIComponent(
              JSON.stringify(dataToAtention)
            );
            const nuevaPestana = window.open("", "_blank");
            nuevaPestana.location.href = `/patientEntry?data=${datosCodificados}`;
          }}
          sx={{
            mx: 2,
            backgroundColor: "#166683",
            borderRadius: "50%",
            padding: 2,
            color: "#fff",
            display: { xs: "inline-flex", sm: "none" },
          }}
        >
          <AssignmentOutlinedIcon />
        </IconButton>
        <IconButton
          onClick={func}
          sx={{
            mx: 2,
            color: "#fff",
            backgroundColor: "#d32f2f",
            borderRadius: "50%",
            padding: 2,
            display: { xs: "inline-flex", sm: "none" },
          }}
        >
          <CallEndIcon />
        </IconButton>

        <Button
          variant="contained"
          disableElevation
          sx={{
            background: "#006686",
            color: "#77F8E0",
            ":hover": { background: "77F8E0", color: "006686" },
            display: { xs: "none", sm: "inline-flex" },
          }}
          onClick={() => {
            const datosCodificados = encodeURIComponent(
              JSON.stringify(dataToAtention)
            );
            const nuevaPestana = window.open("", "_blank");
            nuevaPestana.location.href = `/patientEntry?data=${datosCodificados}`;
          }}
        >
          Ficha de atención
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="error"
          sx={{
            display: { xs: "none", sm: "inline-flex" },
          }}
          onClick={func}
        >
          Finalizar llamada
        </Button>
      </CardActions>
    </Box>
  );
};
