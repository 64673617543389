import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
} from "@mui/material";
import Cropper from "react-easy-crop";
import Files from "react-files";
import { uploadFile } from "../database/doctors";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
import { useDispatch, useSelector } from "react-redux";
import imageCompression from 'browser-image-compression';

function ChangeImageCrop({ open, close, data, setImageCrop, type }) {
  const userData = useSelector((state) => state.auth.userData);

  const dispatch = useDispatch();

  const [imageData, setImageData] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cutImage, setCutImage] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const handleCloseImage = () => {
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setImageData("");
    close(false);
  };
  const styleImage = {
    width: "25vw",
    height: "25vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    border: "1px solid #006686",
    color: "#006686",
    borderRadius: "25px 25px 25px 25px",
    cursor: "pointer",
    padding: "10px",
  };
  //   const handleCrop = async () => {
  //     const croppedImageBlob = await getCroppedImg(
  //       imageData.preview.url,
  //       crop,
  //       "newFile.jpeg" // Nombre de archivo de la imagen recortada
  //     );
  //     setCroppedImage(URL.createObjectURL(croppedImageBlob));
  //   };

  //   // Función para obtener la imagen recortada
  //   const getCroppedImg = (imageSrc, crop, fileName) => {
  //     const image = new Image();
  //     image.src = imageSrc;
  //     const canvas = document.createElement("canvas");
  //     const ctx = canvas.getContext("2d");

  //     const scaleX = image.naturalWidth / image.width;
  //     const scaleY = image.naturalHeight / image.height;
  //     canvas.width = crop.width;
  //     canvas.height = crop.height;

  //     ctx.drawImage(
  //       image,
  //       crop.x * scaleX,
  //       crop.y * scaleY,
  //       crop.width * scaleX,
  //       crop.height * scaleY,
  //       0,
  //       0,
  //       crop.width,
  //       crop.height
  //     );

  //     return new Promise((resolve, reject) => {
  //       canvas.toBlob((blob) => {
  //         if (!blob) {
  //           // Si falla la conversión a Blob
  //           console.error("Error al recortar la imagen");
  //           return;
  //         }
  //         blob.name = fileName; // Establecer el nombre de archivo
  //         resolve(blob);
  //       }, "image/jpeg"); // Puedes cambiar el formato si es necesario
  //     });
  //   };
  const handleEditImageLogo = (e) => {
    let file = e[0];
    setImageData(file);
    setImageCrop(file);
  };
  //   const updatedUserData = {
  //     ...userData,
  //     imageProfile: imageData.preview.url,
  //   };
  //   const handlechangePhoto = () => {
  //     dispatch({
  //       type: "UPDATE_USER_DATA",
  //       payload: updatedUserData,
  //     });
  //   };
  const save = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(imageData, options);

    const resImage = await uploadFile(compressedFile, data.id, type);
    if (resImage) {
      Swal.close();
      //   handlechangePhoto();
      Swal.fire({
        icon: "success",
        title:
          type === "logo" ? "Logo actualizado" : "Imagen de perfil actualizada",
        text:
          type == "logo"
            ? "El logo ha sido actualizado correctamente."
            : `La imagen de perfil ha sido actualizada correctamente. `,
        showConfirmButton: false,
        timer: 2500,
      }).then(() => {
        handleCloseImage();
        // location.reload();
        window.location.reload();
      });
    } else {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error al actualizar la imagen",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => handleCloseImage());
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        maxWidth={"sm"}
      // PaperProps={{ borderRadius: "25px" }}
      >
        <DialogTitle>
          Selecciona tu {type === "logo" ? "logo" : "foto de perfil"}
        </DialogTitle>
        <DialogContent
          sx={{
            width: "30vw",
            height: "25vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            overflow: "hidden",
          }}
        >
          {imageData !== "" ? (
            <div
              style={{
                width: "25vw",
                height: "25vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="crop-container" style={styleImage}>
                <Cropper
                  image={imageData.preview.url}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 3}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                />
                <img
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                  className="files-gallery-item"
                  src={imageData.preview.url}
                  alt="photo"
                  onClick={() => {
                    setImageData("");
                  }}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                {/* <Button
                  onClick={() => {
                    handleCrop();
                    setCutImage(true);
                  }}
                >
                  {" "}
                  Recortar
                </Button> */}
              </div>
            </div>
          ) : (
            <Files
              className="files-dropzone"
              onChange={(e) => {
                handleEditImageLogo(e);
              }}
              // onError={handleError}
              accepts={["image/*"]}
              clickable
              style={{
                width: "25vw",
                height: "25vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "white",
                border: "1px solid #006686",
                color: "#006686",
                borderRadius: "25px 25px 25px 25px",
                cursor: "pointer",
                padding: "10px",
              }}
              maxFiles={1}
            >
              Arrastre o haga click para seleccionar
            </Files>
          )}
          {/* <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
           
          </Container> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImage}>Cancelar</Button>
          <Button onClick={save} variant="contained" color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ChangeImageCrop;
