import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  IconButton,
  Checkbox,
  ListItem,
  List,
  ListSubheader,
  Box,
  Divider,
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import { set } from "date-fns";
import { arrExam } from "../utils/exams";
import { useEffect } from "react";
import { capitalize } from "../utils/functions";
// import { examContext } from "../context/examContext";
// import {PersonOutlinedIcon} from '@mui/icons-material';

export default function ExamRequest({ open, close, valor, setArr, arr }) {
  // const [checked, setChecked] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [type, setType] = useState("");
  const [selectedExams, setSelectedExams] = useState([]);

  function handleChange(exam) {
    if (
      selectedExams.some(
        (item) => item.exam === exam.exam && item.status === "pending"
      )
    ) {
      setSelectedExams(selectedExams.filter((item) => item.exam !== exam.exam));
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item.exam !== exam.exam)
      );
    } else {
      setSelectedExams([...selectedExams, exam]);
      setSelectedCheckboxes([...selectedCheckboxes, exam]);
    }
  }
  function generarCodigoAleatorio(longitud) {
    const caracteres = "0123456789";
    let codigo = "";

    for (let i = 0; i < longitud; i++) {
      const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
      codigo += caracteres.charAt(indiceAleatorio);
    }

    return codigo;
  }

  function handleEnd() {
    const examId = generarCodigoAleatorio(10);
    // let a = {};
    // function limpiarLista(lista) {
    //   const cleanedObject = {};

    //   lista.forEach((item, index) => {
    //     const examName = item.substring(item.indexOf("-") + 1).trim();
    //     cleanedObject[`objet`] = { exam: examName, status: "pending" };
    //   });
    //   console.log("cleanedObject", cleanedObject);
    //   return cleanedObject;
    // }

    // console.log("selectedCheckboxes", selectedCheckboxes);
    // a = limpiarLista(selectedCheckboxes);
    // addToArr(a);
    // setType("");
    // setSelectedCheckboxes([]);
    setArr([...selectedExams]);
    close();
  }

  function calculateAge(birthdate) {
    const partesFecha = birthdate.split("/");
    const birthdateObj = new Date(
      partesFecha[2],
      partesFecha[1] - 1,
      partesFecha[0]
    );
    const tiempoDiferencia = Date.now() - birthdateObj.getTime();
    const edad = Math.floor(tiempoDiferencia / (365.25 * 24 * 60 * 60 * 1000));
    return edad;
  }

  useEffect(() => {
    function setData() {
      setSelectedExams([...arr]);
    }
    setData();
  }, [arr]);

  // const handleToggle = (examId, element, tipo) => {
  //   const identifier = `${examId}-${element}`;
  //   const currentIndex = selectedCheckboxes.indexOf(identifier);
  //   const newSelected = [...selectedCheckboxes];
  //   console.log("tiogjnlkdfsjklhfgds", tipo);
  //   if (currentIndex === -1) {
  //     newSelected.push(identifier);
  //   } else {
  //     newSelected.splice(currentIndex, 1);
  //     setType("");
  //   }
  //   setType(tipo);
  //   setSelectedCheckboxes(newSelected);
  // };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={close}
      sx={{ zIndex: "2000" }}
      /* position={"fixed"} */
      PaperProps={{
        style: {
          backgroundColor: "#EEE8F4",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",

          borderRadius: "28px",
        },
      }}
    >
      <Card
        sx={{
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <CardHeader
          title={`Toma de exámenes`}
          // subheader="September 14, 2016"
          sx={{ borderBottom: "1px solid rgba(0,0,0,0.12)" }}
        />

        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          {/* <Typography variant="h5" sx={{ marginBottom: 3 }}>
            Datos del paciente
          </Typography>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "flex-start",
                height: "10vh",
              }}
            >
              <Typography variant="body">Nombre:</Typography>
              <Typography variant="body">Apellido:</Typography>
              <Typography variant="body">Rut:</Typography>
              <Typography variant="body">Domicilio:</Typography>
            </Container>
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "flex-start",
                height: "10vh",
                color: "rgba(0,0,0,0.6)",
              }}
            >
              <Typography variant="body">
                {capitalize(
                  valor.request.familiarData.name
                    ? valor.request.familiarData.name
                    : valor.client.name
                )}
              </Typography>
              <Typography variant="body">
                {capitalize(
                  valor.request.familiarData.lastName
                    ? valor.request.familiarData.lastName
                    : valor.client.lastName
                )}
              </Typography>

              <Typography variant="body">
                {valor.request.familiarData.rut
                  ? valor.request.familiarData.rut
                  : valor.client.rut}
              </Typography>
              <Typography variant="body">
                {capitalize(
                  valor.request.familiarData.address
                    ? valor.request.familiarData.address
                    : valor.client.location.address
                )}, {capitalize(
                  valor.request.familiarData.comuna
                    ? valor.request.familiarData.comuna
                    : valor.client.location.comuna
                )}

              </Typography>
            </Container>

            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "flex-start",
                height: "10vh",
              }}
            >
              <Typography variant="body">Fecha de nacimiento:</Typography>
              <Typography variant="body">Edad:</Typography>
              <Typography variant="body">Género:</Typography>
              <Typography variant="body">Previsión:</Typography>
            </Container>
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "flex-start",
                height: "10vh",
                color: "rgba(0,0,0,0.6)",
              }}
            >
              <Typography variant="body">
                {valor.request.familiarData.birthday
                  ? valor.request.familiarData.birthday
                  : valor.client.birthday}
              </Typography>
              <Typography variant="body">
                {calculateAge(valor.client.birthday)}
              </Typography>
              <Typography variant="body">{valor.client.genre}</Typography>
              <Typography variant="body">
                {capitalize(valor.request.payData.typePay)}
              </Typography>
            </Container>
          </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginBottom: "3vh",
              width: "100%",
              height: "100%",
              overflowX: "auto",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                {arrExam.map((exam) => (
                  <div key={exam.id}>
                    <ListSubheader
                      component="div"
                      id={`exam-list-label-${exam.id}`}
                      sx={{
                        color: "#006686",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      {exam.tipo}
                    </ListSubheader>
                    <div>
                      {exam.elementos.map((element) => (
                        <ListItem
                          key={element}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="comments"
                            ></IconButton>
                          }
                          onClick={() => {
                            handleChange({
                              exam: element,
                              status: "pending",
                            });
                          }}
                          disablePadding
                        >
                          <ListItemButton dense>
                            {/* <ListItemIcon> */}
                            {/* <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              // checked={selectedExams.some(item => item.exam === element && item.status === "pending")}
                              onChange={(event) => {
                                // Aquí puedes manejar el cambio del checkbox, por ejemplo, actualizando el estado
                                handleChange({
                                  exam: element,
                                  status: "pending"  // Cambia "otro_estado" según sea necesario
                                });
                              }}
                            /> */}
                            <input
                              type="checkbox"
                              checked={selectedExams.some(
                                (item) =>
                                  item.exam === element &&
                                  item.status === "pending"
                              )}
                              onChange={() => {
                                handleChange({
                                  exam: element,
                                  status: "pending",
                                });
                              }}
                            />
                            <ListItemText
                            sx={{
                              marginLeft: 2,
                             
                            }}
                              primary={element}
                              primaryTypographyProps={{
                                fontSize: "16px", // Ejemplo usando el tamaño de fuente del tema
                              }}
                              
                              // secondary={
                              //   selectedExams.filter(
                              //     {
                              //       exam: element,
                              //       status: "pending"
                              //     }
                              //   ) ? "Pendiente" : "Realizado"
                              // }
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </div>
                  </div>
                ))}
              </List>
            </div>
            <div
              style={{
                height: "100%",
               
                display: "flex",
                flexWrap: "wrap",
              }}
            ></div>
          </div>
        </CardContent>
        <Box
              sx={{
                zIndex: '200',
                width: '100%',
                backgroundColor: 'white',
                position: 'sticky',
                bottom: 0,
              }}
            >
              <Divider />
              <CardActions
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  py: 2,
                  px: 2,
                  width: '100%',
                }}
              >
                 <Button
            variant="text"
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              handleEnd();
            }}
          >
            Concluir
          </Button>
              </CardActions>
            </Box>
      </Card>
    </Dialog>
  );
}
