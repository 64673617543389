import {
  Button,
  Container,
  IconButton,
  TextField,
  Typography,
  Box,
  Divider,
  CardActions,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getDoctorById, updateSettingsPOS } from "../database/doctors";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function SettingsPos() {
  const [codePos, setCodePos] = useState("");
  const [rutPos, setRutPos] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [accId, setAccId] = useState("");
  const myId = useSelector((state) => state.auth.idUser);
  const myData = useSelector((state) => state.auth.userData);
  const [showSecretKey, setShowSecretKey] = useState(false);

  const toggleShowSecretKey = () => {
    setShowSecretKey(!showSecretKey);
  };

  useEffect(() => {
    const first = async () => {
      try {
        let res;
        if (myData.role === "doctor") {
          res = await getDoctorById(myId);
        } else {
          res = await getDoctorById(myData.idDoctor);
        }
        setCodePos(res.codePos ?? "0");
        setRutPos(res.rutPos ?? "0");
        setSecretKey(res.secretKey ?? "0");
        setAccId(res.accountId ?? "0");
      } catch (error) {
        console.error("Error en la primera llamada", error);
      }
    };
    first();
  }, [myId, myData.role, myData.idDoctor]);

  const handleChangeCode = (event) => {
    const newValue = event.target.value;
    setCodePos(newValue);
  };

  const handleChangeRut = (event) => {
    const newValue = event.target.value;
    setRutPos(newValue);
  };

  const handleChangeSecretKey = (event) => {
    const newValue = event.target.value;
    setSecretKey(newValue);
  };

  const handleChangeAccId = (event) => {
    const newValue = event.target.value;
    setAccId(newValue);
  };

  const saveValue = async () => {
    const res = await updateSettingsPOS(
      myData.role === "doctor" ? myId : myData.idDoctor,
      codePos,
      rutPos,
      accId,
      secretKey
    );
    if (res) {
      toast.success("Valor guardado");
    } else {
      toast.error("Error al guardar el valor");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 0,
        height: "100%",
      }}
    >
      <Box
        sx={{
          p: 2,
          pb: 12,
          flexGrow: 1,
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            display: "flex",
            py: 2,
            mt: 1,
          }}
          > 
          <Typography variant="titleSmall">
            Datos del POS
          </Typography>
          </Box>
        <Grid container spacing={2} sx={{}}>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{
                width: "100%",
                backgroundColor: "#DCE3E9",
                color: "black",
              }}
              type="text"
              value={codePos}
              onChange={handleChangeCode}
              label="Código del POS"
              variant="filled"
              placeholder="Ingrese el código del POS"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{
                width: "100%",
                backgroundColor: "#DCE3E9",
                color: "black",
              }}
              type="text"
              value={rutPos}
              onChange={handleChangeRut}
              label="Rut del comercio"
              variant="filled"
              placeholder="Ingrese el rut del comercio con puntos y guión"
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            py: 2,
            mt: 1,
          }}
          > 
          <Typography variant="titleSmall">
            Datos de Webpay
          </Typography>
          </Box>
        <Grid container spacing={2} sx={{}}>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{
                width: "100%",
                backgroundColor: "rgba(220, 227, 233, 1)",
                color: "black",
              }}
              type="text"
              value={accId}
              onChange={handleChangeAccId}
              label="Serial number"
              variant="filled"
              placeholder="Ingrese el código del POS"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{
                width: "100%",
                backgroundColor: "rgba(220, 227, 233, 1)",
                color: "black",
              }}
              type={showSecretKey ? "text" : "password"}
              value={secretKey}
              onChange={handleChangeSecretKey}
              label="Clave secreta Webpay"
              variant="filled"
              placeholder="Ingrese el rut del comercio con puntos y guión"
              InputProps={{
                endAdornment: (
                  <IconButton onClick={toggleShowSecretKey} edge="end">
                    {showSecretKey ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
      <Box
        sx={{
          zIndex: 200,
          width: "100%",
          backgroundColor: "white",
          position: "sticky",
          bottom: { xs: "0px", sm: "0px", md: "60px" },
        }}
      >
        <Divider />
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            py: 3,
            px: 2,
            width: "100%",
          }}
        >
          <Button variant="contained" disableElevation onClick={saveValue}>
            Guardar
          </Button>
        </CardActions>
      </Box>
    </Box>
  );
  
}
