import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  TextField,
  Typography,
  IconButton,
  Grid,
  Box,
  Divider,
  Paper,
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { useState } from "react";
import { createMedicalRecord } from "../database/medical_records";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeviceThermostatOutlinedIcon from "@mui/icons-material/DeviceThermostatOutlined";
import BloodPressure from "../Icons/filled/devices/blood_pressure_2.svg";
import Lungs from "../Icons/filled/body/lungs.svg";
import Diabetes from "../Icons/filled/symbols/diabetes.svg";
import Cardiogram from "../Icons/filled/symbols/cardiogram.svg";
import Oxigen from "../Icons/filled/devices/oxygen_tank.svg";
import Pressure from "../Icons/filled/devices/blood_pressure.svg";
import HeartCardiogram from "../Icons/filled/symbols/heart_cardiogram.svg";
import Weight from "../Icons/filled/devices/weight.svg";
import Allergies from "../Icons/filled/conditions/allergies.svg";
import FClinical from "../Icons/filled/symbols/clinical_f.svg";
import Spreadsheets from "../Icons/outline/objects/spreadsheets.svg";
import Referal from "../Icons/filled/symbols/referral.svg";
import RegisterBook from "../Icons/filled/symbols/register_book.svg";
import ExamQualification from "../Icons/filled/symbols/i_exam_qualification.svg";
import Chart from "../Icons/filled/symbols/chart_bar_stacked.svg";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ExamRequest from "./examRequest";
import { completeRequest, updateRequestStatus } from "../database/request";
import { Print } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect } from "react";
import { capitalizeAll, capitalize } from "../utils/functions";
import { getChatId, createChat, activateChat } from "../database/chats";
import loadingSVG from "../assets/logo_Loading.gif";
// import DrawerComponent from "./drawer_component";
import TopBar from "./top_bar";
import { getDoctorById } from "../database/doctors";
import { useSelector } from "react-redux";
import DialogPDFs from "./dialog_pdfs";
import {
  generatePDFFichaMedica,
  generatePDFIndications,
  generatePDFReceta,
} from "../utils/generate_pdfs";
// import NewDrawerComponent from "./new_drawer";

export default function PatientEntry() {
  const navigation = useNavigate();
  const [symptoms, setSymptoms] = useState("");
  const [armpitTemp, setArmpitTemp] = useState("");
  const [diastolicPressure, setDiastolicPressure] = useState("");
  const [respiratoryFrequency, setRespiratoryFrequency] = useState("");
  const [hemoGlucose, setHemoGlucose] = useState("");
  const [rectalTemp, setRectalTemp] = useState("");
  const [pulse, setPulse] = useState("");
  const [oxygenSaturation, setOxygenSaturation] = useState("");
  const [glasgow, setGlasgow] = useState("");
  const [systolicPressure, setSystolicPressure] = useState("");
  const [evaScale, setEvaScale] = useState("");
  const [cardiacFrequency, setCardiacFrequency] = useState("");
  const [weight, setWeight] = useState("");
  const [allergies, setAllergies] = useState("");
  const [antecedents, setAntecedents] = useState("");
  const [history, setHistory] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  // const [medicines, setMedicines] = useState("");
  const [interconsult, setInterconsult] = useState("");
  const [treatment, setTreatment] = useState("");
  //   const [observations, setObservations] = useState("");
  const [indications, setIndications] = useState("");
  const [nameDoctor, setNameDoctor] = useState("");
  const [open, setOpen] = useState(false);
  const [openViewPdf, setOpenViewPdf] = useState(false);
  const [type, setType] = useState("");
  const [valor, setValor] = useState();
  const [triage, setTriage] = useState("");
  const [arr, setArr] = useState([]);
  const [dataDoctor, setDataDoctor] = useState({});
  const [openDialogPdf, setOpenDialogPdf] = useState(false);
  const [typePdf, setTypePdf] = useState("");

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams(window.location.search);
      const datosCodificados = params.get("data");
      const datosDecodificados = JSON.parse(
        decodeURIComponent(datosCodificados)
      );
      datosDecodificados.client.location.fullAddress =
        datosDecodificados.client.location.comuna +
        ", " +
        datosDecodificados.client.location.address;
      setSymptoms(datosDecodificados.request.details);
      setValor(datosDecodificados);
      const dataFetched = await getDoctorById(datosDecodificados.idDoctor);
      setDataDoctor(dataFetched);
    }
    fetchData();
  }, []);

  const dataDoctor2 = useSelector((state) => state.auth.userData);

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const closeDialogPdf = () => {
    setOpenDialogPdf(false);
  };

  const openViewPdfMethod = () => {
    setOpenViewPdf(true);
  };

  const closeViewPdf = () => {
    setOpenViewPdf(false);
  };

  function generarCodigoAleatorio(longitud) {
    let codigo = "";
    const caracteres = "0123456789";

    for (let i = 0; i < longitud; i++) {
      const indice = Math.floor(Math.random() * caracteres.length);
      codigo += caracteres.charAt(indice);
    }

    return codigo;
  }

  const deleteExam = (index) => {
    // Crea una copia del array usando spread operator o slice
    const updatedExams = [...arr];

    // Elimina el examen en el índice proporcionado
    updatedExams.splice(index, 1);

    // Actualiza el estado con el nuevo array
    setArr(updatedExams);
  };

  const createNewChat = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    const resChat = await createChat(valor.idDoctor, valor.idClient, valor.id);
    if (resChat) {
      Swal.close();
      return true;
    } else {
      Swal.close();
      return false;
    }
  };

  const handleOpenChat = async () => {
    // Creamos una nueva Promise para manejar la lógica asincrónica
    return new Promise(async (resolve, reject) => {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      try {
        const resChat = await getChatId(valor.idDoctor, valor.idClient);

        if (resChat.length === 0) {
          const response = await Swal.fire({
            icon: "warning",
            title: "Crear chat",
            text: "No tiene chat con este paciente. ¿Desea crearlo?",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Aceptar",
            cancelButtonColor: "#d33",
            showCancelButton: true,
          });

          if (response.isConfirmed) {
            let a = await createNewChat();
            if (a) {
              resolve(true); // Resolvemos la Promise con true si el chat se creó correctamente
            } else {
              reject(false); // Rechazamos la Promise con false si hubo un error al crear el chat
            }
          }
        } else {
          if (resChat?.status === "inactive") {
            console.log("chat inactivo");
            const response = await Swal.fire({
              icon: "info",
              title: "Chat inactivo",
              text: "El chat con este paciente está inactivo. ¿Desea activarlo?",
              cancelButtonText: "Cancelar",
              confirmButtonText: "Aceptar",
              cancelButtonColor: "#d33",
              showCancelButton: true,
            });

            if (response.isConfirmed) {
              let a = await activateChat(resChat.id, valor.id);
              if (a) {
                resolve(true); // Resolvemos la Promise con true si el chat se activó correctamente
              } else {
                reject(false); // Rechazamos la Promise con false si hubo un error al activar el chat
              }
            }
          } else {
            resolve(true); // Resolvemos la Promise con true si el chat ya existe y está activo
          }
        }
      } catch (err) {
        console.log(err);
        reject(false); // Rechazamos la Promise con false si hubo un error en la ejecución de la función
      }
    });
  };

  const handleEnd = async () => {
    if (history === "") {
      Swal.fire({
        icon: "warning",
        title: "Error historia",
        text: "Debe ingresar historia",
      });
    } else if (diagnosis === "") {
      Swal.fire({
        icon: "warning",
        title: "Error diagnóstico",
        text: "Debe ingresar un diagnóstico",
      });
    } else if (treatment === "") {
      Swal.fire({
        icon: "warning",
        title: "Error tratamiento",
        text: "Debe ingresar un tratamiento",
      });
    } else if (indications === "") {
      Swal.fire({
        icon: "warning",
        title: "Error indicaciones",
        text: "Debe ingresar indicaciones",
      });
    } else {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      const code = generarCodigoAleatorio(10);
      const data = {
        idDoctor: valor.idDoctor,
        idRequest: valor.id,
        idUser: valor.idClient,
        nameDoctor: capitalizeAll(
          `${valor.doctor.name} ${valor.doctor.lastName}`
        ),
        numberRecord: code,
        symptoms: symptoms,
        temperature: armpitTemp,
        diastolicPressure: diastolicPressure,
        respiratoryFrequency: respiratoryFrequency,
        hemoGlucose: hemoGlucose,
        pulse: pulse,
        oxygenSaturation: oxygenSaturation,
        glasgow: glasgow,
        systolicPressure: systolicPressure,
        evaScale: evaScale,
        cardiacFrequency: cardiacFrequency,
        weight: weight,
        allergies: allergies,
        // antecedents: antecedents,
        history: history,
        diagnosis: diagnosis,
        interconsult: interconsult,
        treatment: treatment,
        indications: indications,
        exams: arr,
        triage: triage,
      };
      let dataToreq = {
        idDoctor: valor.idDoctor,
        idClient: valor.idClient,
        doctor: valor.doctor,
      };
      let res;
      if (valor.idClient === "forPage") {
        data.type = "forPage";
        data.client = valor.client;
        res = await createMedicalRecord(data);
      } else {
        data.type = "forApp";
        res = await createMedicalRecord(data);
      }
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Documento guardado",
          text: "Se ha guardado correctamente",
        }).then(() => {
          if (valor.idClient === "forPage") {
            navigation("/calendar");
          } else {
            Swal.fire({
              icon: "question",
              title: "¿Desea abrir un chat con el paciente?",
              showCancelButton: true,
              confirmButtonText: `Sí, abrir chat`,
              cancelButtonText: `No`,
            }).then(async (result) => {
              if (result.isConfirmed) {
                let resOpenChat = await handleOpenChat();
                if (resOpenChat) {
                  if (data.exams.length !== 0) {
                    const a = await updateRequestStatus(
                      valor.id,
                      "waiting",
                      valor
                    );
                    if (a) {
                      Swal.fire({
                        icon: "success",
                        title: "Atención finalizada",
                        text: 'La solicitud ha pasado a estado "En espera de exámenes"',
                      }).then(() => {
                        navigation("/calendar");
                      });
                    } else {
                      Swal.fire({
                        icon: "warning",
                        title: "ops...",
                        text: "No se ha podido finalizar la atención",
                      });
                    }
                  } else {
                    const completeReq = await completeRequest(
                      valor.id,
                      dataToreq
                    );
                    if (completeReq) {
                      Swal.fire({
                        icon: "success",
                        title: "Atención finalizada",
                        text: "la atención ha sido finalizada correctamente",
                      }).then(() => {
                        navigation("/calendar");
                      });
                    } else {
                      Swal.close();
                      Swal.fire({
                        icon: "warning",
                        title: "ops...",
                        text: "No se ha podido finalizar la atención",
                      });
                    }
                  }
                } else {
                  Swal.close();
                  Swal.fire({
                    icon: "warning",
                    title: "ops...",
                    text: "No se ha podido guardar",
                  });
                }
              } else if (result.isDismissed || result.isDenied) {
                if (data.exams.length !== 0) {
                  const a = await updateRequestStatus(
                    valor.id,
                    "waiting",
                    valor
                  );
                  if (a) {
                    Swal.fire({
                      icon: "success",
                      title: "Atención finalizada",
                      text: 'La solicitud ha pasado a estado "En espera de exámenes"',
                    }).then(() => {
                      navigation("/calendar");
                    });
                  } else {
                    Swal.fire({
                      icon: "warning",
                      title: "ops...",
                      text: "No se ha podido finalizar la atención",
                    });
                  }
                } else {
                  const completeReq = await completeRequest(
                    valor.id,
                    dataToreq
                  );
                  if (completeReq) {
                    Swal.fire({
                      icon: "success",
                      title: "Atención finalizada",
                      text: "la atención ha sido finalizada correctamente",
                    }).then(() => {
                      navigation("/calendar");
                    });
                  } else {
                    Swal.close();
                    Swal.fire({
                      icon: "warning",
                      title: "ops...",
                      text: "No se ha podido finalizar la atención",
                    });
                  }
                }
              }
            });
          }
        });
      } else {
        Swal.close();
        Swal.fire({
          icon: "warning",
          title: "ops...",
          text: "No se ha podido guardar",
        });
      }
    }
  };

  function calculateAge(birthdate) {
    const partesFecha = birthdate.split("/");
    const birthdateObj = new Date(
      partesFecha[2],
      partesFecha[1] - 1,
      partesFecha[0]
    );
    const tiempoDiferencia = Date.now() - birthdateObj.getTime();
    const edad = Math.floor(tiempoDiferencia / (365.25 * 24 * 60 * 60 * 1000));
    return edad;
  }
  
  async function GenereateMedicalRecord() {
  }

  async function handlePdfFichaMedica() {
    try {
      let exams = arr.map((item) => item.exam).join(", ");
      await generatePDFFichaMedica(
        valor.client,
        valor.doctor,
        valor.request,
        {
          enfermera: capitalizeAll(
            valor.doctor.name + " " + valor.doctor.lastName
          ),
          horaClasification: valor.request.hour,
          triage: triage,
          motivoConsulta: symptoms,
          //DATO DE CONSULTA
          medicoTurno: valor.doctor.name + " " + valor.doctor.lastName,
          horaAtencion: valor.request.hour,
          especialidad: valor.doctor.mainSpecialty,
          //DATOS DE TABLA
          alergias: allergies,
          medicamentos: "Ninguno",
          historia: history,
          examenes: exams,
          interconsulta: interconsult,
          diagnostico: diagnosis,
          tratamiento: treatment,
          evolution: "Ninguna",
          indications: indications,
          //DATOS SIGNOS
          horaSignos: valor.request.hour,
          tomadoPor: "Enfermera",
          //DATOS TABLA SIGNOS
          temperature: armpitTemp,
          presion: `${systolicPressure}/${diastolicPressure}`,
          pulso: pulse,
          eva: evaScale,
          respiratoria: respiratoryFrequency,
          saturacion: oxygenSaturation,
          cardiaca: cardiacFrequency,
          hemoglucosa: hemoGlucose,
          glassgow: glasgow,
          peso: weight,
        },
        dataDoctor.logo
      );
    } catch (error) {
      console.error("Error al obtener el PDF:", error);
    }
  }

  async function handlePdfTreatment() {
    if (indications.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Error",
        text: "Debe ingresar indicaciones de tratamiento",
      });
    } else {
      try {
        Swal.fire({
          imageUrl: loadingSVG,
          imageWidth: 300,
          imageHeight: 300,
          title: "Cargando...",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        let exams = arr.map((item) => item.exam).join(", ");
        const res = await generatePDFIndications(
          valor.client,
          valor.doctor,
          valor.request,
          indications,
          "Mi sangre",
          treatment,
          dataDoctor.logo
        );

        if (res == false) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Ocurrió un error al obtener el PDF",
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Documento creado",
            text: "Se ha creado correctamente",
          });
        }
      } catch (error) {
        console.error("Error al obtener el PDF:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error al obtener el PDF",
        });
      }
    }
  }

  async function handlePdfReceta() {
    try {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const logo = dataDoctor.logo;
      const res = await generatePDFReceta(
        valor.client,
        valor.doctor,
        valor.request,
        treatment,
        logo
      );
      if (res == false) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error al obtener el PDF",
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Documento creado",
          text: "Se ha creado correctamente",
        });
      }
    } catch (error) {
      console.error("Error al obtener el PDF:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error al obtener el PDF",
      });
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 0,
        height: "100%",
        position: "relative",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          scrollbarWidth: "thin",
          height: "100%",
        }}
      >
        {valor && (
          <>
            <CardHeader
              title={`Atención de ${capitalize(valor.client.name)} ${capitalize(
                valor.client.lastName
              )}`}
              sx={{
                borderBottom: "1px solid rgba(0,0,0,0.12)",
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "#FFFFFF",
              }}
              action={
                <div>
                  <div style={{ zIndex: 1000 }} className="dropdown-container">
                    <Button
                      className="dropdown-button"
                      variant="outlined"
                      sx={{
                        color: "#006686",
                        ":hover": {
                          backgroundColor: "#006686",
                          color: "#77F8E0",
                        },
                        fontSize: "0.8rem",
                        borderRadius: "25px",
                        width: "170px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Print sx={{ fontSize: "17px" }} />
                      Seleccionar
                      <ArrowDropDownIcon
                        sx={{ fontSize: "20px", color: "black" }}
                      />
                    </Button>
                    <div className="dropdown-content">
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handlePdfFichaMedica()}
                      >
                        Ficha médica
                      </p>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setTypePdf("certificado");
                          setOpenDialogPdf(true);
                        }}
                      >
                        Certificado médico
                      </p>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handlePdfReceta()}
                      >
                        Receta médica
                      </p>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handlePdfTreatment()}
                      >
                        Indicaciones de tratamiento
                      </p>
                    </div>
                  </div>
                </div>
              }
            />

            <CardContent
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                overflowY: "auto",
                scrollbarWidth: "thin",
                flexGrow: 1,
              }}
            >
              <Box sx={{ display: "flex", py: 2 }}>
                <Typography variant="titleSmall">Datos del paciente</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">Nombre: </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">
                    {capitalize(
                      valor.request.familiarData.name || valor.client.name
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">Apellido: </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">
                    {capitalize(
                      valor.request.familiarData.lastName ||
                        valor.client.lastName
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">Rut: </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">
                    {valor.request.familiarData.rut || valor.client.rut}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">Domicilio: </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">
                    {capitalize(
                      valor.request.familiarData.address ||
                        valor.client.location.address
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">Fecha de nacimiento: </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">
                    {valor.request.familiarData.birthday ||
                      valor.client.birthday}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">Edad: </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">
                    {calculateAge(
                      valor.request.familiarData.birthday ||
                        valor.client.birthday
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">Género: </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">{valor.client.genre}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">Previsión: </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant="body">
                    {capitalize(valor.request.payData.typePay)}
                  </Typography>
                </Grid>
              </Grid>

              <Box sx={{ display: "flex", py: 2, mt: 2 }}>
                <Typography variant="titleSmall">Triage</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    disabled
                    placeholder="Enfermera(o)"
                    value={capitalizeAll(
                      `${valor.doctor.name} ${valor.doctor.lastName}`
                    )}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlinedIcon sx={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    disabled
                    placeholder="Hora"
                    value={valor.request.hour}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeOutlinedIcon sx={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="Triage"
                    onChange={(e) => setTriage(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlinedIcon sx={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="Motivo de consulta"
                    onChange={(e) => setSymptoms(e.target.value)}
                    value={symptoms}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EditOutlinedIcon sx={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Box sx={{ display: "flex", py: 2, mt: 2 }}>
                <Typography variant="titleSmall">Signos vitales</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="Temperatura"
                    onChange={(e) => setArmpitTemp(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DeviceThermostatOutlinedIcon
                            sx={{ color: "black" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="Presión diastólica"
                    onChange={(e) => setDiastolicPressure(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={BloodPressure}
                            alt="blood pressure"
                            width={30}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="F. Respiratoria"
                    onChange={(e) => setRespiratoryFrequency(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Lungs} alt="lungs" width={30} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="Hemo glucosa"
                    onChange={(e) => setHemoGlucose(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Diabetes} alt="diabetes" width={30} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="Presión sistólica"
                    onChange={(e) => setSystolicPressure(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Pressure} alt="pressure" width={30} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="Escala Eva"
                    onChange={(e) => setEvaScale(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlinedIcon sx={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="F. Cardíaca"
                    onChange={(e) => setCardiacFrequency(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={HeartCardiogram}
                            alt="heart cardiogram"
                            width={30}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="Peso"
                    onChange={(e) => setWeight(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Weight} alt="weight" width={30} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="Pulso"
                    onChange={(e) => setPulse(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Cardiogram} alt="cardiogram" width={30} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="Saturación de oxígeno"
                    onChange={(e) => setOxygenSaturation(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Oxigen} alt="oxigen" width={30} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="Escala Glasgow"
                    onChange={(e) => setGlasgow(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlinedIcon sx={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Box sx={{ display: "flex", py: 2, mt: 2 }}>
                <Typography variant="titleSmall">Médico</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="Alergias"
                    onChange={(e) => setAllergies(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Allergies} alt="allergies" width={30} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    placeholder="Interconsulta"
                    onChange={(e) => setInterconsult(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Referal} alt="referal" width={30} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-area"
                    fullWidth
                    variant="filled"
                    placeholder="Historia"
                    onChange={(e) => setHistory(e.target.value)}
                    multiline
                    rows={5}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={FClinical} alt="f clinical" width={30} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-area"
                    fullWidth
                    variant="filled"
                    placeholder="Diagnóstico"
                    onChange={(e) => setDiagnosis(e.target.value)}
                    multiline
                    rows={5}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={Spreadsheets}
                            alt="spread sheets"
                            width={30}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-area"
                    fullWidth
                    variant="filled"
                    placeholder="Indicaciones de tratamiento"
                    onChange={(e) => setIndications(e.target.value)}
                    multiline
                    rows={5}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Chart} alt="chart" width={30} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-area"
                    fullWidth
                    variant="filled"
                    placeholder="Tratamiento y receta médica"
                    onChange={(e) => setTreatment(e.target.value)}
                    multiline
                    rows={5}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={RegisterBook}
                            alt="register book"
                            width={30}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#DEE3EB",
                      padding: "12px",
                      borderRadius: "8px",
                      gap: 4,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={ExamQualification}
                          alt="qualification"
                          width={30}
                        />
                        <Typography
                          style={{ marginLeft: "8px", color: "#006686" }}
                        >
                          Exámenes
                        </Typography>
                      </div>
                      <IconButton onClick={() => openDialog()}>
                        <AddBoxOutlinedIcon />
                      </IconButton>
                    </div>
                    {arr &&
                      arr.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "#FFFFFF",
                            padding: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <Typography>
                            - Examen N° {index + 1}: {item.exam}
                          </Typography>
                          <IconButton onClick={() => deleteExam(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            </CardContent>

            <Box
              sx={{
                zIndex: "200",
                width: "100%",
                backgroundColor: "white",
                position: "sticky",
                bottom: 0,
              }}
            >
              <Divider />
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  py: 2,
                  px: 2,
                  width: "100%",
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    backgroundColor: "transparent",
                    color: "#006686",
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "100px",
                    background: "#006686",
                    color: "#77F8E0",
                    "&:hover": {
                      backgroundColor: "#006686",
                      color: "white",
                    },
                  }}
                  onClick={handleEnd}
                >
                  Concluir
                </Button>
              </CardActions>
            </Box>
          </>
        )}
      </Paper>
      <ExamRequest
        open={open}
        close={closeDialog}
        valor={valor}
        setArr={setArr}
        arr={arr}
      />
      {openDialogPdf && (
        <DialogPDFs
          open={openDialogPdf}
          handleClose={closeDialogPdf}
          type={typePdf}
          valor={valor}
          dataDoctor={dataDoctor}
        />
      )}
    </Box>
  );
}
