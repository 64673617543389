import {
  Container,
  Divider,
  TextField,
  Button,
  Box,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
  Grid,
  CardActions,
} from "@mui/material";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDoctorById, updateDoctor } from "../database/doctors";
import { ImageOutlined } from "@mui/icons-material";
import loadingSVG from "../assets/logo_Loading.gif";
import Swal from "sweetalert2";
import { location } from "../utils/locations";
import ChangeImageCrop from "../components/change_image_crop";
import { getClinicById } from "../database/clinic";

export default function ProfilePersonalData() {
  const myId = useSelector((state) => state.auth.idUser);
  const role = useSelector((state) => state.auth.userData.role);
  const myData = useSelector((state) => state.auth.userData);
  const [type, setType] = useState("");
  const [docData, setDocData] = useState({});
  const [docName, setDocName] = useState("");
  const [docLastName, setDocLastName] = useState("");
  const [address, setAddress] = useState("");
  const [docEmail, setDocEmail] = useState("");
  const [docDescription, setDocDescription] = useState("");
  const [editPresonal, setEditPersonal] = useState();
  const [region, setRegion] = useState("");
  const [comuna, setComuna] = useState("");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [imageCrop, setImageCrop] = useState(null);

  useEffect(() => {
    async function getData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      try {
        let res;
        if (role === "doctor") {
          res = await getDoctorById(myId);
        } else if (role === "medicalCenter") {
          // console.log("myData", myData);
          let a = await getClinicById(myData.id);
          res = a[0];
        } else {
          res = getDoctorById(myData.doctorId);
        }
        setDocData(res);
        // console.log("resresres", res);
        setDocName(res.name);
        setDocLastName(res.lastName);
        setAddress(res.location.address);
        setRegion(res.location.region);
        setComuna(res.location.comuna);
        setDocEmail(res.email);
        setDocDescription(res.docDescription);
      } catch (error) {
        console.error("Error al obtener datos del médico:", error);
      }
      Swal.close();
    }
    getData();
  }, [myId]);

  const handleInputChange = (event, setState) => {
    const value = event.target.value
      .toLowerCase()
      .normalize("NFD")
      .replace(
        /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
        "$1"
      );
    setState(value);
  };

  const updatePersonalData = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    const countEmpty = [];
    const countUndefined = [];
    const data = {
      location: {
        address: address,
        region: region,
        comuna: comuna,
      },
    };

    // console.log("data", data);
    if (docName !== undefined) {
      if (docName !== "") {
        data.name = docName;
      } else {
        countEmpty.push("Nombre");
      }
    } else {
      countUndefined.push("1");
    }

    if (docLastName !== undefined) {
      if (docLastName !== "") {
        data.lastName = docLastName;
      } else {
        countEmpty.push("Apellido");
      }
    } else {
      countUndefined.push("2");
    }

    if (docDescription !== undefined) {
      if (docDescription !== "") {
        data.docDescription = docDescription;
      } else {
        countEmpty.push("Descripción");
      }
    } else {
      countUndefined.push("3");
    }

    if (countUndefined.length === 2) {
      Swal.fire({
        icon: "info",
        title: "No se han realizado cambios",
        // text:"No se ralizo ningun cambio en los datos personales"
      });
    } else if (countEmpty.length > 0) {
      Swal.fire({
        icon: "info",
        title: "Campos vacios",
        text: "Porfavor complete los campos : " + countEmpty.join(", "),
      });
    } else {
      // console.log("Guardandaodnasjdbksdjhf");
      // console.log(data, idDoctor);
      const update = await updateDoctor(data, myId);
      // console.log(update);
      if (update) {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Actualizado",
          text: "Se han actualizado los datos correctamente",
        });
        setEditPersonal(true);
      } else {
        Swal.close();

        Swal.fire({
          icon: "error",
          title: "Upss..",
          text: "Se ha producido un error al momento de actualizar los datos",
        });
      }
    }
  };

  return (
    <Box sx={{}}>
      <Grid
        container
        spacing={2}
        className="animate__animated animate__backInRight animate__faster"
        overflowy="scroll"
        sx={{
          p: 2,
          pb: 4,
        }}
      >
        <Grid item xs={12} sm={6}>
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {docData?.imageProfile ? (
              <>
                <img
                  src={docData?.imageProfile}
                  alt="Foto de perfil"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                    borderRadius: "10%",
                  }}
                />
              </>
            ) : (
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "rgba(220, 227, 233, 1)",
                  borderRadius: "10%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>Sin Imagen</Typography>
              </div>
            )}

            <Button
              variant="outlined"
              onClick={() => {
                setType("imageProfile");
                setOpen(true);
              }}
              sx={{
                textTransform: "none",

                marginRight: "10px",
                color: "rgba(0, 0, 0, 0.87)",
                borderColor: "rgba(0, 0, 0, 0.12)",
              }}
              startIcon={
                <ImageOutlined
                  sx={{
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                />
              }
            >
              {docData?.imageProfile ? "Cambiar Imagen" : "Agregar Imagen"}
            </Button>
          </Container>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {docData?.logo ? (
              <>
                <img
                  src={docData?.logo}
                  alt="Logo"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                    borderRadius: "10%",
                  }}
                />
              </>
            ) : (
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "rgba(220, 227, 233, 1)",
                  borderRadius: "10%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>Sin logo</Typography>
              </div>
            )}
            <Button
              variant="outlined"
              onClick={() => {
                setType("logo");
                setOpen(true);
              }}
              sx={{
                textTransform: "none",

                marginRight: "10px",
                color: "rgba(0, 0, 0, 0.87)",
                borderColor: "rgba(0, 0, 0, 0.12)",
              }}
              startIcon={
                <ImageOutlined
                  sx={{
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                />
              }
            >
              {docData?.logo ? "Cambiar logo" : "Agregar logo"}
            </Button>
          </Container>
        </Grid>

        <Grid item xs={12} sm={6}>
          {docData?.name && (
            <TextField
              placeholder="Nombre"
              variant="filled"
              id="name"
              label="Nombre"
              name="name"
              fullWidth
              defaultValue={docName}
              onChange={(event) => handleInputChange(event, setDocName)}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {docData?.lastName && (
            <TextField
              placeholder="Apellidos"
              variant="filled"
              id="lastname"
              label="Apellidos"
              name="lastname"
              fullWidth
              defaultValue={docLastName}
              onChange={(event) => handleInputChange(event, setDocLastName)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {docData?.email && (
            <TextField
              placeholder="Correo"
              variant="filled"
              id="email"
              label="Correo Electrónico"
              name="email"
              fullWidth
              disabled
              defaultValue={docEmail}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {docData?.location?.address && (
            <TextField
              required
              placeholder="Dirección"
              defaultValue={docData?.location?.address}
              type="text"
              onChange={(e) => setAddress(e.target.value)}
              fullWidth
              variant="filled"
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            variant="filled"
            fullWidth
            startAdornment={
              <InputAdornment position="start" sx={{ color: "black" }}>
                <MapOutlinedIcon />
              </InputAdornment>
            }
            defaultValue={0}
            value={region}
            onChange={(e) => {
              setRegion(e.target.value);
              setComuna("0");
            }}
          >
            <MenuItem value={0}>Seleccione región</MenuItem>
            {location.map((e, i) => (
              <MenuItem value={e.region} key={i}>
                {e.region}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <Select
            required
            variant="filled"
            fullWidth
            startAdornment={
              <InputAdornment position="start" sx={{ color: "black" }}>
                <ExploreOutlinedIcon />
              </InputAdornment>
            }
            value={comuna}
            defaultValue={"0"}
            onChange={(e) => {
              setComuna(e.target.value);
            }}
          >
            <MenuItem value={"0"}>Seleccione su comuna</MenuItem>
            {location.map((e, i) => {
              if (e.region === region) {
                return e.comunas.map((comuna, i) => (
                  <MenuItem value={comuna} key={i}>
                    {comuna}
                  </MenuItem>
                ));
              }
            })}
          </Select>
        </Grid>

        <Grid item xs={12}>
          {docData?.docDescription && (
            <TextField
              className="text-area"
              placeholder="Descripción"
              variant="filled"
              id="description"
              label="Descripción"
              rows={5}
              fullWidth
              multiline
              name="description"
              defaultValue={docDescription}
              onChange={(event) => handleInputChange(event, setDocDescription)}
            />
          )}
        </Grid>
        <ChangeImageCrop
          type={type}
          open={open}
          close={setOpen}
          data={docData}
          setImageCrop={setImageCrop}
        />
      </Grid>

      <Box
        sx={{
          zIndex: "200",
          width: "100%",
          backgroundColor: "white",
          position: "sticky",
          bottom: { xs: "0px", sm: "0px", md: "60px" },
        }}
      >
        <Divider />
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            py: 3,
            px: 2,
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            disableElevation
            onClick={updatePersonalData}
            sx={{}}
          >
            Guardar
          </Button>
        </CardActions>
      </Box>
    </Box>
  );
}
