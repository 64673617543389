import React, { createContext, useContext, useState, useEffect } from "react";
import { getSales } from "../database/sale";
import { useSelector } from "react-redux";

const UpdateSales = createContext();

export const UpdateSalesProvider = ({ children }) => {
  const [salesData, setSalesData] = useState([]);
  const [myId, setMyId] = useState("");
  const dalesData = useSelector((state) => state.auth.userData);
  const currentPath = window.location.pathname;

  const updateSalesAsync = async () => {
    if (myId !== "") {
      const res = await getSales(myId);
      setSalesData(res);

      // Obtener la ruta actual

      // Hacer algo con la ruta actual
      // console.log("Ruta actual:", currentPath);
    }
  };
  useEffect(() => {
    let idToUpdate = "";
    if (dalesData?.role === "secretary") {
      idToUpdate = dalesData?.idDoctor;
    } else if (dalesData?.role === "doctor") {
      idToUpdate = dalesData?.id;
    }
    setMyId(idToUpdate);
  }, [dalesData]);

  useEffect(() => {
    updateSalesAsync();
  }, [myId]);

  return (
    <UpdateSales.Provider value={{ salesData, updateSalesAsync }}>
      {children}
    </UpdateSales.Provider>
  );
};

export const useUpdateSales = () => useContext(UpdateSales);
