import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  Grid,
  // Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Uploader from "./image_uploader_component";
import Loading from "./loading_component";
import { getDoctorById, getIsapres } from "../database/doctors";
import { getHoursFree } from "../database/schedules";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import langEs from "date-fns/locale/es";
import { createNewRequest } from "../database/request";
import { location } from "../utils/locations";
import Select from "react-select";
import { createUser, getUserByEmail, getUserByRut } from "../database/users";
import { getClinicById } from "../database/clinic";
import { useNavigate } from "react-router-dom";

export default function DialogNewReserve({
  open,
  close,
  selectedDate,
  myId,
  // loadData,
}) {
  //User data client
  const navigate = useNavigate();
  const [rut, setRut] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [genre, setGenre] = useState("");
  const [birthDate, setBirthDate] = useState();
  const [region, setRegion] = useState("");
  const [selectedComune, setSelectedComune] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [payment, setPayment] = useState("");
  const [selectedIsapre, setSelectedIsapre] = useState("");

  const [userCreated, setUserCreated] = useState(false);
  const [userCreatedData, setUserCreatedData] = useState({});

  //Request data
  const [type, setType] = useState("");
  const [details, setDetails] = useState("");
  const [hourSelected, setHourSelected] = useState("");
  const [hoursArray, setHoursArray] = useState([]);

  //Data doctor

  const [loading, setLoading] = useState(false);
  const [isapres, setIsapres] = useState([]);
  const [typesReserve, setTypesReserve] = useState([]);
  const [previsions, setPrevisions] = useState({
    fonasa: false,
    isapre: false,
    particular: false,
  });

  const [dataDoctorUpdated, setDataDoctorUpdated] = useState({});

  function formatRut(rut) {
    // Eliminar caracteres que no sean dígitos ni K
    rut = rut.replace(/[^\dkK]/g, "");

    // Formatear rut con puntos y guión
    return rut.replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
  }

  useEffect(() => {
    async function loadData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const dataDoctorRes = await getDoctorById(myId);
      setDataDoctorUpdated(dataDoctorRes);
      let isapresArray = [];
      for (const isapre in dataDoctorRes.selectedIsapre) {
        isapresArray.push({ name: dataDoctorRes.selectedIsapre[isapre].value });
      }
      setIsapres(isapresArray);
      let optionsTypeReserve = [];
      let optionsPays = [];

      if (dataDoctorRes.medicalCenterId) {
        const a = await getClinicById(dataDoctorRes.medicalCenterId);
        // console.log("dataDoctorRes", dataDoctorRes);

        let isapresArray = [];
        for (const isapre in a[0].selectedIsapre) {
          isapresArray.push({ name: a[0].selectedIsapre[isapre].value });
        }
        setIsapres(isapresArray);
        if (a[0].queryTypes.domicilio) {
          optionsTypeReserve.push({
            value: "A domicilio",
            label: "A domicilio",
          });
        }
        if (a[0].queryTypes.presencial) {
          optionsTypeReserve.push({ value: "Presencial", label: "Presencial" });
        }
        if (a[0].queryTypes.videollamada) {
          optionsTypeReserve.push({
            value: "Videollamada",
            label: "Videollamada",
          });
        }
        if (a[0].previsions.fonasa) {
          optionsPays.push({ value: "Fonasa", label: "Fonasa" });
        }
        if (a[0].previsions.isapre) {
          optionsPays.push({ value: "Isapre", label: "Isapre" });
        }
        if (a[0].previsions.particular) {
          optionsPays.push({ value: "Particular", label: "Particular" });
        }
      } else {
        if (dataDoctorRes.queryTypes.domicilio) {
          optionsTypeReserve.push({
            value: "A domicilio",
            label: "A domicilio",
          });
        }
        if (dataDoctorRes.queryTypes.presencial) {
          optionsTypeReserve.push({ value: "Presencial", label: "Presencial" });
        }
        if (dataDoctorRes.queryTypes.videollamada) {
          optionsTypeReserve.push({
            value: "Videollamada",
            label: "Videollamada",
          });
        }
        if (dataDoctorRes.previsions.fonasa) {
          optionsPays.push({ value: "Fonasa", label: "Fonasa" });
        }
        if (dataDoctorRes.previsions.isapre) {
          optionsPays.push({ value: "Isapre", label: "Isapre" });
        }
        if (dataDoctorRes.previsions.particular) {
          optionsPays.push({ value: "Particular", label: "Particular" });
        }
      }

      setPrevisions(optionsPays);
      setTypesReserve(optionsTypeReserve);
      const hoursFree = await getHoursFree(selectedDate, myId);
      if (hoursFree.length > 0) {
        Swal.close();
        const currentTime = new Date();
        const currentHour = currentTime.getHours();
        const currentMinute = currentTime.getMinutes();
        const currentFormattedTime = `${currentHour}:${
          currentMinute < 10 ? "0" + currentMinute : currentMinute
        }`;

        let hoursLocalArray = [];

        hoursFree.filter((hour) => {
          let hora1 = parseInt(hour.time.replace(":", ""));
          let hora2 = parseInt(currentFormattedTime.replace(":", ""));
          if (new Date(selectedDate) < currentTime) {
            hoursLocalArray.push(hour);
          } else {
            if (hora1 < hora2) {
            } else {
              hoursLocalArray.push(hour);
            }
          }
        });

        if (hoursLocalArray.length === 0) {
          Swal.fire({
            title: "Horas ya reservadas",
            text: "Todas las horas de este día están ocupadas. Si se agregan más horas al horario del doctor podrás reservarlas.",
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Entiendo",
          }).then((result) => {
            Swal.close();
            close();
          });
        } else {
          setHoursArray(hoursLocalArray);
        }
      } else {
        Swal.fire({
          title: "Horas de atención no disponibles",
          text: "Todas las horas de este día están ocupadas. Si se agregan más horas al horario del doctor podrás reservarlas.",
          icon: "error",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Entiendo",
          cancelButtonText: "Ir a horarios",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.close();
            close();
          } else {
            Swal.close();
            navigate("/settings");
            close();
          }
        });
      }
    }
    loadData();
  }, []);

  const handlePayment = (element) => {
    setPayment(element.target.value);
  };

  const handleTypeReserve = (element) => {
    setType(element.value);
  };

  const handleHours = (element) => {
    setHourSelected(element.value);
  };

  const chekSchedules = async (selectedTime) => {
    const hoursFree = await getHoursFree(selectedDate, myId);

    if (hoursFree.length > 0) {
      const selectedTimeFormatted = selectedTime.replace(":", "");
      const isAvailable = hoursFree.some(
        (hour) => hour.time.replace(":", "") === selectedTimeFormatted
      );

      return isAvailable;
    } else {
      return false;
    }
  };

  const handleEnd = async () => {
    let data = {
      myId,
      selectedDate,
      hourSelected,
      type,
      payment,
      details,
      email,
      name,
      lastName,
      birthDate,
      genre,
      phone,
      rut,
      region,
      address,
      selectedComune,
      selectedIsapre,
      myId,
    };

    const countEmpty = [];

    if (rut === "") {
      countEmpty.push("Rut");
    }
    if (name === "") {
      countEmpty.push("Nombre");
    }
    if (lastName === "") {
      countEmpty.push("Apellido");
    }
    if (genre === "") {
      countEmpty.push("Género");
    }
    if (birthDate === "") {
      countEmpty.push("Fecha de nacimiento");
    }
    if (region === "") {
      countEmpty.push("Región");
    }
    if (selectedComune === "") {
      countEmpty.push("Comuna");
    }
    if (address === "") {
      countEmpty.push("Dirección");
    }
    if (phone === "") {
      countEmpty.push("Teléfono");
    }
    if (email === "") {
      countEmpty.push("Correo");
    }
    if (payment === "") {
      countEmpty.push("Forma de pago");
    }
    if (payment === "Isapre" && selectedIsapre === "") {
      countEmpty.push("Isapre");
    }
    if (details === "") {
      countEmpty.push("Detalles");
    }
    if (hourSelected === "") {
      countEmpty.push("Hora de la consulta");
    }
    if (type === "") {
      countEmpty.push("Tipo de reserva");
    }

    if (countEmpty.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Los siguientes campos están vacíos: ${countEmpty.join(", ")}`,
      });
    } else {
      try {
        setLoading(true);
        if (userCreated === false) {
          const responseEmail = await getUserByEmail(email.toLocaleLowerCase());
          if (responseEmail.length > 0) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "El correo ingresado ya está en uso.",
            });
            setLoading(false);
          } else {
            const resIdUser = await createUser({
              email: email,
              password: rut.replace(/\./g, "").replace(/\-/g, ""),
              name: name,
              lastName: lastName,
              birthday: birthDate,
              genre: genre,
              phone: phone,
              location: {
                region: region,
                comuna: selectedComune,
                address: address,
              },
              imageProfile: "default",
              family: [],
              rut: rut,
              firstTime: true,
              payment: payment,
              selectedIsapre: selectedIsapre,
            });
            data.resIdUser = resIdUser;

            const chekHour = await chekSchedules(hourSelected);
            if (!chekHour) {
              close();

              Swal.fire({
                icon: "warning",
                title: "Hora no disponible",
                text: "La hora seleccionada ya no está disponible. Por favor, selecciona otra hora.",
                showConfirmButton: true,
              });
            } else {
              const responseCreate = await createNewRequest(data);
              if (responseCreate) {
                close();
                Swal.fire({
                  icon: "success",
                  title: "Reserva creada",
                  text: "Reserva creada con éxito",
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Ha ocurrido un error al crear la reserva. Por favor, inténtalo nuevamente.",
                });
                setLoading(false);
              }
            }
          }
        } else {
          const chekHour = await chekSchedules(hourSelected);
          if (!chekHour) {
            close();

            Swal.fire({
              icon: "warning",
              title: "Hora no disponible",
              text: "La hora seleccionada ya no está disponible. Por favor, selecciona otra hora.",
              showConfirmButton: true,
            });
          } else {
            const responseCreate = await createNewRequest(
              userCreatedData.id,
              myId,
              selectedDate,
              hourSelected,
              type,
              payment,
              details,
              email,
              name,
              lastName,
              birthDate,
              genre,
              phone,
              rut,
              region,
              address,
              selectedComune,
              selectedIsapre,
              myId
            );
            if (responseCreate) {
              close();
              Swal.fire({
                icon: "success",
                title: "Reserva creada",
                text: "Reserva creada con éxito",
              });
            }
          }
        }
      } catch (error) {
        close();
        console.log(error);
      }
    }
  };

  return (
    <div style={{ position: "fixed" }}>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={close}
        PaperProps={{
          style: {
            backgroundColor: "#FFFFFFFF",
            borderRadius: "28px",
            width: "100%",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="titleLarge">
            Nueva reserva para el día {selectedDate} ({hoursArray.length}{" "}
            {hoursArray.length === 1 ? "hora disponible" : "horas disponibles"})
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="titleMedium">Datos de paciente</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(220, 227, 233, 1)",
                  height: "56px",
                }}
                onChange={async (e) => {
                  let formattedRut = formatRut(e.target.value);
                  setRut(formattedRut);
                  if (e.target.value.length > 7) {
                    const userData = await getUserByRut(formattedRut);
                    if (userData.length === 0) {
                      setUserCreated(false);
                    } else {
                      Swal.fire({
                        imageUrl: loadingSVG,
                        imageWidth: 300,
                        imageHeight: 300,
                        title: "Cargando...",
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                      });
                      try {
                        if (userData[0].role !== "user") {
                          setUserCreated(false);
                          Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "El rut ingresado no puede usarse.",
                          });
                          return;
                        } else if (
                          userData[0].firstTime === true &&
                          userData[0].createdIn === "web"
                        ) {
                          const birthdayString = userData[0].birthday;
                          const parts = birthdayString.split("/");
                          const day = parseInt(parts[0], 10);
                          const month = parseInt(parts[1], 10) - 1;
                          const year = parseInt(parts[2], 10);

                          setUserCreated(true);
                          setName(userData[0].name);
                          setLastName(userData[0].lastName);
                          setBirthDate(new Date(year, month, day));
                          setGenre(userData[0].genre);
                          setPhone(userData[0].phone);
                          setEmail(userData[0].email);
                          setRegion(userData[0].location.region);
                          setSelectedComune(userData[0].location.comuna);
                          setAddress(userData[0].location.address);
                          setPayment(userData[0].payment);
                          setSelectedIsapre(userData[0].selectedIsapre);
                          setUserCreatedData(userData[0]);
                        } else if (
                          userData[0].firstTime === true &&
                          userData[0].createdIn !== "web"
                        ) {
                          setUserCreated(false);
                          Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "El rut ingresado no puede usarse. El paciente creó su cuenta, pero no rellenó sus datos personales. Por favor, solicite al paciente que complete sus datos personales desde la aplicación GetMED.",
                          });
                          return;
                        } else {
                          const birthdayString = userData[0].birthday;
                          const parts = birthdayString.split("/");
                          const day = parseInt(parts[0], 10);
                          const month = parseInt(parts[1], 10) - 1;
                          const year = parseInt(parts[2], 10);

                          setUserCreated(true);
                          setName(userData[0].name);
                          setLastName(userData[0].lastName);
                          setBirthDate(new Date(year, month, day));
                          setGenre(userData[0].genre);
                          setPhone(userData[0].phone);
                          setEmail(userData[0].email);
                          setRegion(userData[0].location.region);
                          setSelectedComune(userData[0].location.comuna);
                          setAddress(userData[0].location.address);
                          setPayment(userData[0].payment);
                          setSelectedIsapre(userData[0].selectedIsapre);
                          setUserCreatedData(userData[0]);
                        }
                      } catch (error) {
                        console.log(error);
                      }
                      Swal.close();
                    }
                  }
                }}
                variant="filled"
                value={rut}
                placeholder="Rut del paciente"
                label="Rut"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(220, 227, 233, 1)",
                  height: "56px",
                }}
                variant="filled"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombre del paciente"
                label="Nombre"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(220, 227, 233, 1)",
                  height: "56px",
                }}
                variant="filled"
                label="Apellido"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: "56px",
                    border: "none",
                    borderRadius: "12px",
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 2001,
                  }),
                }}
                options={[
                  { value: "Masculino", label: "Masculino" },
                  { value: "Femenino", label: "Femenino" },
                  { value: "Otro", label: "Otro" },
                ]}
                placeholder="Género"
                value={[
                  { value: "Masculino", label: "Masculino" },
                  { value: "Femenino", label: "Femenino" },
                  { value: "Otro", label: "Otro" },
                ].find((option) => option.value === genre)}
                onChange={(val) => {
                  setGenre(val.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider
                adapterLocale={langEs}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  sx={{
                    width: "100%",
                    backgroundColor: "rgba(220, 227, 233, 1)",
                    height: "56px",
                    "& .MuiTextField-root": {
                      borderBottom: "1px solid rgba(220, 227, 233, 1)",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                    },
                  }}
                  label="Fecha de nacimiento"
                  value={birthDate}
                  onChange={(newValue) => {
                    setBirthDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(220, 227, 233, 1)",
                  height: "56px",
                }}
                variant="filled"
                label="Teléfono"
                value={phone}
                type="phone"
                inputProps={{ maxLength: 12 }}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(220, 227, 233, 1)",
                  height: "56px",
                }}
                variant="filled"
                label="Correo"
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: "56px",
                    border: "none",
                    borderRadius: "12px",
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 2001,
                  }),
                }}
                value={region ? { value: region, label: region } : null}
                placeholder="Región"
                onChange={(e) => {
                  setRegion(e.value);
                  setSelectedComune("");
                }}
                options={location.map((e) => ({
                  value: e.region,
                  label: e.region,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: "56px",
                    border: "none",
                    borderRadius: "12px",
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 2001,
                  }),
                }}
                value={
                  selectedComune
                    ? { value: selectedComune, label: selectedComune }
                    : null
                }
                placeholder="Comuna"
                options={
                  location.find((e) => e.region === region)
                    ? location
                        .find((e) => e.region === region)
                        .comunas.map((comuna) => ({
                          value: comuna,
                          label: comuna,
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label))
                    : []
                }
                onChange={(e) => {
                  setSelectedComune(e.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(220, 227, 233, 1)",
                  height: "56px",
                }}
                variant="filled"
                label="Dirección"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="titleMedium">Datos de reserva</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: "56px",
                    border: "none",
                    borderRadius: "12px",
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 2001,
                  }),
                }}
                variant="filled"
                onChange={(e) => setPayment(e.value)}
                options={previsions}
                value={payment ? { value: payment, label: payment } : null}
                placeholder="Forma de pago"
              />
              {payment === "Isapre" && (
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: "56px",
                      border: "none",
                      borderRadius: "12px",
                      backgroundColor: "rgba(0, 0, 0, 0.06)",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 2001,
                    }),
                  }}
                  variant="filled"
                  onChange={(e) => {
                    setSelectedIsapre(e.value);
                  }}
                  value={
                    selectedIsapre
                      ? { value: selectedIsapre, label: selectedIsapre }
                      : null
                  }
                  placeholder="Selecciona una isapre"
                  options={isapres.map((isapre) => ({
                    value: isapre.name,
                    label: isapre.name,
                  }))}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(220, 227, 233, 1)",
                  height: "56px",
                }}
                variant="filled"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                placeholder="Detalles"
                label="Detalles (Síntomas, motivo de consulta, etc.)"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: "56px",
                    border: "none",
                    borderRadius: "12px",
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                  }),
                }}
                menuPosition="fixed"
                variant="filled"
                placeholder="Hora de la consulta"
                value={
                  hourSelected
                    ? { value: hourSelected, label: hourSelected }
                    : null
                }
                onChange={handleHours}
                options={hoursArray.map((hour) => ({
                  value: hour.time,
                  label: hour.time,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                variant="filled"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: "56px",
                    border: "none",
                    borderRadius: "12px",
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                  }),
                }}
                menuPosition="fixed"
                value={type ? { value: type, label: type } : null}
                placeholder="Tipo de reserva"
                closeMenuOnSelect={true}
                options={typesReserve}
                onChange={handleTypeReserve}
              />
              {type === "Videollamada" && (
                <p
                  style={{
                    color: "grey",
                    fontSize: "12px",
                  }}
                >
                  * Para realizar una videollamada la reserva debe ser
                  solicitada desde la aplicación GetMED.
                </p>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions
          sx={{
            px: 3,
            py: 3,
          }}
        >
          <Button
            variant="text"
            sx={{
              backgroundColor: "transparent",
              color: "black",
            }}
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </Button>
          {type !== "Videollamada" && (
            <Button
              variant="outlined"
              sx={{
                borderRadius: "100px",
                background: "#006686",
                color: "#77F8E0",
              }}
              onClick={async () => handleEnd()}
            >
              {"Crear reserva"}
            </Button>
          )}
        </DialogActions>
        <Loading loadingOn={loading} loadingOff={() => setLoading(false)} />
      </Dialog>
    </div>
  );
}
