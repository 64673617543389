import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import AgoraRTC from "agora-rtc-sdk-ng";
import { AgoraRTCProvider } from "agora-rtc-react";

// import { AgoraServiceProvider } from "agora-rtc-react";
// import { ExamContextProvider } from "./context/examContext";
// import { UserProvider } from "./context/UserContext";
// import { UpdateSalesProvider } from "./context/UpdateSales";
// import { UpdateSecretariesProvider } from "./context/UpdateSrecretaries";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/configureStore";
// import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
// import ButtonDrawer from "../src/components/button_drawer";
//import NewDrawerComponent from "../src/components/new_drawer";
import CustomThemeProvider from "../src/components/customThemeProvider.jsx";
import CssBaseline from "@mui/material/CssBaseline";

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme();
AgoraRTC.setLogLevel(4);
// const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

root.render(
  <CustomThemeProvider theme={theme}>
    <Provider store={store}>
      {/* <NewDrawerComponent/> */}
      <PersistGate loading={null} persistor={persistor}>
        <AgoraRTCProvider
          client={AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })}
        >
          {/* <UpdateSecretariesProvider>
            <UpdateSalesProvider> */}
          <CssBaseline />
          <App />
          {/* </UpdateSalesProvider>
          </UpdateSecretariesProvider> */}
        </AgoraRTCProvider>
      </PersistGate>
    </Provider>
  </CustomThemeProvider>
);
