import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  PieChart,
  Pie,
  XAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import { getMonthlyDataMedicalCenter } from "../database/reports";
import { Typography, Container, Button, Box } from "@mui/material";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";

import {
  generatePDFDailyMedicalCenter,
  generatePDFReportingMedicalCenter,
} from "../utils/generate_pdfs";
import { capitalize } from "../utils/functions";
import { usePadding } from "../context/PaddingContext";
//REDUX IMPORTS
import { useSelector } from "react-redux";

// import NewDrawerComponent from "../components/new_drawer";

import TopBar from "../components/top_bar";

export default function HomeMedicalCenter() {
  const dataDoctor = useSelector((state) => state.auth.userData);
  console.log(dataDoctor);
  const myId = useSelector((state) => state.auth.idUser);
  const [dataStatus, setDataStatus] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [totalMonth, setTotalMonth] = useState(0);
  const [totals, setTotals] = useState([]);
  const [totalPlatform, setTotalPlatform] = useState([]);
  const date = new Date().toISOString().split("T")[0];
  const [weeklyData, setWeeklyData] = useState([]);
  const [dailyData, setDailyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const padding = usePadding();
  const COLORS = ["#006686", "#77F8E0", "#FFDAD6", "#E1E0FF", "#DE3730"];

  const CustomBar = (props) => {
    const { fill, x, y, width, height } = props;
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={fill}
          rx={5}
          ry={5}
        />
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // Verifica si payload es definido y tiene al menos un elemento
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: 5,
          }}
        >
          <p>{`${label} : ${payload[0].value.toLocaleString("es-CL", {
            style: "currency",
            currency: "CLP",
          })}`}</p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipPlatform = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // Verifica si payload es definido y tiene al menos un elemento
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: 5,
          }}
        >
          <p>{`${payload[0].name} : ${payload[0].value} solicitudes`}</p>
        </div>
      );
    }
    return null;
  };

  const formatLegendText = (value, entry) => {
    // Aquí puedes personalizar el texto de la leyenda como desees
    return `${
      entry.payload.status === "accepted"
        ? "Por atender"
        : entry.payload.status === "clientAbsent"
        ? "Ausentes"
        : entry.payload.status === "completed"
        ? "Completadas"
        : entry.payload.status === "waiting"
        ? "En espera"
        : "Canceladas"
    }`; // Por ejemplo, "En proceso: 30"
  };

  const CustomTooltipStatus = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // Verifica si payload es definido y tiene al menos un elemento
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: 5,
          }}
        >
          <p>{`${
            payload[0].name === "waiting"
              ? "En espera de exámenes"
              : payload[0].name === "accepted"
              ? "Por atender"
              : payload[0].name === "completed"
              ? "Completadas"
              : payload[0].name === "clientAbsent"
              ? "Ausentes"
              : "Canceladas"
          } : ${payload[0].value} solicitudes`}</p>
        </div>
      );
    }
    return null;
  };

  // Función para ajustar la fecha a la zona horaria de Santiago
  function adjustDateToSantiagoTime(date) {
    // Santiago, Chile, tiene un desplazamiento horario de -3 horas respecto al Tiempo Universal Coordinado (UTC)
    const offsetHours = -3;
    // Obtener la fecha ajustada con el desplazamiento horario
    return new Date(date.getTime() + offsetHours * 60 * 60 * 1000);
  }

  useEffect(() => {
    async function fetchData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const monthlyDataResponse = await getMonthlyDataMedicalCenter(myId);
      if (monthlyDataResponse) {
        setDailyData(monthlyDataResponse.dataDaily);
        setWeeklyData(monthlyDataResponse.dataWeekly);
        setMonthlyData(monthlyDataResponse.dataMonthly);
        const finalData = [
          {
            day: "Efectivo",
            Ganancias: monthlyDataResponse.dataDaily.byType.totalEfectivo,
          },
          {
            day: "Tarjeta",
            Ganancias: monthlyDataResponse.dataDaily.byType.totalTarjeta,
          },
          {
            day: "Fonasa",
            Ganancias: monthlyDataResponse.dataDaily.byType.totalFonasa,
          },
          {
            day: "Isapre",
            Ganancias: monthlyDataResponse.dataDaily.byType.totalIsapre,
          },
        ];
        setTotals(finalData);
        setDataChart(monthlyDataResponse.dataWeekly.dataChart);
        const newDataStatus = [];
        for (const status in monthlyDataResponse.dataMonthly.dataByStatus) {
          if (
            Object.hasOwnProperty.call(
              monthlyDataResponse.dataMonthly.dataByStatus,
              status
            )
          ) {
            const quantity =
              monthlyDataResponse.dataMonthly.dataByStatus[status];
            newDataStatus.push({ status: status, quantity: quantity });
          }
        }
        setDataStatus(newDataStatus);
        setTotalPlatform([
          {
            name: "Aplicación",
            value: monthlyDataResponse.dataMonthly.totalApp,
          },
          { name: "Web", value: monthlyDataResponse.dataMonthly.totalWeb },
        ]);
      }
      Swal.close();
    }
    fetchData();
  }, []);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          ...padding, // Usa el padding del contexto
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: "40%",
          }}
        >
          <Container
            sx={{
              width: "32%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "start",
              backgroundColor: "white",
              height: "100%",
              borderRadius: 5,
            }}
          >
            <Typography
              variant="h5"
              component="p"
              style={{
                color: "#006686",
                fontFamily: "Nunito",
              }}
            >
              Recaudaciones de hoy
            </Typography>
            <Typography
              variant="h6"
              component="p"
              style={{
                color: "black",
                fontFamily: "Nunito",
              }}
            >
              Total hoy:{" "}
              {parseInt(dailyData.totalDay).toLocaleString("es-CL", {
                style: "currency",
                currency: "CLP",
              })}
            </Typography>
            <br />
            <ResponsiveContainer width="100%" height="60%">
              <BarChart
                data={totals}
                margin={{ top: 1, right: 1, left: 1, bottom: 1 }}
              >
                <XAxis dataKey="day" />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  shape={CustomBar}
                  background
                  dataKey="Ganancias"
                  fill="#5154B2"
                />
              </BarChart>
            </ResponsiveContainer>
          </Container>
          <Container
            sx={{
              width: "65%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "start",
              backgroundColor: "white",
              height: "100%",
              borderRadius: 5,
            }}
          >
            <Typography
              style={{
                color: "#006686",
                fontFamily: "Nunito",
              }}
              variant="h5"
              component="p"
            >
              Recaudaciones de la última semana
            </Typography>
            <Typography
              style={{
                color: "black",
                fontFamily: "Nunito",
              }}
              variant="h6"
              component="p"
            >
              Total semana:{" "}
              {dataChart
                .reduce((acc, item) => acc + item.Ganancias, 0)
                .toLocaleString("es-CL", {
                  style: "currency",
                  currency: "CLP",
                })}
            </Typography>
            <br />
            <ResponsiveContainer width="100%" height="60%">
              <BarChart
                data={dataChart}
                style={{}}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="day" />
                {/* <YAxis /> */}
                <Tooltip content={<CustomTooltip />} />
                {/* <Legend /> */}
                <Bar
                  shape={CustomBar}
                  background
                  name=""
                  dataKey="Ganancias"
                  fill="#006686"
                />
              </BarChart>
            </ResponsiveContainer>
          </Container>
        </div>
        <br />
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Container
            sx={{
              width: "32%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "start",
              backgroundColor: "white",
              borderRadius: 5,
            }}
          >
            <Typography
              variant="h5"
              style={{
                color: "#006686",
                fontFamily: "Nunito",
              }}
              component="p"
            >
              Solicitudes del mes
            </Typography>
            <Typography
              variant="h6"
              style={{
                color: "black",
                fontFamily: "Nunito",
              }}
              component="p"
            >
              Total solicitudes:{" "}
              {dataStatus.reduce((acc, item) => acc + item.quantity, 0)}
            </Typography>
            <PieChart width={400} height={230}>
              <Pie
                data={dataStatus}
                dataKey="quantity"
                nameKey="status"
                cx="50%"
                cy="50%"
                innerRadius={50}
                outerRadius={90}
                fill="#8884d8"
                paddingAngle={0}
              >
                {dataStatus.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltipStatus />} />
              {/* <Legend formatter={formatLegendText} /> */}
              <Legend
                formatter={(value, entry, index) => (
                  <span
                    style={{
                      color: "black",
                      fontFamily: "Nunito",
                      fontSize: 11,
                    }}
                  >
                    {value === "accepted"
                      ? "Por atender"
                      : value === "completed"
                      ? "Completadas"
                      : value === "canceledByClient"
                      ? "Canceladas"
                      : value === "clientAbsent"
                      ? "Ausentes"
                      : "En espera de exámenes"}
                  </span>
                )}
              />
            </PieChart>
          </Container>
          <Container
            sx={{
              width: "32%",
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              alignItems: "start",
              backgroundColor: "white",
              borderRadius: 5,
            }}
          >
            <Typography
              variant="h5"
              style={{
                color: "#006686",
                fontFamily: "Nunito",
                marginTop: 20,
              }}
              component="div"
            >
              Recaudaciones del mes
            </Typography>
            <div
              style={{
                marginTop: "20%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="h2" component="div">
                {parseInt(monthlyData.totalMonth).toLocaleString("es-CL", {
                  style: "currency",
                  currency: "CLP",
                })}
              </Typography>
              {parseInt(dailyData.totalDay).toLocaleString("es-CL", {
                style: "currency",
                currency: "CLP",
              }) > 0 && (
                <Button
                  onClick={() => {
                    Swal.fire({
                      title:
                        "¿Desea generar el informe diario con detalles por cita?",
                      icon: "question",
                      text: "El detalle incluye información de cada cita realizada en el mes.",
                      showDenyButton: true,
                      showCancelButton: false,
                      confirmButtonText: `Generar informe con detalles`,
                      denyButtonText: `Generar informe sin detalles`,
                      confirmButtonColor: "#006686",
                      denyButtonColor: "#006686",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        weeklyData.totalWeek = dataChart
                          .reduce((acc, item) => acc + item.Ganancias, 0)
                          .toLocaleString("es-CL", {
                            style: "currency",
                            currency: "CLP",
                          });
                        generatePDFDailyMedicalCenter(
                          {
                            name: capitalize(dataDoctor.name),
                            email: capitalize(dataDoctor.email),
                            address: capitalize(
                              `${dataDoctor.location.address}, ${dataDoctor.location.comuna}`
                            ),
                            dailyTotal: parseInt(
                              dailyData.totalDay
                            ).toLocaleString("es-CL", {
                              style: "currency",
                              currency: "CLP",
                            }),
                            dailyTypePay: totals,
                            dailyData: dailyData,
                            watermark: dataDoctor.logo,
                            date: new Date().toLocaleDateString("es-CL", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }),
                          },
                          false
                        );
                      } else if (result.isDenied) {
                        weeklyData.totalWeek = dataChart
                          .reduce((acc, item) => acc + item.Ganancias, 0)
                          .toLocaleString("es-CL", {
                            style: "currency",
                            currency: "CLP",
                          });
                        generatePDFDailyMedicalCenter(
                          {
                            name: capitalize(dataDoctor.name),
                            email: capitalize(dataDoctor.email),
                            address: capitalize(
                              `${dataDoctor.location.address}, ${dataDoctor.location.comuna}`
                            ),
                            dailyTotal: parseInt(
                              dailyData.totalDay
                            ).toLocaleString("es-CL", {
                              style: "currency",
                              currency: "CLP",
                            }),
                            dailyTypePay: totals,
                            dailyData: dailyData,
                            watermark: dataDoctor.logo,
                            date: new Date().toLocaleDateString("es-CL", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }),
                          },
                          true
                        );
                      }
                    });
                  }}
                  color="primary"
                  style={{ marginTop: 20, textTransform: "none" }}
                >
                  Ver informe diario
                </Button>
              )}
              <Button
                onClick={() => {
                  Swal.fire({
                    title: "¿Desea generar el informe con un detalle por cita?",
                    icon: "question",
                    text: "El detalle incluye información de cada cita realizada en el mes.",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: `Generar sin detalle`,
                    denyButtonText: `Generar con detalle`,
                    confirmButtonColor: "#006686",
                    denyButtonColor: "#006686",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      generatePDFReportingMedicalCenter(
                        {
                          name: capitalize(dataDoctor.name),
                          email: capitalize(dataDoctor.email),
                          address: capitalize(
                            `${dataDoctor.location.address}, ${dataDoctor.location.comuna}`
                          ),
                          monthlyTotal: totalMonth.toLocaleString("es-CL", {
                            style: "currency",
                            currency: "CLP",
                          }),
                          dailyTotal: parseInt(
                            dailyData.totalDay
                          ).toLocaleString("es-CL", {
                            style: "currency",
                            currency: "CLP",
                          }),
                          dailyTypePay: totals,
                          weeklyData: weeklyData,
                          dailyData: dailyData,
                          monthlyData: monthlyData,
                          watermark:
                            dataDoctor.role === "medicalCenter"
                              ? dataDoctor.imageProfile
                              : dataDoctor.logo,
                          date: new Date().toLocaleDateString("es-CL", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }),
                        },
                        false
                      );
                    } else if (result.isDenied) {
                      let allData = [];
                      for (let i = 0; i < 9; i++) {
                        allData.push(...monthlyData.allData);
                      }
                      // console.log(allData);
                      // console.log(dataDoctor);
                      generatePDFReportingMedicalCenter(
                        {
                          name: capitalize(dataDoctor.name),
                          email: capitalize(dataDoctor.email),
                          address: capitalize(
                            `${dataDoctor.location.address}, ${dataDoctor.location.comuna}`
                          ),
                          monthlyTotal: totalMonth.toLocaleString("es-CL", {
                            style: "currency",
                            currency: "CLP",
                          }),
                          dailyTotal: parseInt(
                            dailyData.totalDay
                          ).toLocaleString("es-CL", {
                            style: "currency",
                            currency: "CLP",
                          }),
                          dailyTypePay: totals,
                          weeklyData: weeklyData,
                          dailyData: dailyData,
                          monthlyData: monthlyData,
                          watermark:
                            dataDoctor.role === "medicalCenter"
                              ? dataDoctor.imageProfile
                              : dataDoctor.logo,
                          date: new Date().toLocaleDateString("es-CL", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }),
                        },
                        true,
                        allData
                      );
                    }
                  });
                }}
                color="primary"
                style={{ marginTop: 20, textTransform: "none" }}
              >
                Ver informe mensual
              </Button>
            </div>
          </Container>
          <Container
            sx={{
              width: "32%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "start",
              backgroundColor: "white",
              borderRadius: 5,
            }}
          >
            <br />
            <Typography
              variant="h5"
              style={{
                color: "#006686",
                fontFamily: "Nunito",
              }}
              component="div"
            >
              Plataforma
            </Typography>
            <Typography
              style={{
                color: "grey",
                fontSize: 12,
                fontFamily: "Nunito",
              }}
              component="div"
            >
              *Este gráfico representa desde dónde se crearon las solicitudes
            </Typography>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PieChart width={300} height={300}>
                <Pie
                  data={totalPlatform}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={90}
                  fill="#006686"
                >
                  {totalPlatform.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltipPlatform />} />
                <Legend
                  formatter={(value, entry, index) => (
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Nunito",
                        fontSize: 15,
                      }}
                    >
                      {value}
                    </span>
                  )}
                />
              </PieChart>
            </div>
          </Container>
        </div>
      </Box>
    </>
  );
}
