import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  docSnap,
} from "firebase/firestore";
import { db } from "./config";

export async function createChangeRequest(dataRequest) {
  try {
    dataRequest.createdAt = serverTimestamp();
    const queryData = query(collection(db, "request_bank_data"));
    await addDoc(queryData, dataRequest);
    return true;
  } catch (error) {
    console.log("Error adding document request bank change data: ", error);
    return false;
  }
}
export async function getChangeRequest(idUser) {
  try {
    const types = await getDocs(
      query(
        collection(db, "request_bank_data"),
        where("idUser", "==", idUser),
        where("status", "==", "pending")
      )
    );
    let a = types.docs.map((doc) => ({
      ...doc.data(),
    }));
    if (a.length > 0) {
      return a;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error getting document request bank change data: ", error);
    return false;
  }
}

export async function getAllPendingChangeRequest() {
  try {
    const queryData = query(
      collection(db, "request_bank_data"),
      where("status", "==", "pending")
    );

    const types = await getDocs(queryData);

    let data = types.docs.map((doc) => ({
      id: doc.id, // Agrega el ID de la solicitud al objeto de datos
      ...doc.data(),
    }));
    if (data.length > 0) {
      await Promise.all(
        data.map(async (element) => {
          const docRef = doc(db, "users", element.idUser);
          const docSnap = await getDoc(docRef);
          element.profesionalData = docSnap.data();
        })
      );
      return data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error getting document request bank change data: ", error);
    return false;
  }
}

export async function updateChangeRequest(data) {
  try {
    const docRef = doc(db, "request_bank_data", data.id);
    await updateDoc(docRef, { status: data.newStatus });
    if (data.newStatus !== "declined") {
      const docRef2 = doc(collection(db, "users"), data.idUser);
      await updateDoc(docRef2, { bankAccount: data.data.newBankAccount });
    }
    return true;
  } catch (error) {
    console.log("Error updating document request bank change data: ", error);
    return false;
  }
}
