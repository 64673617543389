import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ScheduleIcon from "@mui/icons-material/Schedule";
import NewHourModal from "./new_hour_modal";
import { Close, DeleteTwoTone } from "@mui/icons-material";
import {
  addHourToSchedule,
  deleteScheduleByDay,
  updateHourToSchedule,
} from "../database/schedules";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import {
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Box,
  Divider,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import loadingSVG from "../assets/logo_Loading.gif";
import Swal from "sweetalert2";
import { getMyRequestsByDayAndDate } from "../database/request";
import {
  createMultiNotification,
  createNotification,
} from "../database/notifications";
import { capitalize } from "@mui/material";
import { updateRequestStatus } from "../database/request";
//reservada
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
// libre
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
//cancelada
import CommentsDisabledOutlinedIcon from "@mui/icons-material/CommentsDisabledOutlined";

const AddHoursModal = ({
  open,
  onClose,
  hours,
  date,
  idDocument,
  reloadData,
  existsScheduleTomorrow,
}) => {
  const [hoursLocal, setHoursLocal] = useState([]);
  const [openNewHourModal, setOpenNewHourModal] = useState(false);
  const myId = useSelector((state) => state.auth.idUser);
  const userData = useSelector((state) => state.auth.userData);
  // const [idDeleteHoursUsers, setIdDeleteHoursUsers] = useState([]);

  const handleDeleteHour = async (index) => {
    // console.log(userData);
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      backdrop: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    if (hoursLocal[index].status === "taked") {
      Swal.fire({
        icon: "warning",
        title: "¡Atención!",
        text: "¿Estás seguro(a) de eliminar esta hora? Ya está reservada por un paciente.",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, eliminar",
      }).then(async (result) => {
        Swal.fire({
          imageUrl: loadingSVG,
          imageWidth: 300,
          imageHeight: 300,
          title: "Cargando...",
          backdrop: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        if (result.isConfirmed) {
          // console.log("index", hoursLocal);
          const updatedHours = [...hoursLocal];
          updatedHours.splice(index, 1); // Elimina la hora en el índice especificado
          setHoursLocal(updatedHours);
          let requestByDate = await getMyRequestsByDayAndDate(
            userData.role === "doctor" ? myId : userData.idDoctor,
            hoursLocal[index].time,
            date
          );
          if (requestByDate.length === 0) {
            let response = await updateHourToSchedule(
              date,
              userData.role === "doctor" ? myId : userData.idDoctor,
              updatedHours,
              idDocument
            );
            if (response) {
              Swal.close();
              toast.success("Se ha guardado el horario correctamente");
            } else {
              Swal.close();
              toast.error("Error al guardar las horas");
            }
          } else {
            let idClient = requestByDate[0].idClient;
            let idRequest = requestByDate[0].id;

            let response = await updateHourToSchedule(
              date,
              userData.role === "doctor" ? myId : userData.idDoctor,
              updatedHours,
              idDocument
            );
            await updateRequestStatus(
              idRequest,
              "canceledByDoctor",
              requestByDate[0]
            );
            if (response) {
              let notificationCreated = await createNotification(
                {
                  type: "requestRejected",
                  title: "Solicitud cancelada",
                  body: `Tu solicitud ha sido cancelada por el doctor ${capitalize(
                    userData.name
                  )} ${capitalize(userData.lastName)}.`,
                },
                idClient,
                myId,
                idRequest
              );
              if (notificationCreated) {
                Swal.close();
                toast.success("Se ha guardado el horario correctamente");
              } else {
                Swal.close();
                toast.error("Error al guardar las horas");
              }
            }
          }
        } else {
          Swal.close();
        }
      });
    } else {
      const updatedHours = [...hoursLocal];
      updatedHours.splice(index, 1); // Elimina la hora en el índice especificado
      setHoursLocal(updatedHours);
      let response = await updateHourToSchedule(
        date,
        userData.role === "doctor" ? myId : userData.idDoctor,
        hoursLocal,
        idDocument
      );
      if (response) {
        Swal.close();
        toast.success("Se ha guardado el horario correctamente");
      } else {
        Swal.close();
        toast.error("Error al guardar las horas");
      }
    }
  };

  const hasNewHours = (newHours, existingHours) => {
    return newHours.some((hour) => !existingHours.includes(hour));
  };

  const validate = async () => {
    if (hoursLocal.length === 0) {
      Swal.fire({
        icon: "error",
        title: "¡Atención!",
        text: "Debes agregar al menos una hora.",
      });
    } else {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Se guardarán las horas programadas para este día.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, guardar",
        cancelButtonText: "No, cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            imageUrl: loadingSVG,
            imageWidth: 300,
            imageHeight: 300,
            title: "Cargando...",
            backdrop: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          let response;
          if (idDocument) {
            if (hasNewHours(hoursLocal, hours)) {
              await createMultiNotification(
                {
                  type: "newHourNotification",
                  title: "Hora disponible",
                  body: `Un(a) ${userData.mainSpecialty} ha agregado horas a su horario. Entra aquí para reservar.`,
                },
                userData.mainSpecialty,
                userData.id,
                "newHourNotification"
              );
            }
            // console.log(
            //   "update",
            //   date,
            //   userData.role === "doctor" ? myId : userData.idDoctor,
            //   hoursLocal,
            //   idDocument
            // );
            response = await updateHourToSchedule(
              date,
              userData.role === "doctor" ? myId : userData.idDoctor,
              hoursLocal,
              idDocument
            );
          } else {
            // console.log(
            //   "add",
            //   date,
            //   userData.role === "doctor" ? myId : userData.idDoctor,
            //   hoursLocal
            // );
            response = await addHourToSchedule(
              date,
              userData.role === "doctor" ? myId : userData.idDoctor,
              hoursLocal
            );
          }
          if (response === true) {
            Swal.close();
            reloadData();
            onClose();
            Swal.close();
            toast.success("Se ha guardado el horario correctamente");
          } else {
            Swal.close();
            toast.error("Error al guardar las horas");
          }
        } else {
          Swal.close();
        }
      });
    }
  };

  const copyTomorrow = async () => {
    let a = hasNewHours(hoursLocal, hours);
    console.log(a);
    if (a) {
      Swal.fire({
        icon: "info",
        title: "¡Atención!",
        text: "para copiar horas de hoy a mañana, primero debes guardar nuevas horas.  ",
      });
    } else {
      Swal.fire({
        title: "¿Estás seguro(a)?",
        text: "Se copiarán las horas de hoy para mañana.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, copiar",
        cancelButtonText: "No, cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            imageUrl: loadingSVG,
            imageWidth: 300,
            imageHeight: 300,
            title: "Cargando...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          const dateTomorrow = moment(date).add(1, "days").format("YYYY-MM-DD");
          const response = await addHourToSchedule(
            dateTomorrow,
            userData.role === "doctor" ? myId : userData.idDoctor,
            hours
          );
          if (response === true) {
            Swal.close();
            reloadData();
            onClose();
            Swal.close();
          } else {
            Swal.close();
            toast.error("Error al guardar las horas");
          }
        } else {
          Swal.close();
        }
      });
    }
  };

  const deleteSchedule = async () => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminarán todas las horas programadas para este día.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "No, cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          imageUrl: loadingSVG,
          imageWidth: 300,
          imageHeight: 300,
          title: "Cargando...",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });

        const response = await deleteScheduleByDay(
          userData.role === "doctor" ? myId : userData.idDoctor,
          idDocument
        );
        if (response === true) {
          Swal.close();
          reloadData();
          onClose();
          Swal.close();
          toast.success("Se ha eliminado el horario correctamente");
        } else {
          Swal.close();
          toast.error("Error al eliminar las horas");
        }
      } else {
        Swal.close();
      }
    });
  };

  useEffect(() => {
    // console.log(idDocument);
    // console.log("iniciando horas", Array.isArray(hours), date);
    setHoursLocal(hours);
    // console.log(hoursLocal);
  }, [hours]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={onClose}
        // sx={{ zIndex: "2000" }}
        PaperProps={{
          style: {
            bgcolor: "background.paper",
            borderRadius: "28px",
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            id="hours-modal-title"
            variant="titleLarge"
            component="h2"
            gutterBottom
          >
            Lista de Horas ({date})
          </Typography>
          <Close onClick={onClose} style={{ cursor: "pointer" }} />
        </DialogTitle>
        <DialogContent>
          {idDocument && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>Horas</Typography>

              <Button
                variant="outline"
                onClick={deleteSchedule}
                style={{
                  border: "1px solid #FF00001F",
                  color: "red",
                }}
                startIcon={<DeleteTwoTone style={{ cursor: "pointer" }} />}
              >
                Eliminar horario
              </Button>
            </Box>
          )}

          {hoursLocal.length === 0 ? (
            <Box>
              <Typography
                variant="body1"
                align="center"
                gutterBottom
                sx={{ padding: "40px" }}
              >
                No existen horas registradas.
              </Typography>
            </Box>
          ) : (
            <List
              sx={{
                width: "100%",
              }}
            >
              {hoursLocal.map((hour, index) => (
                <ListItem
                  sx={{
                    borderBottom: "1px solid #0000001F",
                    px: 0,
                  }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteHour(index)}
                    >
                      <DeleteTwoTone
                        sx={{
                          color: "red",
                        }}
                      />
                    </IconButton>
                  }
                  key={index}
                >
                  <ListItemIcon>
                    <ScheduleIcon />
                  </ListItemIcon>
                  <ListItemText primary={hour.time} />

                  {/* <ListItemIcon >
                    <DeleteTwoTone sx={{
                      color: "red",
                    }} onClick={() => handleDeleteHour(index)} />
                  </ListItemIcon> */}
                  {/* <Button
                    color="error"
                    startIcon={<Delete />}
                    onClick={() => handleDeleteHour(index)}
                  >
                    Eliminar
                  </Button> */}
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <Divider />
        {/* <DialogActions> */}
        <DialogActions
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          {!existsScheduleTomorrow && idDocument && (
            <Button
              onClick={() => {
                copyTomorrow();
              }}
            >
              Copiar para mañana
            </Button>
          )}
          <Button
            onClick={() => {
              setOpenNewHourModal(true);
            }}
          >
            Agregar Nueva Hora
          </Button>

          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              validate();
            }}
          >
            Guardar
          </Button>
        </DialogActions>
        {/* </DialogActions> */}
      </Dialog>
      {openNewHourModal && (
        <NewHourModal
          open={openNewHourModal}
          date={date}
          hours={hoursLocal}
          setHours={setHoursLocal}
          onClose={() => {
            setOpenNewHourModal(false);
          }}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default AddHoursModal;
