import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TopBar from "../components/top_bar";
import {
  BarChart,
  Bar,
  Cell,
  PieChart,
  Pie,
  XAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import { getDailyGain, getMonthlyGains } from "../database/reports";
import { Typography, Container, Button } from "@mui/material";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
import { getWeeklyGains } from "../database/reports";

import {
  generatePDFDailyDoctor,
  generatePDFReporting,
} from "../utils/generate_pdfs";
import { capitalize } from "../utils/functions";

export default function Reporting() {
  const dataDoctor = useSelector((state) => state.auth.userData);
  const myId = useSelector((state) => state.auth.idUser);
  const [dataStatus, setDataStatus] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [totalMonth, setTotalMonth] = useState(0);
  const [totals, setTotals] = useState([]);
  const [totalPlatform, setTotalPlatform] = useState([]);
  const date = new Date().toISOString().split("T")[0];
  const [weeklyData, setWeeklyData] = useState([]);
  const [dailyData, setDailyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);

  const COLORS = ["#006686", "#77F8E0", "#FFDAD6", "#E1E0FF", "#DE3730"];

  const CustomBar = (props) => {
    const { fill, x, y, width, height } = props;
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={fill}
          rx={5}
          ry={5}
        />
      </g>
    );
  };

  // Función para ajustar la fecha a la zona horaria de Santiago
  function adjustDateToSantiagoTime(date) {
    // Santiago, Chile, tiene un desplazamiento horario de -3 horas respecto al Tiempo Universal Coordinado (UTC)
    const offsetHours = -3;
    // Obtener la fecha ajustada con el desplazamiento horario
    return new Date(date.getTime() + offsetHours * 60 * 60 * 1000);
  }

  useEffect(() => {
    async function fetchData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const response = await getMonthlyGains(dataDoctor.id);
      if (response) {
        const newDataStatus = [];
        for (const status in response.dataByStatus) {
          if (Object.hasOwnProperty.call(response.dataByStatus, status)) {
            const quantity = response.dataByStatus[status];
            newDataStatus.push({ status: status, quantity: quantity });
          }
        }
        setMonthlyData(response);
        setTotalMonth(response.totalMonth);
        setDataStatus(newDataStatus);
        setTotalPlatform([
          { name: "Aplicación", value: response.totalApp },
          { name: "Web", value: response.totalWeb },
        ]);
      }

      let weeklyGains = await getWeeklyGains(myId);
      weeklyGains.dataChart.sort((a, b) => a.day - b.day);
      weeklyGains.dataChart.forEach((item) => {
        const date = new Date(item.day + "T00:00:00");
        const dayOfWeek = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"][
          date.getDay()
        ];
        const dayOfMonth = date.getDate();
        item.day = `${dayOfWeek} ${dayOfMonth}`;
      });
      setWeeklyData(weeklyGains);
      setDataChart(weeklyGains.dataChart);
      const responseRequests = await getDailyGain(myId, date); //getMyRequestsByDayForChart(date, myId);
      setDailyData(responseRequests);
      let totalEfectivo = responseRequests.byType.totalEfectivo;
      let totalTarjeta = responseRequests.byType.totalCard;
      let totalFonasa = responseRequests.byType.totalFonasa;
      let totalIsapre = responseRequests.byType.totalIsapre;
   
      const finalData = [
        { day: "Efectivo", Ganancias: totalEfectivo },
        { day: "Tarjeta", Ganancias: totalTarjeta },
        { day: "Fonasa", Ganancias: totalFonasa },
        { day: "Isapre", Ganancias: totalIsapre },
      ];
      setTotals(finalData);
      Swal.close();
    }

    fetchData();
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // Verifica si payload es definido y tiene al menos un elemento
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: 5,
          }}
        >
          <p>{`${label} : ${payload[0].value.toLocaleString("es-CL", {
            style: "currency",
            currency: "CLP",
          })}`}</p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipPlatform = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // Verifica si payload es definido y tiene al menos un elemento
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: 5,
          }}
        >
          <p>{`${payload[0].name} : ${payload[0].value} solicitudes`}</p>
        </div>
      );
    }
    return null;
  };

  const formatLegendText = (value, entry) => {
    // Aquí puedes personalizar el texto de la leyenda como desees
    return `${
      entry.payload.status === "accepted"
        ? "Por atender"
        : entry.payload.status === "clientAbsent"
        ? "Ausentes"
        : entry.payload.status === "completed"
        ? "Completadas"
        : entry.payload.status === "waiting"
        ? "En espera"
        : "Canceladas"
    }`; // Por ejemplo, "En proceso: 30"
  };

  const CustomTooltipStatus = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // Verifica si payload es definido y tiene al menos un elemento
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: 5,
          }}
        >
          <p>{`${
            payload[0].name === "waiting"
              ? "En espera de exámenes"
              : payload[0].name === "accepted"
              ? "Por atender"
              : payload[0].name === "completed"
              ? "Completadas"
              : payload[0].name === "clientAbsent"
              ? "Ausentes"
              : "Canceladas"
          } : ${payload[0].value} solicitudes`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          flex: 1, // Esta propiedad hace que el Container ocupe el resto del espacio
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "15%",
        }}
      >
        <div
          style={{
            width: "90%",
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              height: "40%",
            }}
          >
            <Container
              sx={{
                width: "32%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "start",
                backgroundColor: "white",
                height: "100%",
                borderRadius: 5,
              }}
            >
              <Typography
                variant="h5"
                component="p"
                style={{
                  color: "#006686",
                  fontFamily: "Nunito",
                }}
              >
                Recaudaciones de hoy
              </Typography>
              <Typography
                variant="h6"
                component="p"
                style={{
                  color: "black",
                  fontFamily: "Nunito",
                }}
              >
                Total hoy:{" "}
                {totals
                  .reduce((acc, item) => acc + item.Ganancias, 0)
                  .toLocaleString("es-CL", {
                    style: "currency",
                    currency: "CLP",
                  })}
              </Typography>
              <br />
              <ResponsiveContainer width="100%" height="60%">
                <BarChart
                  data={totals}
                  margin={{ top: 1, right: 1, left: 1, bottom: 1 }}
                >
                  <XAxis dataKey="day" />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    shape={CustomBar}
                    background
                    dataKey="Ganancias"
                    fill="#5154B2"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Container>
            <Container
              sx={{
                width: "65%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "start",
                backgroundColor: "white",
                height: "100%",
                borderRadius: 5,
              }}
            >
              <Typography
                style={{
                  color: "#006686",
                  fontFamily: "Nunito",
                }}
                variant="h5"
                component="p"
              >
                Recaudaciones de la última semana
              </Typography>
              <Typography
                style={{
                  color: "black",
                  fontFamily: "Nunito",
                }}
                variant="h6"
                component="p"
              >
                Total semana:{" "}
                {dataChart
                  .reduce((acc, item) => acc + item.Ganancias, 0)
                  .toLocaleString("es-CL", {
                    style: "currency",
                    currency: "CLP",
                  })}
              </Typography>
              <br />
              <ResponsiveContainer width="100%" height="60%">
                <BarChart
                  data={dataChart}
                  style={{}}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis dataKey="day" />
                  {/* <YAxis /> */}
                  <Tooltip content={<CustomTooltip />} />
                  {/* <Legend /> */}
                  <Bar
                    shape={CustomBar}
                    background
                    name=""
                    dataKey="Ganancias"
                    fill="#006686"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Container>
          </div>
          <br />
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Container
              sx={{
                width: "32%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "start",
                backgroundColor: "white",
                borderRadius: 5,
              }}
            >
              <Typography
                variant="h5"
                style={{
                  color: "#006686",
                  fontFamily: "Nunito",
                }}
                component="p"
              >
                Solicitudes del mes
              </Typography>
              <Typography
                variant="h6"
                style={{
                  color: "black",
                  fontFamily: "Nunito",
                }}
                component="p"
              >
                Total solicitudes:{" "}
                {dataStatus.reduce((acc, item) => acc + item.quantity, 0)}
              </Typography>
              <PieChart width={380} height={230}>
                <Pie
                  data={dataStatus}
                  dataKey="quantity"
                  nameKey="status"
                  cx="50%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={90}
                  fill="#8884d8"
                  paddingAngle={0}
                >
                  {dataStatus.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltipStatus />} />
                {/* <Legend formatter={formatLegendText} /> */}
                <Legend
                  formatter={(value, entry, index) => (
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Nunito",
                        fontSize: 11,
                      }}
                    >
                      {value === "accepted"
                        ? "Por atender"
                        : value === "completed"
                        ? "Completadas"
                        : value === "canceledByClient"
                        ? "Canceladas"
                        : value === "clientAbsent"
                        ? "Ausentes"
                        : "En espera de exámenes"}
                    </span>
                  )}
                />
              </PieChart>
            </Container>
            <Container
              sx={{
                width: "32%",
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                alignItems: "start",
                backgroundColor: "white",
                borderRadius: 5,
              }}
            >
              <Typography
                variant="h5"
                style={{
                  color: "#006686",
                  fontFamily: "Nunito",
                  marginTop: 20,
                }}
                component="div"
              >
                Recaudaciones del mes
              </Typography>
              <div
                style={{
                  marginTop: "20%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="h2" component="div">
                  {totalMonth.toLocaleString("es-CL", {
                    style: "currency",
                    currency: "CLP",
                  })}
                </Typography>
                {totalMonth > 0 && (
                  <Button
                    onClick={() => {
                      Swal.fire({
                        title:
                          "¿Desea generar el informe diario con detalles por cita?",
                        icon: "question",
                        text: "El detalle incluye información de cada cita realizada en el mes.",
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: `Generar informe con detalles`,
                        denyButtonText: `Generar informe sin detalles`,
                        confirmButtonColor: "#006686",
                        denyButtonColor: "#006686",
                      }).then((result) => {
                        if (result.isConfirmed) {
                        
                          generatePDFDailyDoctor(
                            {
                              name:
                                capitalize(dataDoctor.name) +
                                " " +
                                capitalize(dataDoctor.lastName),
                              email: capitalize(dataDoctor.email),
                              address: capitalize(
                                `${dataDoctor.location.address}, ${dataDoctor.location.comuna}`
                              ),
                              dailyTypePay: totals,
                              dailyData: dailyData,
                              watermark: dataDoctor.logo,
                              date: new Date().toLocaleDateString("es-CL", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }),
                            },
                            true
                          );
                        } else if (result.isDenied) {
                          generatePDFDailyDoctor(
                            {
                              name:
                                capitalize(dataDoctor.name) +
                                " " +
                                capitalize(dataDoctor.lastName),
                              email: capitalize(dataDoctor.email),
                              address: capitalize(
                                `${dataDoctor.location.address}, ${dataDoctor.location.comuna}`
                              ),
                              dailyTypePay: totals,
                              dailyData: dailyData,
                              watermark: dataDoctor.logo,
                              date: new Date().toLocaleDateString("es-CL", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }),
                            },
                            false
                          );
                        }
                      });
                    }}
                    color="primary"
                    style={{ marginTop: 20, textTransform: "none" }}
                  >
                    Ver informe diario
                  </Button>
                )}
                <Button
                  onClick={() => {
                   
                    Swal.fire({
                      title:
                        "¿Desea generar el informe diario con detalles por cita?",
                      icon: "question",
                      text: "El detalle incluye información de cada cita realizada en el mes.",
                      showDenyButton: true,
                      showCancelButton: false,
                      confirmButtonText: `Generar informe con detalles`,
                      denyButtonText: `Generar informe sin detalles`,
                      confirmButtonColor: "#006686",
                      denyButtonColor: "#006686",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        generatePDFReporting(
                          {
                            name:
                              capitalize(dataDoctor.name) +
                              " " +
                              capitalize(dataDoctor.lastName),
                            email: capitalize(dataDoctor.email),
                            address: capitalize(
                              `${dataDoctor.location.address}, ${dataDoctor.location.comuna}`
                            ),
                            monthlyTotal: totalMonth.toLocaleString("es-CL", {
                              style: "currency",
                              currency: "CLP",
                            }),
                            dailyTypePay: totals,
                            weeklyData: weeklyData,
                            dailyData: dailyData,
                            monthlyData: monthlyData,
                            watermark: dataDoctor.logo,
                            date: new Date().toLocaleDateString("es-CL", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }),
                          },
                          true
                        );
                      } else if (result.isDenied) {
                        generatePDFReporting(
                          {
                            name:
                              capitalize(dataDoctor.name) +
                              " " +
                              capitalize(dataDoctor.lastName),
                            email: capitalize(dataDoctor.email),
                            address: capitalize(
                              `${dataDoctor.location.address}, ${dataDoctor.location.comuna}`
                            ),
                            monthlyTotal: totalMonth.toLocaleString("es-CL", {
                              style: "currency",
                              currency: "CLP",
                            }),
                            dailyTypePay: totals,
                            weeklyData: weeklyData,
                            dailyData: dailyData,
                            monthlyData: monthlyData,
                            watermark: dataDoctor.logo,
                            date: new Date().toLocaleDateString("es-CL", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }),
                          },
                          false
                        );
                      }
                    });
                  }}
                  color="primary"
                  style={{ marginTop: 20, textTransform: "none" }}
                >
                  Ver informe mensual
                </Button>
              </div>
            </Container>
            <Container
              sx={{
                width: "32%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "start",
                backgroundColor: "white",
                borderRadius: 5,
              }}
            >
              <br />
              <Typography
                variant="h5"
                style={{
                  color: "#006686",
                  fontFamily: "Nunito",
                }}
                component="div"
              >
                Plataforma
              </Typography>
              <Typography
                style={{
                  color: "grey",
                  fontSize: 12,
                  fontFamily: "Nunito",
                }}
                component="div"
              >
                *Este gráfico representa desde dónde se crearon las solicitudes
              </Typography>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PieChart width={300} height={300}>
                  <Pie
                    data={totalPlatform}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={50}
                    outerRadius={90}
                    fill="#006686"
                  >
                    {totalPlatform.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltipPlatform />} />
                  <Legend
                    formatter={(value, entry, index) => (
                      <span
                        style={{
                          color: "black",
                          fontFamily: "Nunito",
                          fontSize: 15,
                        }}
                      >
                        {value}
                      </span>
                    )}
                  />
                </PieChart>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}
