import React, { useEffect, useState } from "react";
import { deactivateChat, getChatList } from "../database/chats";
import { getMyAcceptedRequests } from "../database/request";
import {
  Container,
  List,
  ListItem,
  Avatar,
  Typography,
  Button,
  Card,
  capitalize,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import backgroundCall from "../assets/backgroundRequest.svg";
import CardViewChat from "../components/card_view_chat_component";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// import { List } from '@mui/icons-material';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
// import NewDrawerComponent from "../components/new_drawer";
import { completeRequest } from "../database/request";
import NavPaper from "../components/nav_paper"; // Import NavPaper component

//REDUX IMPORTS
import { useSelector } from "react-redux";
import TopBar from "../components/top_bar";
import { Circle } from "@mui/icons-material";

export default function ChatList() {
  const [chatData, setChatData] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [dataClient, setDataClient] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [dataDoctor, setDataDoctor] = useState({});
  const [chatSelected, setChatSelected] = useState(false);
  const [showList, setShowList] = useState(true);
  // const [fullData, setFullData] = useState([]);
  // const [res, setRes] = useState([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const puntoDeQuiebre = 1300;
  const breakVariable = windowWidth >= puntoDeQuiebre;

  const myId = useSelector((state) => state.auth.idUser);
  const userData = useSelector((state) => state.auth.userData);
  function formatearRut(rut) {
    // Eliminar cualquier caracter que no sea dígito o la letra 'k' si está al final
    rut = rut.replace(/[^\dkK]/g, "");

    // Separar el rut y el dígito verificador
    var rutSinDigito = rut.slice(0, -1);
    var digitoVerificador = rut.slice(-1).toUpperCase();

    // Formatear el rut con puntos y guión
    var rutFormateado = "";
    var contador = 0;
    for (var i = rutSinDigito.length - 1; i >= 0; i--) {
      rutFormateado = rutSinDigito.charAt(i) + rutFormateado;
      contador++;
      if (contador % 3 === 0 && contador !== rutSinDigito.length) {
        rutFormateado = "." + rutFormateado;
      }
    }

    // Agregar el guión y el dígito verificador
    rutFormateado += "-" + digitoVerificador;

    return rutFormateado;
  }
  useEffect(() => {
    async function getData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      setDataDoctor(userData);
      // let resData = await getMyAcceptedRequests(myId);
      var res = await getChatList(myId, setChatData);
      // setRes(res);
      // console.log("resData", resData);

      setChatData(res);
      Swal.close();
    }

    getData();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myId, userData]);

  const setDataToMessage = (message) => {
    // console.log(message);
    setSelectedChat(message.idChats);
    setDataClient(message.client);
    setSelectedClient(message.client.id);
    setShowList(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // async function endRequest() {
  //   console.log(fullData);
  //   let data = {
  //     doctor: {
  //       name: fullData.doctor.name,
  //       lastName: fullData.doctor.lastName,
  //     },
  //     idDoctor: fullData.doctor.id,
  //     idClient: fullData.client.id,
  //   };
  //   Swal.fire({
  //     title: `¿Estás seguro de que deseas finalizar la sesión con `,
  //     text: "No podrás revertir esta acción",
  //     icon: "info",
  //     showCancelButton: true,
  //     confirmButtonText: "Sí, finalizar",
  //     confirmButtonColor: "#004860",
  //     cancelButtonText: "Cancelar",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       Swal.fire({
  //         imageUrl: loadingSVG,
  //         imageWidth: 300,
  //         imageHeight: 300,
  //         title: "Cargando...",
  //         showConfirmButton: false,
  //         allowOutsideClick: false,
  //         allowEscapeKey: false,
  //       });
  //       let resRequest = await completeRequest(fullData.id, data);
  //       let resChatEnd = await deactivateChat(selectedChat);
  //       console.log(resRequest && resChatEnd);
  //       if (resRequest && resChatEnd) {
  //         Swal.close();
  //         Swal.fire({
  //           title: "Sesión finalizada",
  //           text: "Se ha finalizado la sesión con el cliente",
  //           icon: "success",
  //           confirmButtonColor: "#004860",
  //           confirmButtonText: "Aceptar",
  //         }).then((result) => {
  //           if (result.isConfirmed) {
  //             window.location.reload();
  //           }
  //         });
  //       } else {
  //         Swal.close();
  //         Swal.fire({
  //           title: "Error",
  //           text: "No se ha podido finalizar la sesión",
  //           icon: "error",
  //           confirmButtonColor: "#004860",
  //           confirmButtonText: "Aceptar",
  //         });
  //       }
  //     }
  //   });
  // }

  const disableChat = async () => {
    Swal.fire({
      text: `¿Estás seguro de que deseas desactivar el chat con este paciente?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Sí, desactivar",
      confirmButtonColor: "#004860",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          imageUrl: loadingSVG,
          imageWidth: 300,
          imageHeight: 300,
          title: "Cargando...",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        //id request
        //data.doctor.name
        //data.doctor.lastName
        //data.idClient
        //data.idDoctor

        const dataFiltered = chatData.filter(
          (word) => word.idChats === selectedChat
        );
        let dataToCompleteRequest = {
          doctor: { name: dataDoctor.name, lastName: dataDoctor.lastName },
          idClient: selectedClient,
          idDoctor: dataFiltered[0].doctorId,
        };

        let resRequest = await completeRequest(
          dataFiltered[0].idRequest,
          dataToCompleteRequest
        );
        let res = await deactivateChat(selectedChat);
        if (res) {
          Swal.close();
          Swal.fire({
            title: "Chat desactivado",
            text: "Se ha desactivado el chat con el cliente",
            icon: "success",
            confirmButtonColor: "#004860",
            confirmButtonText: "Aceptar",
          })
            .then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            })
            .then(() => {
              if (resRequest) {
                Swal.fire({
                  title: "Sesión finalizada",
                  text: "Se ha finalizado la sesión con el cliente",
                  icon: "success",
                  confirmButtonColor: "#004860",
                  confirmButtonText: "Aceptar",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
              }
            });
        }
        if (!res || !resRequest) {
          Swal.close();
          Swal.fire({
            title: "Error",
            text: "No se ha podido desactivar el chat",
            icon: "error",
            confirmButtonColor: "#004860",
            confirmButtonText: "Aceptar",
          });
        }
      }
    });
  };

  const paperNav = (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}
    >
      {chatData?.map((message, i) => (
        <ListItem
          key={i}
          sx={{
            cursor: "pointer",
            ":hover": { backgroundColor: "rgba(248, 249, 251, 1)" },
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => {
            setDataToMessage(message);
            setChatSelected(true);
            message.viewed = true;
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Avatar
              sx={{
                width: { md: "40px", lg: "48px" },
                height: { md: "40px", lg: "48px" },
                display: "flex",
                flexDirection: "column",
              }}
              src={message.client.imageProfile}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10px",
              }}
            >
              <Typography>
                {`${
                  breakVariable
                    ? `${
                        capitalize(message.client.name) +
                        " " +
                        capitalize(message.client.lastName).slice(0, 10)
                      }...`
                    : capitalize(message.client.name)
                } `}
              </Typography>
              <Typography
                variant="BodyLarge"
                sx={{
                  fontWeight: "normal",
                  color: "RGB(120,120,120)",
                }}
              >
                {formatearRut(message.client.rut)}
              </Typography>
            </div>
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {message.viewed === false
              ? breakVariable && (
                  <Circle sx={{ fontSize: "15px", ml: 3, color: "green" }} />
                )
              : breakVariable && (
                  <ArrowRightIcon sx={{ fontSize: "24px", ml: 3 }} />
                )}
          </div>
        </ListItem>
      ))}
    </List>
  );

  const paperContentHeader = selectedChat && (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "start",
      }}
    >
      <Avatar
        src={dataClient.imageProfile}
        sx={{
          height: { md: "40px", lg: "48px" },
          width: { md: "40px", lg: "48px" },
          mr: 2,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "start",
        }}
      >
        <Typography variant="titleMedium">{`${capitalize(
          dataClient.name
        )} ${capitalize(dataClient.lastName)}`}</Typography>
        <Typography variant="bodyMedium"># {dataClient.rut}</Typography>
      </Box>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            disableChat();
          }}
        >
          Finalizar chat
        </MenuItem>
      </Menu>
    </Box>
  );

  const paperContent = selectedChat ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <CardViewChat
        idChat={selectedChat}
        idClient={selectedClient}
        dataClient={dataClient}
        sx={{
          overFlowY: "auto",
        }}
      />
    </Box>
  ) : (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <img alt="background" src={backgroundCall} style={{ width: "20vw" }} />
      <br />
      <Typography variant="displaySmall" sx={{ color: "#006686" }}>
        Selecciona un chat para ver
      </Typography>
    </Container>
  );

  return (
    <NavPaper
      paperTitle={
        <Typography variant="h6" sx={{ padding: 1 }}>
          Lista de Chats
        </Typography>
      }
      paperNav={paperNav}
      paperContentHeader={paperContentHeader}
      paperContent={paperContent}
      showList={showList}
      setShowList={setShowList}
    />
  );
}
