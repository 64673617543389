import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import AddHoursModal from "./add_hour_modal";
import { getNewSchedule } from "../database/schedules";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";

export default function Schedule() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const myId = useSelector((state) => state.auth.idUser);
  const userData = useSelector((state) => state.auth.userData);
  const [dataOnline, setDataOnline] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  // const handleClick = (info) => {
  //   setSelectedDate(info.dateStr);
  //   setOpenModal(true);
  // };

  const handleClick = (arg) => {
    setSelectedId(null);
    const dateStr = arg.dateStr;
    const selectedDate = new Date(dateStr);

    // Filtrar eventos que tengan la misma fecha (ignorando la hora)
    const isEventExist = schedules.some((event) => {
      const eventDate = event.start; //2024-02-29

      if (eventDate === selectedDate.toISOString().substring(0, 10)) {
        return true;
      } else {
        return false;
      }
    });

    if (isEventExist) {
      Swal.fire({
        icon: "warning",
        title: "¡Atención!",
        text: "Ya hay un horario programado para este día. Presiona sobre el día para editarlo.",
      });
    } else {
      setSelectedDate(arg.dateStr);
      setOpenModal(true);
    }
  };
  useEffect(() => {
    const getData = async () => {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      try {
        let data;
        if (userData.role === "doctor") {
          data = await getNewSchedule(myId);
        } else {
          data = await getNewSchedule(userData.idDoctor);
        }
        // console.log(data);
        setSchedules(data);
      } catch (error) {
        console.error("Error al obtener las solicitudes aceptadas", error);
      }
      Swal.close();
    };
    getData();
  }, []);

  const buttonColors = {
    today: "#006686", // Color del día actual
    week: "#0000ff", // Color del botón de semana
    month: "#0000ff", // Color del botón de mes
  };

  const reloadData = async () => {
    let data;
    if (userData.role === "doctor") {
      data = await getNewSchedule(myId);
    } else {
      data = await getNewSchedule(userData.idDoctor);
    }
    setSchedules(data);
  };

  const handleEventClick = (info) => {
    setSelectedId(info.event.extendedProps.data.id);
    setSelectedDate(info.event.startStr);
    setDataOnline(info.event.extendedProps.data.hours);
    setOpenModal(true);
    schedules.some(
      (event) =>
        event.start ===
        new Date(
          new Date(selectedDate).setDate(new Date(selectedDate).getDate() + 1)
        )
          .toISOString()
          .substring(0, 10)
    );
  };

  const eventRender = (info) => {
    // Obtener el texto del evento
    const eventText = info.event.title;
    // Ajustar el máximo de caracteres permitidos en el div del evento
    const maxCharacters = 20;
    // Truncar el texto si excede el máximo de caracteres
    const truncatedText =
      eventText.length > maxCharacters
        ? eventText.slice(0, maxCharacters) + "..."
        : eventText;
    // Asignar el texto truncado al div del evento
    info.el.innerHTML = truncatedText;
  };

  const getTodayDate = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalizar a las 00:00 horas
    return today;
  };

  const today = getTodayDate(); // Fecha mínima permitida (hoy)
  const maxDate = new Date(today);
  maxDate.setMonth(today.getMonth() + 2); // Fecha máxima permitida (dentro de dos meses)

  return (
    <>
      <Container
        sx={{
          padding: { xs: "8px", sm: "16px", md: "24px", lg: "32px" },
          textTransform: "capitalize",
          borderRadius: "20px",
          width: "100%",
          height: "100%",
        }}
      >
        <FullCalendar
          height={"71vh"}
          dayMaxEvents={2}
          dayMaxEventRows={true}
          eventColor="#006686"
          eventTextColor="#FFF"
          dateClick={handleClick}
          showNonCurrentDates={false}
          aspectRatio={1.5}
          dayHeaderClassNames={"rounded-header"}
          firstDay={1}
          // buttonColors={buttonColors}
          // todayBackgroundColor="#006686"
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            listPlugin,
            timeGridPlugin,
          ]}
          initialView="dayGridMonth"
          weekends={true}
          eventClick={(info) => {
            handleEventClick(info);
          }}
          // eventLimit={true}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          }}
          buttonText={{
            today: "Hoy", // Cambia "Hoy" al texto deseado
            month: "Mes",
            week: "Semana",
            day: "Día",
            list: "Lista",
          }}
          events={schedules}
          locale={"es"}
          validRange={{
            start: today.toISOString().slice(0, 10), // Fecha mínima permitida (hoy)
            end: maxDate.toISOString().slice(0, 10), // Fecha máxima permitida (dentro de un mes)
          }}
        />
      </Container>

      {/* <div
      style={{
        width: "100%",
        height: "10%",
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
        borderTop: "1px solid rgba(0,0,0,0.3)",
      }}
    >
      <Button
        onClick={() => {
        }}

        style={{
          width: "10%",
          backgroundColor: "#006686",
          color: "#77F8E0",
          borderRadius: "25px",
          padding: "10px",
          marginRight: "10px",
          textDecoration: "none",
          textTransform: "none",
        }}
      >
        Guardar
      </Button>
    </div> */}
      {openModal && (
        <AddHoursModal
          open={openModal}
          date={selectedDate}
          hours={dataOnline}
          reloadData={() => {
            reloadData();
          }}
          existsScheduleTomorrow={schedules.some(
            (event) =>
              event.start ===
              new Date(
                new Date(selectedDate).setDate(
                  new Date(selectedDate).getDate() + 1
                )
              )
                .toISOString()
                .substring(0, 10)
          )}
          idDocument={selectedId}
          onClose={() => {
            setOpenModal(false);
            setDataOnline([]);
          }}
        />
      )}

      {/* <NewHourModal open={openModal} date={selectedDate} onClose={() => {
      setOpenModal(false)
    }} /> */}
    </>
  );
}
