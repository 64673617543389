import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material";
import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../database/config";
import { useNavigate } from "react-router-dom";
// import { getDoctorById } from "../database/doctors";
// import { getSecretaryById } from "../database/secretaries";
import { getUserById } from "../database/users";
import loadingSVG from "../assets/logo_Loading.gif";

import logoOtherColor from "../assets/logo_light.svg";
import Swal from "sweetalert2";

import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
//import DocsDraw from "../assets/docs_draw.svg";

import { useDispatch } from "react-redux";
import { login } from "../actions/auth_actions";

export default function Login() {
  const dispatch = useDispatch();

  const handleLogin = (userData, userId) => {
    dispatch(login(userData, userId));
  };

  // const [openError, setOpenError] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  // const [error, setError] = useState("");
  const [password, setPassword] = useState("");

  const onLogin = async (e) => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    e.preventDefault();
    // console.log(email, password);
    // setError("");
    try {
      // console.log("auth", auth, email, password);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email.trim(),
        password
      );
      // console.log("userCredential", userCredential);
      const user = userCredential.user;
      // console.log("user", user);
      // if (user.emailVerified) {
      // var resDoctor = await getDoctorById(user.uid);
      const resUser = await getUserById(user.uid);
      // console.log("resUser", resUser);

      handleLogin(resUser, user.uid);
      if (resUser.role === "doctor") {
        if (resUser.firstTime === true) {
          navigate("/completeProfile");
          Swal.close();
        } else {
          navigate("/home");
          Swal.close();
        }
      } else if (resUser.role === "medicalCenter") {
        // console.log("resUser", resUser);
        if (resUser.firstTime === true) {
          navigate("/completeCenterMed");
          Swal.close();
        } else {
          navigate("/");
          Swal.close();
        }
      }
      if (resUser.role === "secretary") {
        navigate("/home");
        Swal.close();
      } else if (resUser.role === "admin") {
        navigate("/homeAdmin");
        Swal.close();
      }
      // } else {
      // setOpenError(true);
      // Swal.close();
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Oops...',
      //   text: "Debes verificar tu correo para poder ingresar. Si no lo encuentras revisa tu bandeja de spam."
      //   ,
      // })
      // }
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Contraseña incorrecta. Verifica tus credenciales.",
        });
      } else if (error.code === "auth/too-many-requests") {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Demasiados intentos fallidos. Inténtalo después.",
        });
      } else {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Correo no encontrado. Verifica tus datos.",
        });
      }
      // setError(error)
    }
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#001E2B",
        height: "100vh",
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={11} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography component="h2" variant="h5" sx={{ color: "#CDE5FF" }}>
              Ingresa a
            </Typography>
            <Box
              component="img"
              src={logoOtherColor}
              sx={{ width: "180px", height: "50px", marginTop: 1 }}
            />
          </Box>
          <ThemeProvider theme={darkTheme}>
            <Box
              component="form"
              noValidate
              onSubmit={onLogin}
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                sx={{ mb: 2 }}
                margin="normal"
                required
                fullWidth
                placeholder="Correo"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                variant="filled"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                  style: {
                    padding: "3%",
                  },
                }}
                inputProps={{
                  style: {
                    height: "90%",
                  },
                }}
              />
              <TextField
                sx={{ mb: 2 }}
                margin="normal"
                required
                fullWidth
                placeholder="Contraseña"
                type="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                variant="filled"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                  style: {
                    padding: "3%",
                  },
                }}
                inputProps={{
                  style: {
                    height: "90%",
                  },
                }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  height: "48px",
                  background: "#006686",
                  color: "#77F8E0",
                  borderRadius: "25px",
                  ":hover": { background: "#77F8E0", color: "#006686" },
                }}
              >
                Iniciar sesión
              </Button>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Link
                    style={{ textDecoration: "none" }}
                    href="/recoverPass"
                    variant="body2"
                    sx={{ color: "#E1E0FF" }}
                  >
                    ¿Olvidaste tu cuenta?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </ThemeProvider>
        </Grid>
      </Grid>
    </Box>
  );
}
