import { Container } from '@mui/material'
import load from '../assets/logo_Loading.gif'
import Dialog from "@mui/material/Dialog";
export default function Loading({loadingOn,loadingOff}) {

  return (
    <Container style={{
      width: '100%',
    }} >
       <Dialog open={loadingOn} onClose={loadingOff}>
        <img src={load} alt="loading" style={{width:'20vw'}} />
        <h3 style={{textAlign:'center'}}>Cargando...</h3>
    </ Dialog >
    </Container>
  )
}
