import React, { createContext, useContext } from 'react';

const PaddingContext = createContext();

export const usePadding = () => useContext(PaddingContext);

export const PaddingProvider = ({ children, padding }) => {
  return (
    <PaddingContext.Provider value={padding}>
      {children}
    </PaddingContext.Provider>
  );
};
