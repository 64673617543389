import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Avatar,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Grid,
  Divider,
  Box,
} from "@mui/material";
import {
  HomeTwoTone,
  CalendarMonthTwoTone,
  RoomServiceTwoTone,
  MessageTwoTone,
  VideocamTwoTone,
  SourceTwoTone,
  SettingsTwoTone,
  SupervisorAccount,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowDown,
  CheckCircleTwoTone,
  LogoutRounded,
  AccountBalanceTwoTone,
} from "@mui/icons-material";
import GroupsIcon from "@mui/icons-material/Groups";
import { SupervisedUserCircleTwoTone } from "@mui/icons-material";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/auth_actions";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
import Logo from "../assets/logo.svg";
import FireNav from "./fire_nav";
import { signOut } from "firebase/auth";
import { auth } from "../database/config";
import { capitalizeAll } from "../utils/functions";
import { getCenterMedicalDoctors } from "../database/doctors"; // Importar getCenterMedicalDoctors
import { getChatsUnread } from "../database/chats"; // Importar getChatsUnread
import DialogSelectDoctor from "../components/dialog_select_doctor";
import userMaleImage from "../assets/user_male_light.png";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";

const MyDrawer = ({ mobileOpen, handleDrawerToggle, drawerWidth }) => {
  const dataDoctor = useSelector((state) => state.auth.userData);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [chatsUnread, setChatsUnread] = useState(0);
  const [openAtention, setOpenAtention] = useState(true);
  const [arrayDataDoctors, setArrayDataDoctors] = useState([]);
  const [viewDoctors, setViewDoctors] = useState(false);
  const [doctorSelected, setDoctorSelected] = useState(false);

  const [openDoctorSelector, setOpenDoctorSelector] = useState(false);
  const handleCloseDoctorSelector = () => {
    setOpenDoctorSelector(false);
  };
  const myData = useSelector((state) => state.auth.userData);
  const isSelected = location.pathname === "/chatList";

  const handleChangeView = (prop) => {
    if (
      dataDoctor &&
      myData &&
      prop === "/home" &&
      myData.role === "medicalCenter"
    ) {
      navigate("/homeMedicalCenter");
    } else {
      navigate(prop);
    }
  };

  const handleViewSecretari = (e) => {
    if (e.firstTime) {
      Swal.fire({
        title: "¡Atención!",
        text: "El doctor no ha completado su información, por favor, espere a que lo haga.",
        icon: "warning",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#006686",
      });
    } else {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      if (userData.idDoctor === e.id) {
        Swal.close();
        Swal.fire({
          title: "¡Opss..!",
          text: "Ya se encuentra seleccionado este profesional.",
          icon: "info",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#006686",
        });
      } else {
        const updatedUserData = {
          ...userData,
          idDoctor: e.id,
          userSelected: e,
        };

        dispatch({
          type: "UPDATE_USER_DATA",
          payload: updatedUserData,
        });
        Swal.close();
        Swal.fire({
          title: "¡Éxito!",
          text: "Se ha cambiado de profesional correctamente.",
          icon: "success",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#006686",
        }).then(() => {
          window.location.reload(true);
        });
      }
    }
  };

  const handleAtentionClick = () => {
    setOpenAtention(!openAtention);
  };

  useEffect(() => {
    async function getData() {
      setUserData(dataDoctor);
    }
    getData();

    async function getdoctorArray() {
      if (userData?.medicalCenter) {
        const res = await getCenterMedicalDoctors(userData.medicalCenter);
        setArrayDataDoctors(res);
      }
    }

    if (userData?.role === "secretary" && userData?.medicalCenter) {
      getdoctorArray();
    }
    if (
      dataDoctor?.role === "secretary" &&
      dataDoctor?.idDoctor === dataDoctor?.medicalCenter
    ) {
      setOpenDoctorSelector(true);
    }

    async function getMessages() {
      if (myData?.id) {
        const noRead = await getChatsUnread(myData.id);
        setChatsUnread(noRead);
      }
    }

    getMessages();
  }, [dataDoctor, userData, myData]);

  const textLenght = (e) => {
    if (e.length > 13) {
      return e.slice(0, 18) + "...";
    } else {
      return e;
    }
  };

  const handleChangeDoctor = () => {
    if (myData?.role === "secretary" && myData?.medicalCenter) {
      setViewDoctors(!viewDoctors);
    }
  };

  const handleLogout = () => {
    try {
      dispatch(logout());
      signOut(auth)
        .then(() => {
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error signing out:", error);
        });
    } catch (error) {
      console.log("Error signing out:", error);
    }
  };

  const drawerContent = (
    <Container
      disableGutters={true}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "center",
        overflow: "auto",
        overflowX: "hidden",
        padding: "16px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <a href="/home" style={{ width: "90%", padding: "0px" }}>
          <img
            src={Logo}
            alt="Logo"
            width="100%"
            style={{ cursor: "pointer", width: { md: "40px", lg: "3vw" } }}
          />
        </a>
      </div>
      <br />
      {dataDoctor && (
        <div
          onClick={() =>
            dataDoctor.role === "secretary" &&
            dataDoctor.medicalCenter &&
            handleChangeDoctor()
          }
          style={{
            backgroundColor: "primary",
            color: "primary",
            border: "1px solid rgba(192, 199, 205, 1)",
            borderRadius: "25px",
            width: "103%",
            marginBottom: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "12px 16px 12px 16px",
          }}
          className="userDrawer"
        >
          {viewDoctors && <KeyboardArrowLeft />}
          <Avatar
            alt="Remy Sharp"
            sx={{
              border: "2px solid rgba(192, 199, 205, 1)",
              width: "56px",
              height: "56px",
              marginRight: "16px",
            }}
            src={
              dataDoctor.role === "doctor"
                ? userData?.imageProfile
                : dataDoctor.role === "secretary" && userData?.userSelected
                ? userData.userSelected?.imageProfile
                : dataDoctor.role === "medicalCenter"
                ? userData?.imageProfile
                : dataDoctor.role === "admin"
                ? userMaleImage
                : userData?.image
            }
          />
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Typography variant="titleMedium" sx={{ width: "100%" }}>
              {dataDoctor?.role === "doctor"
                ? capitalizeAll("Dr." + " " + userData?.lastName)
                : dataDoctor.role === "secretary" && userData?.userSelected
                ? "Dr." + " " + userData.userSelected?.lastName
                : dataDoctor.role === "medicalCenter"
                ? textLenght(`${capitalizeAll(userData?.name)}`)
                : dataDoctor.role === "admin" &&
                  textLenght(`${capitalizeAll(userData?.name)}`)}
            </Typography>
            <Typography
              variant="bodyMedium"
              style={{ width: "100%", textAlign: "start", color: "primary" }}
            >
              {dataDoctor.role === "doctor"
                ? "Doctor"
                : dataDoctor.role === "secretary" && userData?.userSelected
                ? userData.userSelected?.mainSpecialty
                : dataDoctor.role === "medicalCenter"
                ? "Centro médico"
                : dataDoctor.role === "admin"
                ? "Administrador"
                : "Secretaria"}
            </Typography>
          </div>
          {!viewDoctors && <KeyboardArrowDown />}
        </div>
      )}
      {viewDoctors ? (
        <FireNav sx={{ width: "100%" }} className="arrayDoctorsContainer">
          <ListItem sx={{ width: "100%", display: "flex", flex: "start" }}>
            <ListItemText
              primary="Profesionales"
              primaryTypographyProps={{
                color: "#006686",
                fontSize: "17px",
              }}
            />
          </ListItem>
          {arrayDataDoctors.length > 0 &&
            arrayDataDoctors.map((doctor, index) => (
              <ListItem
                button
                key={index}
                sx={{
                  backgroundColor: "#F3F4F6",
                  border: "1px solid rgba(192, 199, 205, 1)",
                  borderRadius: "25px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleViewSecretari(doctor)}
              >
                <Avatar src={doctor.imageProfile ? doctor.imageProfile : ""}>
                  {doctor.name.charAt(0) + doctor.lastName.charAt(0)}
                </Avatar>
                <ListItemText
                  primary={doctor.name}
                  secondary={
                    doctor.mainSpecialty
                      ? doctor.mainSpecialty
                      : "Sin especialidad"
                  }
                  sx={{ marginLeft: "10px" }}
                />
                <KeyboardArrowRight />
              </ListItem>
            ))}
        </FireNav>
      ) : (
        <>
          {userData?.role === "admin" && (
            <Grid container spacing={0} columns={1} sx={{ width: "100%" }}>
              <Grid item xs={12} sm={4}>
                <FireNav>
                  <ListItem
                    button
                    selected={location.pathname === "/homeAdmin"}
                    onClick={() => handleChangeView("/homeAdmin")}
                  >
                    <ListItemIcon>
                      <GroupsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Profesionales" />
                  </ListItem>
                </FireNav>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FireNav>
                  <ListItem
                    button
                    selected={location.pathname === "/MedicalCenterAdmin"}
                    onClick={() => handleChangeView("/MedicalCenterAdmin")}
                  >
                    <ListItemIcon>
                      <SupervisedUserCircleTwoTone />
                    </ListItemIcon>
                    <ListItemText primary="Centro medico" />
                  </ListItem>
                </FireNav>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FireNav>
                  <ListItem
                    button
                    selected={location.pathname === "/requestsAdmin"}
                    onClick={() => handleChangeView("/requestsAdmin")}
                  >
                    <ListItemIcon>
                      <CheckCircleTwoTone />
                    </ListItemIcon>
                    <ListItemText primary="Solicitudes" />
                  </ListItem>
                </FireNav>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FireNav>
                  <ListItem
                    button
                    selected={location.pathname === "/adminBankRequest"}
                    onClick={() => handleChangeView("/adminBankRequest")}
                  >
                    <ListItemIcon>
                      <AccountBalanceOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="cambio de datos bancarios" />
                  </ListItem>
                </FireNav>
              </Grid>
            </Grid>
          )}
          {myData !== null && (
            <Grid container spacing={0} columns={1} sx={{ width: "100%" }}>
              {myData.role !== "admin" && (
                <Grid item xs={12} sm={4}>
                  <FireNav>
                    <ListItem
                      button
                      selected={location.pathname === "/home"}
                      onClick={() => handleChangeView("/home")}
                    >
                      <ListItemIcon>
                        <HomeTwoTone />
                      </ListItemIcon>
                      <ListItemText primary="Inicio" />
                    </ListItem>
                  </FireNav>
                </Grid>
              )}

              {myData?.role !== "medicalCenter" && myData.role !== "admin" && (
                <>
                  <Grid item xs={12} sm={4}>
                    <FireNav>
                      <ListItem
                        button
                        selected={location.pathname === "/calendar"}
                        onClick={() => handleChangeView("/calendar")}
                      >
                        <ListItemIcon>
                          <CalendarMonthTwoTone />
                        </ListItemIcon>
                        <ListItemText primary={"Agenda"} />
                      </ListItem>
                    </FireNav>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FireNav>
                      <ListItem
                        button
                        selected={location.pathname === "/reserves"}
                        onClick={() => handleChangeView("/reserves")}
                      >
                        <ListItemIcon>
                          <RoomServiceTwoTone />
                        </ListItemIcon>
                        <ListItemText primary={"Reservas"} />
                      </ListItem>
                    </FireNav>
                  </Grid>
                </>
              )}

              {myData?.role === "medicalCenter" && (
                <Grid item xs={12} sm={4}>
                  <FireNav>
                    <ListItem
                      button
                      selected={location.pathname === "/professionals"}
                      onClick={() => handleChangeView("/professionals")}
                    >
                      <ListItemIcon>
                        <SupervisorAccount />
                      </ListItemIcon>
                      <ListItemText primary="Profesionales" />
                    </ListItem>
                  </FireNav>
                </Grid>
              )}
            </Grid>
          )}

          {userData?.role === "doctor" && (
            <div
              style={{
                marginTop: "2vh",
                border: "1px solid rgba(192, 199, 205, 1)",
                borderRadius: "20px",
                padding: "10px",
                width: "100%",
              }}
            >
              <div
                onClick={handleAtentionClick}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                  marginBottom: "8px",
                }}
              >
                <p
                  style={{
                    textAlign: "start",
                    margin: "0px",
                    padding: "10px 20px",
                    fontSize: "0.9em",
                    fontWeight: "bold",
                    color: "#006686",
                  }}
                >
                  Atención
                </p>
              </div>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <FireNav>
                  <ListItem
                    button
                    selected={location.pathname === "/chatList"}
                    onClick={() => handleChangeView("/chatList")}
                  >
                    <ListItemIcon>
                      <MessageTwoTone />
                    </ListItemIcon>
                    <ListItemText primary={"Mensajes"} />
                    {chatsUnread > 0 && (
                      <span
                        className="badge"
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "4px",
                          backgroundColor: isSelected ? "#77F8E0" : "#006686",
                          color: isSelected ? "#006686" : "#77F8E0",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "0.90rem",
                          lineHeight: "1.45rem",
                          fontWeight: "700",
                          marginRight: "6px",
                        }}
                      >
                        {chatsUnread}
                      </span>
                    )}
                  </ListItem>

                  <ListItem
                    button
                    selected={location.pathname === "/videocalls"}
                    onClick={() => handleChangeView("/videocalls")}
                  >
                    <ListItemIcon>
                      <VideocamTwoTone />
                    </ListItemIcon>
                    <ListItemText primary={"Telemedicina"} />
                  </ListItem>

                  <ListItem
                    button
                    selected={location.pathname === "/medicalRecords"}
                    onClick={() => handleChangeView("/medicalRecords")}
                  >
                    <ListItemIcon>
                      <SourceTwoTone />
                    </ListItemIcon>
                    <ListItemText primary={"Fichas atención"} />
                  </ListItem>
                </FireNav>
              </Box>
            </div>
          )}
        </>
      )}

      <div style={{ height: "100%" }}></div>

      <Grid container spacing={0} style={{ marginTop: "3vh" }}>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <FireNav>
            <ListItem
              component="a"
              href="https://espacio.haulmer.com"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ backgroundColor: "transparent", color: "#006686" }}
            >
              <ListItemIcon>
                <AccountBalanceTwoTone sx={{ color: "#006686" }} />
              </ListItemIcon>
              <ListItemText primary={"Espacio Haulmer"} />
            </ListItem>
          </FireNav>
        </Grid>
        {dataDoctor?.role !== "admin" && (
          <Grid item xs={12}>
            <FireNav>
              <ListItem
                button
                selected={location.pathname === "/settings"}
                onClick={() => handleChangeView("/settings")}
              >
                <ListItemIcon>
                  <SettingsTwoTone />
                </ListItemIcon>
                <ListItemText primary={"Configuración"} />
              </ListItem>
            </FireNav>
          </Grid>
        )}
        <Grid item xs={12}>
          <FireNav>
            <ListItem
              button
              onClick={handleLogout}
              sx={{
                color: "#BA1A1A",
                borderRadius: "10px",
              }}
            >
              <ListItemIcon>
                <LogoutRounded sx={{ color: "#BA1A1A" }} />
              </ListItemIcon>
              <ListItemText primary={"Cerrar sesión"} />
            </ListItem>
          </FireNav>
        </Grid>
      </Grid>
    </Container>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", xl: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            borderRadius: "0 20px 20px 0",
          },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            borderRadius: "0 20px 20px 0",
          },
        }}
        open
      >
        {drawerContent}
      </Drawer>
      <DialogSelectDoctor
        open={openDoctorSelector}
        close={handleCloseDoctorSelector}
        data={arrayDataDoctors}
        userData={userData}
      />
    </>
  );
};

MyDrawer.propTypes = {
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  drawerWidth: PropTypes.number.isRequired,
};

export default MyDrawer;
