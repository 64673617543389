import { db } from "./config";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { getUserById } from "./users";
import axios from "axios";
import { getTokenQueue } from "./request";

export async function createNotification(
  notificationData,
  clientId,
  doctorId,
  idRequest,
  type
) {
  console.log(
    "Creando notificación",
    notificationData,
    clientId,
    doctorId,
    idRequest,
    type
  );
  try {
    // console.log("Creando notificación2");
    const notificationsCollectionRef = collection(
      db,
      "notifications",
      clientId,
      "notifications"
    );
    await addDoc(notificationsCollectionRef, {
      type: notificationData.type,
      title: notificationData.title,
      message: notificationData.body,
      doctorId: doctorId,
      idRequest: idRequest,
      viewed: false,
      timestamp: new Date(),
    });
    await sendNotificationFCM(
      {
        title: notificationData.title,
        body: notificationData.body,
      },
      clientId
    );
    return true;
  } catch (error) {
    console.log("Error al crear notificación", error);
    return false;
  }
}

export async function createMultiNotification(notificationData, mainSpecialty, doctorId, type) {
  const now = new Date();
  try {
    const queueList = await getTokenQueue(
      mainSpecialty,
      //2020/09/12
      now.toISOString().substring(0, 10)
    );
    queueList.forEach(async (user) => {
      const notificationsCollectionRef = collection(
        db,
        "notifications",
        user.id,
        "notifications"
      );
      await addDoc(notificationsCollectionRef, {
        type: type,
        title: notificationData.title,
        message: notificationData.body,
        doctorId: doctorId,
        viewed: false,
        timestamp: new Date(),
      });
      await sendNotiFCMWithToken(
        {
          title: notificationData.title,
          body: notificationData.body,
        },
        user.token,
        type
      );
    });
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function sendNotiFCMWithToken(dataNotification, token, type) {
  const key =
    "AAAAAE6FPJ8:APA91bF3MFZUJiyQ46ZG6ScQ1GNM8uYjgh9Z1yj3GnhIvw4p4PInoL6HBtD8ys6FCweD5jm-mNKWhwo7VTtF6eIOrvi24B4P9d8YprSDb_u3G_Kc6ArT_S7FJ4Qvc43NU-0-ZPEmGMNi";
  const notification = {
    title: dataNotification.title,
    body: dataNotification.body,
  };

  await axios.post(
    "https://fcm.googleapis.com/fcm/send",
    {
      notification: notification,
      data: { type: type },
      priority: "high",
      to: token,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `key=${key}`,
      },
    }
  );
}

export async function sendNotificationFCM(dataNotification, clientId, type) {
  const userData = await getUserById(clientId);
  const key =
    "AAAAAE6FPJ8:APA91bF3MFZUJiyQ46ZG6ScQ1GNM8uYjgh9Z1yj3GnhIvw4p4PInoL6HBtD8ys6FCweD5jm-mNKWhwo7VTtF6eIOrvi24B4P9d8YprSDb_u3G_Kc6ArT_S7FJ4Qvc43NU-0-ZPEmGMNi";
  const notification = {
    title: dataNotification.title,
    body: dataNotification.body,
  };

  const res = await axios.post(
    "https://fcm.googleapis.com/fcm/send",
    {
      notification: notification,
      data: { type: type },
      priority: "high",
      to: userData.token,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `key=${key}`,
      },
    }
  );
}

export async function getMyNotifications(myId, setData) {
  const queryA = query(
    collection(db, "notifications", myId, "notifications"),
    orderBy("createdAt", "asc")
  );
  const unsubscribe = onSnapshot(queryA, (querySnapshot) => {
    const updatedData = [];

    querySnapshot.forEach((doc) => {
      updatedData.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    setData(updatedData);
    // console.log(updatedData);
  });

  return () => unsubscribe();
}
