import { db } from "./config";
import {
  collection,
  onSnapshot,
  query,
  getDocs,
  where,
  updateDoc,
  doc,
  orderBy,
  addDoc,
  limit,
} from "firebase/firestore";
import { createNotification } from "./notifications";
import { capitalize } from "../utils/functions";
import { getDoctorById } from "./doctors";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";

import {
  addMinutes,
  endOfDay,
  format,
  isToday,
  startOfDay,
  subDays,
} from "date-fns";

export async function updateRequestStatus(idRequest, newStatus, data) {
  // console.log("updateRequestStatus entre entre entre entre entre");
  // Id
  try {
    const requestRef = doc(db, "requests", idRequest);
    await updateDoc(requestRef, {
      status: newStatus,
    });
    if (newStatus === "clientAbsent" && data.idClient !== "forPage") {
      await createNotification(
        {
          type: "clientAbsent",
          title: "No llegaste a tu reserva",
          body: `El doctor ${capitalize(data.doctor.name)} ${capitalize(
            data.doctor.lastName
          )} ha indicado que no fuiste. Si ocurre varias veces se te penalizará. Revisa los detalles en "Mis solicitudes".`,
        },
        data.idClient,
        data.idDoctor,
        idRequest,
        "statusRequest"
      );
    } else if (newStatus === "waiting" && data.idClient !== "forPage") {
      await createNotification(
        {
          type: "clientPresent",
          title: "En espera de exámenes",
          body: `El doctor ${capitalize(data.doctor.name)} ${capitalize(
            data.doctor.lastName
          )} está esperando tus exámenes. Revisa los detalles en "Mis solicitudes".`,
        },
        data.idClient,
        data.idDoctor,
        idRequest,
        "statusRequest"
      );
    }

    // console.log("hecho", updateDoc);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
export async function updateRequestStatusComplete(
  idRequest,
  newStatus,
  complete,
  data
) {
  try {
    const requestRef = doc(db, "requests", idRequest);
    // console.log("complete", complete);
    await updateDoc(requestRef, {
      status: newStatus,
      "request.diagnosis": complete.diagnostico,
      "request.treatment": complete.tratamiento,
    });
    await createNotification(
      {
        type: "requestCompleted",
        title: "Reserva concluida",
        body: `Tu reserva con el doctor ${capitalize(
          data.doctor.name
        )} ${capitalize(
          data.doctor.lastName
        )} ha sido concluida. Revisa los detalles en "Mis solicitudes".`,
      },
      data.idClient,
      data.idDoctor,
      idRequest,
      "statusRequest"
    );
    // console.log("hecho");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateRequestForCall(
  idRequest,
  idClient,
  idDoctor,
  dataDoctor,
  token
) {
  try {
    // console.log("loggg ", idRequest, token);
    const requestRef = doc(db, "requests", idRequest);
    // console.log("complete", complete);
    await updateDoc(requestRef, {
      "request.token": token,
    });
    await createNotification(
      {
        type: "enterCall",
        title: "Telemedicina iniciada",
        body: `El doctor ${capitalize(dataDoctor.name)} ${capitalize(
          dataDoctor.lastName
        )} ha iniciado la llamada. ¡Entra pronto!`,
      },
      idClient,
      idDoctor,
      idRequest,
      "videoCall"
    );
    // console.log("hecho");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getRequestById(idRequest) {
  // const docRef = doc(db, "requests", idRequest);
  const queryData = query(
    collection(db, "requests"),
    doc(db, "requests", idRequest)
  );
  const docSnap = await getDocs(queryData);
  // return docSnap.data();
  return docSnap.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }))[0];
}

export async function getMyRequests(idUser) {
  try {
    // console.log(idUser);
    const queryData = query(
      collection(db, "requests"),
      where("status", "==", "pending"),
      where("idDoctor", "==", idUser),
      orderBy("request.date", "asc") //revisar
    );
    const types = await getDocs(queryData);
    // console.log("tipos", types.docs);
    return types.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getMyRequestsByDayAndType(date, idDoctor, type) {
 
  let queryData;
  if (type === "por atender") {
    console.log("Entra a por atender");
    queryData = query(
      collection(db, "requests"),
      where("status", "in", ["pending", "waiting"]),
      where("request.payData.status", "==", "payed"),
      where("idDoctor", "==", idDoctor),
      where("request.date", "==", date)
    );
  } else if (type === "pendiente de pago") {
    console.log("Entra a pendientes de pago");
    queryData = query(
      collection(db, "requests"),
      where("status", "==", "accepted"),
      where("request.payData.status", "==", "pending"),
      where("idDoctor", "==", idDoctor),
      where("request.date", "==", date)
    );
  } else if (type === "canceladas") {
    console.log("Entra a canceladas");
    queryData = query(
      collection(db, "requests"),
      where("status", "in", ["canceledByClient", "canceledByDoctor"]),
      where("idDoctor", "==", idDoctor),
      where("request.date", "==", date)
    );
  } else if (type === "completadas") {
    console.log("Entra a completadas");
    queryData = query(
      collection(db, "requests"),
      where("status", "in", ["completed", "waiting"]),
      where("idDoctor", "==", idDoctor),
      where("request.date", "==", date)
    );
  } else if (type === "ausentes") {
    console.log("Entra a completadas");
    queryData = query(
      collection(db, "requests"),
      where("status", "==", "clientAbsent"),
      where("idDoctor", "==", idDoctor),
      where("request.date", "==", date)
    );
  }

  const types = await getDocs(queryData);
  // console.log("tipos", types.docs);
  // console.log("tipos", types.docs);
  return types.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

export async function getMyRequestsByDay(date, idDoctor) {
  // console.log("Buscando por fecha", date, idDoctor);
  const queryData = query(
    collection(db, "requests"),
    // where("status", "==", "accepted"),
    where("idDoctor", "==", idDoctor),
    where("request.date", "==", date),
    orderBy("dateCitation", "asc") //revisar
  );
  const types = await getDocs(queryData);

  return types.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}
export async function getMyRequestsByDayAndDate(idDoctor, hour, date) {
  const queryData = query(
    collection(db, "requests"),
    where("idDoctor", "==", idDoctor),
    where("request.date", "==", date),
    where("request.hour", "==", hour) //revisar
  );
  const types = await getDocs(queryData);
  return types.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

export async function getMyRequestsByDayForChart(date, idDoctor) {
  // console.log("Buscando por fecha", date, idDoctor);
  const queryData = query(
    collection(db, "requests"),
    where("status", "in", ["completed", "waiting"]),
    where("idDoctor", "==", idDoctor),
    where("request.date", "==", date),
    orderBy("dateCitation", "asc") //revisar
  );
  const types = await getDocs(queryData);

  return types.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

export function subscribeToMyAcceptedRequests(idUser, callback) {
  const queryData = query(
    collection(db, "requests"),
    where("status", "==", "accepted"),
    where("idDoctor", "==", idUser)
  );

  try {
    const unsubscribe = onSnapshot(queryData, (snapshot) => {
      const requests = [];
      snapshot.forEach((doc) => {
        const requestData = doc.data().request;
        const dateParts = requestData.date.split("-");
        const hourParts = requestData.hour.split(":");

        let startDate = new Date(
          Date.UTC(
            parseInt(dateParts[0]),
            parseInt(dateParts[1]) - 1,
            parseInt(dateParts[2]),
            parseInt(hourParts[0]),
            parseInt(hourParts[1])
          )
        );
        const endDate = addMinutes(startDate, 1);
        startDate.setHours(startDate.getHours() + 4);
        endDate.setHours(endDate.getHours() + 4);

        requests.push({
          id: doc.id,
          title:
            capitalize(doc.data().client.name) +
            " " +
            capitalize(doc.data().client.lastName),
          start: startDate,
          end: endDate,
          data: { id: doc.id, ...doc.data() },
          color:
            doc.data().request.payData.status === "pending"
              ? "orange"
              : "green",
        });
      });
      callback(requests);
    });

    return unsubscribe;
  } catch (e) {
    console.log("unsubscribe", e);
  }
}
// onSnapshot
export async function getMyAcceptedRequests(idUser) {
  // console.log("Buscando por id", idUser);
  const queryData = query(
    collection(db, "requests"),
    where("status", "==", "accepted"),
    where("idDoctor", "==", idUser)
  );
  const types = await getDocs(queryData);

  return types.docs.map((doc) => {
    const requestData = doc.data().request;
    const dateParts = requestData.date.split("-");
    const hourParts = requestData.hour.split(":");

    // Construye la fecha de inicio en formato UTC
    let startDate = new Date(
      Date.UTC(
        parseInt(dateParts[0]),
        parseInt(dateParts[1]) - 1, // Los meses en JavaScript son 0-indexados
        parseInt(dateParts[2]),
        parseInt(hourParts[0]),
        parseInt(hourParts[1])
      )
    );

    // Suma 30 minutos para obtener la fecha de finalización
    // let endDate = new Date(startDate);
    // endDate.setMinutes(endDate.getMinutes() + 1);
    const endDate = addMinutes(startDate, 1);
    // Ajusta la hora de inicio según la diferencia de UTC de Chile (+3)
    startDate.setHours(startDate.getHours() + 4);
    endDate.setHours(endDate.getHours() + 4);
    // Ajusta la hora de finalización según la diferencia de UTC de Chile (+3)
    // endDate.setHours(endDate.getHours() + 3);

    return {
      id: doc.id,
      title:
        capitalize(doc.data().client.name) +
        " " +
        capitalize(doc.data().client.lastName),
      start: startDate,
      end: endDate,
      data: { id: doc.id, ...doc.data() },
      color:
        doc.data().request.payData.status === "pending" ? "orande" : "green",
    };
  });
}

export async function acceptRequest(idRequest, data) {
  // Id
  try {
    const requestRef = doc(db, "requests", idRequest);
    await updateDoc(requestRef, {
      status: "accepted",
    });
    await createNotification(
      {
        type: "requestAccepted",
        title: "Solicitud aceptada",
        body: `Tu solicitud para el doctor ${capitalize(
          data.doctor.name
        )} ${capitalize(
          data.doctor.lastName
        )} ha sido aceptada. Revisa los detalles en "Mis solicitudes".`,
      },
      data.idClient,
      data.idDoctor,
      idRequest,
      "statusRequest"
    );
    // console.log("hecho");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function payRequestPOS(idRequest, data, dataPay) {
  try {
    const requestRef = doc(db, "requests", idRequest);
    await updateDoc(requestRef, {
      "request.payData.status": "payed",
      "request.payData.payDate": new Date().toISOString(),
      "request.payData.dataPay": dataPay,
    });

    await createNotification(
      {
        type: "requestPayed",
        title: "Pago aceptado",
        body: `Tu pago para el doctor ${capitalize(
          data.doctor.name
        )} ${capitalize(
          data.doctor.lastName
        )} ha sido aceptado. Revisa los detalles en "Mis solicitudes".`,
      },
      data.idClient,
      data.idDoctor,
      idRequest,
      "payAccepted"
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function payRequest(idRequest, data, code, payMethod, payType) {
  try {
    const requestRef = doc(db, "requests", idRequest);
    if (data.request.payData.typePay === "Particular") {
      await updateDoc(requestRef, {
        "request.payData.status": "payed",
        "request.payData.payMethod": payMethod,
        "request.payData.payDate": new Date().toISOString(),
      });
    } else {
      await updateDoc(requestRef, {
        "request.payData.status": "payed",
        "request.payData.code": code,
        "request.payData.payDate": new Date().toISOString(),
      });
    }
    await createNotification(
      {
        type: "requestPayed",
        title: "Pago aceptado",
        body: `Tu pago para el doctor ${capitalize(
          data.doctor.name
        )} ${capitalize(
          data.doctor.lastName
        )} ha sido aceptado. Revisa los detalles en "Mis solicitudes".`,
      },
      data.idClient,
      data.idDoctor,
      idRequest,
      "payAccepted"
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function declineRequest(idRequest, reason) {
  // Id y razon
  try {
    const requestRef = doc(db, "requests", idRequest);
    await updateDoc(requestRef, {
      status: "declined",
      reason: reason,
    });
    // console.log("hecho");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function completeRequest(idRequest, data) {
 

  try {
    const requestRef = doc(db, "requests", idRequest);
    await updateDoc(requestRef, {
      status: "completed",
    });
    await createNotification(
      {
        type: "completed",
        title: "Atención concluida",
        body: `Tu solicitud para el doctor ${capitalize(
          data.doctor.name
        )} ${capitalize(
          data.doctor.lastName
        )} ha sido finalizada. Revisa los detalles en el panel de tu cuenta.`,
      },
      data.idClient,
      data.idDoctor,
      idRequest,
      "statusRequest"
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const getTokenQueue = async (type, date) => {
  // console.log("Buscando por fecha", type, date);
  try {
    const q = query(
      collection(db, "queues"),
      where("type", "==", type),
      where("date", "==", date),
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    let tokens = [];
    if (!querySnapshot.empty) {
      querySnapshot.docs[0].data().waiting.forEach((user) => {
        tokens.push({ token: user.token, id: user.id });
      });
      return tokens;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export async function updateHourStatusToFree(doctorId, day, hour) {
  // console.log("Buscando, day, hour", doctorId, day, hour);
  try {
    const q = query(
      collection(doc(db, "schedules", doctorId), "schedules"),
      where("day", "==", day)
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const scheduleDoc = querySnapshot.docs[0];
      const scheduleData = scheduleDoc.data();
      const hoursList = scheduleData.hours;

      // Buscar la hora específica en la lista de horas
      for (let i = 0; i < hoursList.length; i++) {
        if (hoursList[i].time === hour && hoursList[i].status === "taked") {
          // Actualizar el estado de la hora a 'free'
          hoursList[i].status = "free";

          // Actualizar el documento en Firestore con la hora modificada
          await updateDoc(scheduleDoc.ref, {
            hours: hoursList,
          });

          // Indicar que la hora ha sido actualizada
          // console.log(`Hora actualizada del horario del doctor ${doctorId}`);
          return true; // Terminar la función
        }
      }
    } else {
      // Indicar que el documento no se encontró
      // console.log(`No se encontró el documento para el día ${day}`);
      return false; // Terminar la función
    }
  } catch (error) {
    console.error("Error actualizando hora:", error);
    return false; // Terminar la función en caso de error
  }
}

async function updateHourStatusToTaked(doctorId, day, hour) {
  // console.log("Buscando, day, hour", doctorId, day, hour);
  try {
    const q = query(
      collection(doc(db, "schedules", doctorId), "schedules"),
      where("day", "==", day)
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const scheduleDoc = querySnapshot.docs[0];
      const scheduleData = scheduleDoc.data();
      const hoursList = scheduleData.hours;

      // Buscar la hora específica en la lista de horas
      for (let i = 0; i < hoursList.length; i++) {
        if (hoursList[i].time === hour && hoursList[i].status === "free") {
          // Actualizar el estado de la hora a 'taked'
          hoursList[i].status = "taked";

          // Actualizar el documento en Firestore con la hora modificada
          await updateDoc(scheduleDoc.ref, {
            hours: hoursList,
          });

          // Indicar que la hora ha sido actualizada
          // console.log(`Hora actualizada del horario del doctor ${doctorId}`);
          return true; // Terminar la función
        }
      }
    } else {
      // Indicar que el documento no se encontró
      // console.log(`No se encontró el documento para el día ${day}`);
      return false; // Terminar la función
    }
  } catch (error) {
    console.error("Error actualizando hora:", error);
    return false; // Terminar la función en caso de error
  }
}

export const createNewRequest = async (
  idClient,
  idDoctor,
  day,
  hour,
  typeRequest,
  typePay,
  details,
  clientEmail,
  clientName,
  clientLastName,
  clientBirthday,
  clientGenre,
  clientPhone,
  clientRut,
  clientRegion,
  clientAddress,
  clientComune,
  selectedIsapre,
  createdBy
) => {
  try {
    const dataDoctor = await getDoctorById(idDoctor);
    const dateObject = new Date(day + "T" + hour);

    const dayBirth = clientBirthday.getDate().toString().padStart(2, "0"); // Obtener el día con dos dígitos
    const month = (clientBirthday.getMonth() + 1).toString().padStart(2, "0"); // Obtener el mes (¡recuerda sumar 1 ya que los meses son indexados desde 0!)
    const year = clientBirthday.getFullYear(); // Obtener el año
    const formattedDate = `${dayBirth}/${month}/${year}`;
    let dataToSend = {
      idClient: idClient,
      idDoctor: idDoctor,
      doctor: dataDoctor,
      client: {
        name: clientName,
        lastName: clientLastName,
        email: clientEmail,
        birthday: formattedDate,
        genre: clientGenre,
        imageProfile: "default",
        phone: clientPhone,
        rut: clientRut,
        location: {
          region: clientRegion,
          address: clientAddress,
          comuna: clientComune,
        },
      },
      dateCitation: dateObject,
      request: {
        date: day,
        hour: hour,
        type: typeRequest,
        familiarData: {
          type: "none",
        },
        payData: {
          status: "pending",
          amount: dataDoctor.valueHour,
          typePay: typePay,
        },
        details: details,
      },
      createdAt: new Date(),
      selectedIsapre: selectedIsapre,
      createdBy: createdBy,
      status: "accepted",
    };
    const req = await addDoc(collection(db, "requests"), dataToSend);

    updateHourStatusToTaked(idDoctor, day, hour);

    return true;
  } catch (error) {
    console.error("Error creating request:", error);
    return false;
  }
};
