import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Container,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  Box,
} from "@mui/material";
import {
  ArrowDropDown,
  ArrowRight,
  DateRangeOutlined,
  DateRangeTwoTone,
  Circle,
  Hail,
  LocationCity,
  MailOutline,
  Person,
} from "@mui/icons-material";
import { capitalizeAll, getActualDay } from "../utils/functions";
import { useSelector } from "react-redux";
import TopBar from "../components/top_bar";
import { getMyRequestsByDayAndType } from "../database/request";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import langEs from "date-fns/locale/es";
import moment from "moment";
import { format, isToday } from "date-fns";
import imageUnselected from "../assets/request_select.svg";
import MapComponent from "../components/map_component";
import userMaleImage from "../assets/user_male_light.png";
import userFemaleImage from "../assets/user_female_light.png";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
// import NewDrawerComponent from "../components/new_drawer";
import {
  getInvoiceByIdRequest,
  saveInvoiceResponse,
} from "../database/invoices";
import axios from "axios";
import DialogSendInvoice from "../components/dialog_send_invoice";
import LoadingInvoice from "../components/loading_invoice";
import NavPaper from "../components/nav_paper"; // Import NavPaper component

export default function Reserves() {
  const myId = useSelector((state) => state.auth.idUser);
  let dataDoctor = useSelector((state) => state.auth.userData);
  const [dateNow, setDateNow] = useState(new Date());
  const [dataRequests, setDataRequests] = useState([]);
  const [formattedDate] = getActualDay();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedTab, setSelectedTab] = useState("por atender");
  const [invoices, setInvoices] = useState([]);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [showList, setShowList] = useState(true);

  const tokenSII = "";

  useEffect(() => {
    async function getData() {
      // console.log(dataDoctor.role);
      // if (dataDoctor.role !== 'doctor') {
      //     const data = await getDoctorById(dataDoctor.idDoctor);
      //     setDataUser(data);
      // } else {
      //     setDataUser(dataDoctor);
      // }

      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      // let a = dataDoctor.role === "doctor" ? myId : dataDoctor.idDoctor;
      const nowDate = dateNow.toISOString().split("T")[0];

      let res = await getMyRequestsByDayAndType(
        nowDate,
        dataDoctor.role === "doctor" ? myId : dataDoctor.idDoctor,
        selectedTab
      );
      setDataRequests(res);
      Swal.close();
    }
    getData();
  }, []);

  // const handleGenerateBoleta = async (data) => {
  //   setLoadingCreate(true);
  //   const res = await axios.post(
  //     "https://us-central1-dfinder-5f8ae.cloudfunctions.net/generateBoletaSII",
  //     {
  //       data: {
  //         RutUsuario: "19473859-5",
  //         PasswordSII: "FIOV19473",
  //         Retencion: 1,
  //         FechaEmision: "09-04-2024",
  //         Emisor: {
  //           Direccion: "0",
  //         },
  //         Receptor: {
  //           Rut: "77.634.343-9",
  //           Nombre: "Inversiones Maxi Pos Spa",
  //           Direccion: "Aldunate 719 of 302 Temuco",
  //           Region: 9,
  //           Comuna: "Temuco",
  //         },
  //         Detalles: [
  //           {
  //             Nombre: "CONSULTORIA INFORMATICA",
  //             Valor: 10000,
  //           },
  //         ],
  //       },
  //       pass: dataDoctor.tokenSII ? dataDoctor.tokenSII : tokenSII,
  //     },
  //     {
  //       method: "POST",
  //       withCredentials: true,
  //       headers: {
  //         Authorization:
  //           "Bearer " + dataDoctor.tokenSII ? dataDoctor.tokenSII : tokenSII,
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );

  //   if (res.status === 200) {
  //     await saveInvoiceResponse(res.data, data.id);
  //     setLoadingCreate(false);
  //     Swal.fire({
  //       icon: "success",
  //       title: "¡Listo!",
  //       text: "Se ha generado la boleta exitosamente.",
  //     }).then(() => {
  //       Swal.close();
  //       loadData();
  //     });
  //   } else {
  //     setLoadingCreate(false);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: "No se pudo generar la boleta. Inténtalo nuevamente.",
  //     });
  //   }
  // };

  const handleDownloadInvoice = async () => {
    const binaryData = atob(invoices[0].data.pdfBase64);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const byteArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }
  };

  const loadData = async (date, type) => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    let res = await getMyRequestsByDayAndType(
      date,
      dataDoctor.role === "doctor" ? myId : dataDoctor.idDoctor,
      type
    );
    setDataRequests(res);
    Swal.close();
  };

  const handleSelectRequest = async (request) => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    const response = await getInvoiceByIdRequest(request.id);
    if (response.length > 0) {
      setInvoices(response);
    } else {
      setInvoices([]);
    }
    setSelectedRequest(request);
    setShowList(false);
    Swal.close();
  };

  const handleTabChange = (event, newValue) => {
    const formattedDate2 = format(dateNow, "yyyy-MM-dd");
    setSelectedTab(newValue);
    loadData(formattedDate2, newValue);
    setSelectedRequest(null);
    setShowList(true);
  };
  const tabs = [
    { value: "por atender", label: "Por atender" },
    { value: "pendiente de pago", label: "Pendiente de pago" },
    { value: "canceladas", label: "Canceladas" },
    { value: "completadas", label: "Completadas" },
    { value: "ausentes", label: "Ausentes" },
  ];
  const paperNav = (
    <List>
      <LocalizationProvider adapterLocale={langEs} dateAdapter={AdapterDateFns}>
        <DatePicker
          open={openDatePicker}
          value={dateNow}
          onChange={(date) => {
            const formattedDate = format(date, "yyyy-MM-dd");
            setDateNow(date);
            loadData(formattedDate, selectedTab);
          }}
          onClose={() => setOpenDatePicker(false)}
          sx={{ width: "100%", cursor: "pointer" }}
          slotProps={{
            textField: {
              onClick: () => setOpenDatePicker(true),
              style: { padding: "16px", cursor: "pointer" },
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <DateRangeOutlined />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <ArrowDropDown />
                  </InputAdornment>
                ),
                disableUnderline: true,
                readOnly: true,
                value: isToday(dateNow)
                  ? "Hoy"
                  : moment(dateNow).format("DD/MM/YYYY"),
              },
            },
          }}
        />
      </LocalizationProvider>
      {dataRequests.length === 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Typography
            variant="bodyLarge"
            sx={{ textAlign: "center", margin: "0px 16px", color: "#006686" }}
          >
            No hay reservas para hoy
          </Typography>
        </div>
      ) : (
        dataRequests.map((request, index) => (
          <ListItem
            key={index}
            button
            onClick={() => handleSelectRequest(request)}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              borderBottom: "1px solid rgba(192, 199, 205, 1)",
              backgroundColor:
                selectedRequest?.id === request.id ? "#DCE3E9" : "white",
              cursor: "pointer",
              margin: "0px",
              padding: "8px 16px 8px 16px",
            }}
          >
            <Avatar
              alt=""
              src={
                request.client.imageProfile === "default" &&
                request.client.genre === "Masculino"
                  ? userMaleImage
                  : request.client.imageProfile === "default" &&
                    request.client.genre === "Femenino"
                  ? userFemaleImage
                  : request.client.imageProfile
              }
              sx={{ width: 52, height: 52, marginRight: "16px" }}
            />
            <ListItemText
              primary={
                capitalizeAll(request.client.name) +
                " " +
                capitalizeAll(request.client.lastName)
              }
              secondary={
                isToday(request.dateCitation.toDate())
                  ? format(
                      request.dateCitation.toDate(),
                      "'Hoy a las' HH:mm 'hrs.'",
                      { locale: langEs }
                    )
                  : format(
                      request.dateCitation.toDate(),
                      "dd/MM/yyyy 'a las' HH:mm 'hrs.'",
                      { locale: langEs }
                    )
              }
              sx={{
                color: "#191C1E",
                fontFamily: "Nunito",
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            />
            <ListItemIcon
              sx={{
                color: selectedRequest?.id === request.id ? "#006686" : "black",
              }}
            >
              <ArrowRight />
            </ListItemIcon>
          </ListItem>
        ))
      )}
    </List>
  );

  const paperContentHeader = selectedRequest && (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <Typography
        variant="titleMedium"
        sx={{
          textAlign: "center",
          color: "black",
          fontWeight: "bold",
          fontFamily: "Nunito",
        }}
      >
        {capitalizeAll(selectedRequest.client.name) +
          " " +
          capitalizeAll(selectedRequest.client.lastName)}
      </Typography>
      <span style={{ color: "grey" }}>
        {format(
          selectedRequest.createdAt.toDate(),
          "dd 'de' MMMM 'de' yyyy 'a las' HH:mm 'hrs.'",
          { locale: langEs }
        )}
      </span>
    </Box>
  );

  const paperContent = selectedRequest ? (
    <Container
      sx={{
        p: 2,
      }}
    >
      {selectedRequest.status === "canceledByDoctor" && (
        <Container
          sx={{
            borderRadius: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            scrollbarWidth: "thin",
          }}
        >
          <div
            style={{
              padding: "5px",
              height: "20px",
              border: "1px solid #ccc",
              borderRadius: "10px",
            }}
          >
            <Typography>
              <Circle sx={{ fontSize: "10px", color: "#FFB829", mr: 1 }} />
              Cancelada por el doctor
            </Typography>
          </div>
        </Container>
      )}
      <Box
        sx={{
          border: "1px solid rgba(192, 199, 205, 1)",
          borderRadius: "12px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          py: { xs: 2, sm: 2, md: 4 },
          px: { xs: 2, sm: 2, md: 4 },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <span
            style={{
              padding: "0px",
              color: "black",
              fontWeight: "bold",
              fontFamily: "Nunito",
            }}
          >
            Estado
          </span>
          <span>
            {selectedRequest.request.payData.status === "pending" ? (
              <span>Pendiente de pago</span>
            ) : (
              <span>
                Pagado (
                {selectedRequest.request.payData.typePay === "Particular"
                  ? `${selectedRequest.request.payData.typePay}, ${selectedRequest.request.payData.payMethod}`
                  : `${selectedRequest.request.payData.typePay} | ${selectedRequest.request.payData.code}`}
                )
              </span>
            )}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Hail />
          </div>
          <div>
            <p
              style={{
                padding: "0px",
                margin: "0px",
                paddingLeft: "8px",
                fontSize: "14px",
                color: "#40484C",
              }}
            >
              Tipo
            </p>
            <p
              style={{
                padding: "0px",
                margin: "0px",
                paddingLeft: "8px",
                fontSize: "18px",
                color: "#191C1E",
              }}
            >
              {capitalizeAll(selectedRequest.request.type)}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <DateRangeTwoTone />
          </div>
          <div>
            <p
              style={{
                padding: "0px",
                margin: "0px",
                paddingLeft: "8px",
                fontSize: "14px",
                color: "#40484C",
              }}
            >
              Programada para
            </p>
            <p
              style={{
                padding: "0px",
                margin: "0px",
                paddingLeft: "8px",
                fontSize: "18px",
                color: "#191C1E",
              }}
            >
              {isToday(selectedRequest.dateCitation.toDate())
                ? format(
                    selectedRequest.dateCitation.toDate(),
                    "'Hoy a las' HH:mm 'hrs.'",
                    { locale: langEs }
                  )
                : format(
                    selectedRequest.dateCitation.toDate(),
                    "dd 'de' MMMM 'de' yyyy 'a las' HH:mm 'hrs.'",
                    { locale: langEs }
                  )}
            </p>
          </div>
        </div>
      </Box>
      <br />
      <Box
        sx={{
          border: "1px solid rgba(192, 199, 205, 1)",
          borderRadius: "12px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          py: { xs: 2, sm: 2, md: 4 },
          px: { xs: 2, sm: 2, md: 4 },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Person />
          </div>
          <div>
            <p
              style={{
                padding: "0px",
                margin: "0px",
                paddingLeft: "8px",
                fontSize: "14px",
                color: "#40484C",
              }}
            >
              Nombre
            </p>
            <p
              style={{
                padding: "0px",
                margin: "0px",
                paddingLeft: "8px",
                fontSize: "18px",
                color: "#191C1E",
              }}
            >
              {capitalizeAll(selectedRequest.client.name) +
                " " +
                capitalizeAll(selectedRequest.client.lastName)}
            </p>
          </div>
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <MailOutline />
          </div>
          <div>
            <p
              style={{
                padding: "0px",
                margin: "0px",
                paddingLeft: "8px",
                fontSize: "14px",
                color: "#40484C",
              }}
            >
              Email
            </p>
            <p
              style={{
                padding: "0px",
                margin: "0px",
                paddingLeft: "8px",
                fontSize: "18px",
                color: "#191C1E",
              }}
            >
              {capitalizeAll(selectedRequest.client.email)}
            </p>
          </div>
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <LocationCity />
          </div>
          <div>
            <p
              style={{
                padding: "0px",
                margin: "0px",
                paddingLeft: "8px",
                fontSize: "14px",
                color: "#40484C",
              }}
            >
              Dirección
            </p>
            <p
              style={{
                padding: "0px",
                margin: "0px",
                paddingLeft: "8px",
                fontSize: "18px",
                color: "#191C1E",
              }}
            >
              {capitalizeAll(selectedRequest.client.location.address)},{" "}
              {capitalizeAll(selectedRequest.client.location.comuna)},{" "}
              {selectedRequest.client.location.region}
            </p>
          </div>
        </div>
      </Box>
      {selectedRequest.request.type === "domicilio" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
            flex: 1,
          }}
        >
          <div
            style={{
              border: "1px solid rgba(192, 199, 205, 1)",
              borderRadius: "10px",
              width: "90%",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <MapComponent
              ubication={`${selectedRequest.client.location.address}, ${selectedRequest.client.location.comuna}, ${selectedRequest.client.location.region}`}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            height: "100px",
          }}
        ></div>
      )}
      {dataDoctor.role === "doctor" && selectedTab === "por atender" ? (
        <div
          style={{
            width: "100%",
            height: "8%",
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            borderTop: "1px solid rgba(0,0,0,0.3)",
          }}
        >
          <Button
            onClick={() => {
              const datosCodificados = encodeURIComponent(
                JSON.stringify(selectedRequest)
              );
              const nuevaPestana = window.open("", "_blank");
              nuevaPestana.location.href = `/patientEntry?data=${datosCodificados}`;
            }}
            style={{
              backgroundColor: "#006686",
              color: "#77F8E0",
              borderRadius: "25px",
              padding: "5px",
              marginRight: "10px",
              minWidth: "10%",
            }}
          >
            Iniciar
          </Button>
          <Button sx={{ textTransform: "none" }}>Cancelar</Button>
        </div>
      ) : (
        selectedRequest.status === "completed" ||
        (selectedRequest.status === "waiting" && (
          <>
            {invoices.length > 0 &&
              selectedRequest.request.payData.typePay === "Particular" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    sx={{ fontSize: "12px" }}
                    onClick={() => setSendEmail(true)}
                  >
                    Enviar boleta
                  </Button>
                  <Button
                    sx={{ fontSize: "12px" }}
                    onClick={() => handleDownloadInvoice()}
                  >
                    Descargar boleta
                  </Button>
                </div>
              )}
          </>
        ))
      )}
    </Container>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
      }}
    >
      <img
        src={imageUnselected}
        alt="selectImage"
        style={{ alignSelf: "center", width: "60%", height: "60%" }}
      />
      <Typography
        variant="titleMedium"
        sx={{ textAlign: "center", marginTop: "20px", color: "black" }}
      >
        Selecciona una reserva para ver más detalles
      </Typography>
    </div>
  );

  return (
    <NavPaper
      paperTitle={
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          value={selectedTab}
          onChange={handleTabChange}
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
      }
      paperNav={paperNav}
      paperContentHeader={paperContentHeader}
      paperContent={paperContent}
      showList={showList}
      setShowList={setShowList}
    />
  );
}
