import {
  Box,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Button,
  ListItemIcon,
  MenuItem,
  Select,
  Switch,
  Typography,
  Grid,
  CardActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getOperations, getTypes } from "../database/types";
import { useSelector } from "react-redux";
import { getDoctorById, updateDoctor, getIsapres } from "../database/doctors";
import styled from "@emotion/styled";
import {  DeleteOutline } from "@mui/icons-material";
import loadingSVG from "../assets/logo_Loading.gif";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { updateClinic } from "../database/clinic";
// import Select from 'react-select';
export default function ProfileProfesionalData() {
  const myId = useSelector((state) => state.auth.idUser);

  const dataDoctor = useSelector((state) => state.auth.userData);
  const idDoctor = useSelector((state) => state.auth.idUser);
  const [operationsList, setOperationsList] = useState([]);
  const [typeDoctor, setTypeDoctor] = useState([]);
  const [videocallActive, setVideocallActive] = useState(false);
  const [presentialActive, setPresentialActive] = useState(false);
  const [domicileActive, setDomicileActive] = useState(false);
  const [fonasaActive, setFonasaActive] = useState(false);
  const [isapreActive, setIsapreActive] = useState(false);
  const [particularActive, setParticularActive] = useState(false);

  const [operationSelectedForSelect, setOperationSelectedForSelect] =
    useState("1");
  const [specialtySelectedForSelect, setSpecialtySelectedForSelect] =
    useState("1");
  const [isapreSelectedForSelect, setIsapreSelectedForSelect] = useState("1");
  //Data DB
  const [allOperations, setAllOperations] = useState([]);
  const [allSpecialty, setAllSpecialty] = useState([]);
  const [allIsapres, setAllIsapres] = useState([]);

  //Handler local array
  const [localOperations, setLocalOperations] = useState([]);
  const [localSpecialties, setLocalSpecialties] = useState([]);
  const [localIsapres, setLocalIsapres] = useState([]);

  // const handleChangeSpeciality = (element) => {
  //   const specialtyTemp = element.map((obj) => ({ value: obj.value }));
  //   // console.log(specialtyTemp);
  //   setTypeDoctor(specialtyTemp);
  //   // console.log(typeDoctor);
  // };
  // const handleChangeOperations = (element) => {
  //   const operationTemp = element.map((obj) => ({ value: obj.value }));
  //   setOperationsList(operationTemp);
  // };

  const notify = (text, type) =>
    toast(text, {
      type: type,
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      color: "green",
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  // Estilo personalizado para el interruptor
  const IOSSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#006686   ",
          opacity: 1,
          border: 0,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#006686",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleNewSpecialty = (event) => {
    const newSpecialty = {
      label: event.target.value,
      value: event.target.value,
    };

    try {
      setLocalSpecialties((prevSpecialties) => [
        ...prevSpecialties,
        newSpecialty,
      ]);
      setAllSpecialty((allSpecialty) =>
        allSpecialty.filter((item) => item.name !== event.target.value)
      );
      notify("Especialidad agregada", "success");
      setSpecialtySelectedForSelect("1");
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewOperation = (event) => {
    // console.log("agrenado operacion", event.target.value);
    const newOperation = {
      label: event.target.value,
      value: event.target.value,
    };

    try {
      setLocalOperations((prevOperations) => [...prevOperations, newOperation]);
      setAllOperations((allOperations) =>
        allOperations.filter((item) => item.operation !== event.target.value)
      );
      notify("Operación agregada", "success");
      setOperationSelectedForSelect("1");
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewIsapre = (event) => {
    // console.log("agrenado operacion", event.target.value);
    const newIsapre = {
      label: event.target.value,
      value: event.target.value,
    };

    try {
      setLocalIsapres((prevOperations) => [...prevOperations, newIsapre]);
      setAllIsapres((allIsapres) =>
        allIsapres.filter((item) => item.name !== event.target.value)
      );
      notify("Isapre agregada", "success");
      setIsapreSelectedForSelect("1");
    } catch (error) {
      console.log(error);
    }
  };
  const deleteSpecialty = (index) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Deseas eliminar esta especialidad?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006686",
      cancelButtonColor: "grey",
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        const tempSpecialties = localSpecialties.filter((el, i) => i !== index);
        setLocalSpecialties(tempSpecialties);
        notify("Especialidad eliminada", "success");
      }
    });
  };

  const deleteOperation = (index) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Deseas eliminar esta operación?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006686",
      cancelButtonColor: "grey",
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        const tempOperations = localOperations.filter((el, i) => i !== index);
        setLocalOperations(tempOperations);
        notify("Operación eliminada", "success");
      }
    });
  };
  const deleteIsapre = (index) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Deseas eliminar esta Isapre?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006686",
      cancelButtonColor: "grey",
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        const tempIsapres = localIsapres.filter((el, i) => i !== index);
        setLocalIsapres(tempIsapres);
        notify("Isapre eliminada", "success");
      }
    });
  };
  useEffect(() => {
    async function getData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const tempData = await getDoctorById(idDoctor);

      if (dataDoctor.role !== "medicalCenter") {
        const allOperationsData = await getOperations();
        const allSpecialtyData = await getTypes();
        // console.log("allIsapreData", allIsapreData);
        setLocalOperations(tempData.operationsList);
        setLocalSpecialties(tempData.typeDoctor);
        //Quiero remover los elementos que ya estan en el arreglo local para no poder seleccionarlo 2 veces
        setAllOperations(
          allOperationsData.filter(
            (operation) =>
              !localOperations.some(
                (localOp) => localOp.value == operation.operation
              )
          )
        );
        setAllSpecialty(
          allSpecialtyData.filter(
            (specialty) =>
              !localSpecialties.some(
                (localSpec) => localSpec.value == specialty.name
              )
          )
        );
      }

      if (!tempData.medicalCenterId) {
        // console.log("no es centro medico");
        const allIsapreData = await getIsapres();

        setLocalIsapres(tempData.selectedIsapre);

        setAllIsapres(
          allIsapreData.filter(
            (isapre) =>
              !localIsapres.some(
                (localIsapre) => localIsapre.value == isapre.name
              )
          )
        );
        if (tempData.queryTypes.presencial) {
          setPresentialActive(true);
        }
        if (tempData.queryTypes.videollamada) {
          setVideocallActive(true);
        }
        if (tempData.queryTypes.domicilio) {
          setDomicileActive(true);
        }
        if (tempData.previsions.fonasa) {
          setFonasaActive(true);
        }
        if (tempData.previsions.isapre) {
          setIsapreActive(true);
        }
        if (tempData.previsions.particular) {
          setParticularActive(true);
        }
      }

      // console.log(tempData);
      Swal.close();
    }
    getData();
  }, [idDoctor, dataDoctor]);

  const updateProfesionalData = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    const countEmpty = [];
    const countUndefined = [];
    let cleanLocalOperations = localOperations
      .filter((item) => item.value !== undefined)
      .map((item) => ({ value: item.value }));

    let cleanLocalSpecialties = localSpecialties
      .filter((item) => item.value !== undefined)
      .map((item) => ({ value: item.value }));

    let cleanLocalIsapres = localIsapres
      .filter((item) => item.value !== undefined)
      .map((item) => ({ value: item.value }));

    const data = {
      queryTypes: {
        domicilio: domicileActive,
        presencial: presentialActive,
        videollamada: videocallActive,
      },
      previsions: {
        fonasaActive: fonasaActive,
        isapreActive: isapreActive,
        particularActive: particularActive,
      },
      operationsList: cleanLocalOperations,
      typeDoctor: cleanLocalSpecialties,
      selectedIsapre: cleanLocalIsapres,
    };

    if (domicileActive !== undefined) {
      if (domicileActive !== "") {
        data.queryTypes.domicilio = domicileActive;
      } else {
        countEmpty.push("Domicilio");
      }
    } else {
      countUndefined.push("1");
    }
    if (presentialActive !== undefined) {
      if (presentialActive !== "") {
        data.queryTypes.presencial = presentialActive;
      } else {
        countEmpty.push("Presencial");
      }
    } else {
      countUndefined.push("2");
    }
    if (videocallActive !== undefined) {
      if (videocallActive !== "") {
        data.queryTypes.videollamada = videocallActive;
      } else {
        countEmpty.push("Videollamada");
      }
    } else {
      countUndefined.push("3");
    }
    if (fonasaActive !== undefined) {
      if (fonasaActive !== "") {
        data.previsions.fonasa = fonasaActive;
      } else {
        countEmpty.push("Fonasa");
      }
    } else {
      countUndefined.push("4");
    }
    if (isapreActive !== undefined) {
      if (isapreActive !== "") {
        data.previsions.isapre = isapreActive;
      } else {
        countEmpty.push("Isapre");
      }
    } else {
      countUndefined.push("5");
    }
    if (particularActive !== undefined) {
      if (particularActive !== "") {
        data.previsions.particular = particularActive;
      } else {
        countEmpty.push("Particular");
      }
    } else {
      countUndefined.push("6");
    }
    if (cleanLocalOperations.length === 0) {
      countEmpty.push("Operaciones");
    }
    if (cleanLocalIsapres.length === 0) {
      countEmpty.push("Isapres");
    }
    if (cleanLocalSpecialties.length === 0) {
      countEmpty.push("Especialidades");
    }

    if (countUndefined.length === 2) {
      Swal.fire({
        icon: "info",
        title: "No se han realizado cambios",
        // text:"No se ralizo ningun cambio en los datos personales"
      });
    } else if (countEmpty.length > 0) {
      Swal.fire({
        icon: "info",
        title: "Campos vacios",
        text: "Por favor complete los campos: " + countEmpty.join(", "),
      });
    } else {
      // console.log("Guardando", data);
      const update = await updateDoctor(data, myId);
      // console.log(update);
      if (update) {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Actualizado",
          text: "Se han actualizado los datos correctamente",
        });
      } else {
        Swal.close();

        Swal.fire({
          icon: "error",
          title: "Upss..",
          text: "Se ha producido un error al momento de actualizar los datos",
        });
      }
    }
  };
  const updateMedicalCenterData = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    const countEmpty = [];
    const countUndefined = [];

    let cleanLocalIsapres = localIsapres
      .filter((item) => item.value !== undefined)
      .map((item) => ({ value: item.value }));

    const data = {
      queryTypes: {
        domicilio: domicileActive,
        presencial: presentialActive,
        videollamada: videocallActive,
      },
      previsions: {
        fonasaActive: fonasaActive,
        isapreActive: isapreActive,
        particularActive: particularActive,
      },
      selectedIsapre: cleanLocalIsapres,
    };

    if (presentialActive !== undefined) {
      if (presentialActive !== "") {
        if (presentialActive) {
          data.queryTypes.presencial = presentialActive;
        } else {
          countEmpty.push("Presencial");
        }
      } else {
        countEmpty.push("Presencial");
      }
    } else {
      countUndefined.push("2");
    }

    if (fonasaActive !== undefined) {
      if (fonasaActive !== "") {
        data.previsions.fonasa = fonasaActive;
      } else {
        countEmpty.push("Fonasa");
      }
    } else {
      countUndefined.push("4");
    }
    if (isapreActive !== undefined) {
      if (isapreActive !== "") {
        data.previsions.isapre = isapreActive;
      } else {
        countEmpty.push("Isapre");
      }
    } else {
      countUndefined.push("5");
    }
    if (particularActive !== undefined) {
      if (particularActive !== "") {
        data.previsions.particular = particularActive;
      } else {
        countEmpty.push("Particular");
      }
    } else {
      countUndefined.push("6");
    }

    if (cleanLocalIsapres.length === 0) {
      countEmpty.push("Isapres");
    }

    if (countUndefined.length === 2) {
      Swal.fire({
        icon: "info",
        title: "No se han realizado cambios",
        // text:"No se ralizo ningun cambio en los datos personales"
      });
    } else if (countEmpty.length > 0) {
      Swal.fire({
        icon: "info",
        title: "Campos vacios",
        text: "Por favor complete los campos: " + countEmpty.join(", "),
      });
    } else {
      const update = await updateClinic(data, myId);
      if (update) {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Actualizado",
          text: "Se han actualizado los datos correctamente",
        });
      } else {
        Swal.close();

        Swal.fire({
          icon: "error",
          title: "Upss..",
          text: "Se ha producido un error al momento de actualizar los datos",
        });
      }
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 0,
        height: '100%',
      }}
    >
       <Box
        sx={{
          p: 2,
          pb: 12,
          flexGrow: 1,
          overflowY: "scroll",
        }}
      >
      <Grid container spacing={2}
      sx={{
      }}
      >
        <Grid item xs={12} md={6}>
          {!dataDoctor.medicalCenterId && (
            <>
           
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontFamily: 'Nunito',
                  fontSize: '16px',
                }}
              >
                Tipos de atención (Seleccione al menos una opción)
              </Typography>
              <List
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                }}
              >
                <FormControlLabel
                  sx={{ paddingLeft: '16px' }}
                  control={
                    <IOSSwitch
                      checked={presentialActive}
                      onChange={() => setPresentialActive(!presentialActive)}
                    />
                  }
                  label="Presencial"
                />
                <FormControlLabel
                  sx={{ paddingLeft: '16px' }}
                  control={
                    <IOSSwitch
                      checked={videocallActive}
                      onChange={() => setVideocallActive(!videocallActive)}
                    />
                  }
                  label="Videollamada"
                />
                {dataDoctor.role !== 'medicalCenter' && (
                  <FormControlLabel
                    sx={{ paddingLeft: '16px' }}
                    control={
                      <IOSSwitch
                        checked={domicileActive}
                        onChange={() => setDomicileActive(!domicileActive)}
                      />
                    }
                    label="A domicilio"
                  />
                )}
              </List>
            </>
          )}
          {dataDoctor.role !== 'medicalCenter' && (
            <div>
              <Box sx={{display: "flex", py: 2}}>
                <Typography variant="titleSmall">
                  Operaciones
                </Typography>
              </Box>
              {localOperations.length >= 1 ? (
                <>
                  {localOperations.map((el, index) => (
                    <ListItem
                      key={index}
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      {el.value}
                      <ListItemIcon
                        onClick={() => deleteOperation(index)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <DeleteOutline />
                      </ListItemIcon>
                    </ListItem>
                  ))}
                </>
              ) : null}
              <Select
                defaultValue={'1'}
                placeholder="Selecciona una operación"
                variant="filled"
                id="select-operation"
                onChange={handleNewOperation}
                label="Banco"
                fullWidth
                value={operationSelectedForSelect}
                onFocus={() => {
                  setAllOperations(
                    allOperations.filter(
                      (operation) =>
                        !localOperations.some(
                          (localOp) => localOp.value == operation.operation
                        )
                    )
                  );
                }}
              >
                <MenuItem disabled key={'selecciona operation'} value={'1'}>
                  Selecciona una operación
                </MenuItem>
                {allOperations.map((option, index) => (
                  <MenuItem
                    sx={{
                      backgroundColor:
                        index % 2 === 0
                          ? 'rgba(248, 249, 251, 1)'
                          : 'rgba(220, 227, 233, 1)',
                    }}
                    key={index}
                    value={option.operation}
                  >
                    {option.operation}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {!dataDoctor.medicalCenterId && (
            <>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontFamily: 'Nunito',
                  fontSize: '16px',
                }}
              >
                Tipos de pago (Seleccione al menos una opción)
              </Typography>
              <List
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                }}
              >
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={fonasaActive}
                      onChange={() => setFonasaActive(!fonasaActive)}
                    />
                  }
                  label="Fonasa"
                />
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={isapreActive}
                      onChange={() => setIsapreActive(!isapreActive)}
                    />
                  }
                  label="Isapre"
                />
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={particularActive}
                      onChange={() => setParticularActive(!particularActive)}
                    />
                  }
                  label="Particular"
                />
              </List>
              {isapreActive && (
                <div>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontFamily: 'Nunito',
                      fontSize: '16px',
                    }}
                  >
                    Isapres
                  </Typography>
                  {localIsapres.length >= 1 ? (
                    <>
                      {localIsapres.map((el, index) => (
                        <ListItem
                          key={index}
                          sx={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                          {el.value}
                          <ListItemIcon
                            onClick={() => deleteIsapre(index)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <DeleteOutline />
                          </ListItemIcon>
                        </ListItem>
                      ))}
                    </>
                  ) : (
                    <>No data</>
                  )}
                  <Select
                    defaultValue={'1'}
                    placeholder="Selecciona una isapre"
                    variant="filled"
                    id="select-isapre"
                    label="isapres"
                    fullWidth
                    onChange={handleNewIsapre}
                    onFocus={() => {
                      setAllIsapres(
                        allIsapres.filter(
                          (specialty) =>
                            !localIsapres.some(
                              (localSpec) => localSpec.value == specialty.name
                            )
                        )
                      );
                    }}
                    value={isapreSelectedForSelect}
                  >
                    <MenuItem disabled key={'selecciona isapre'} value={'1'}>
                      Selecciona una isapre
                    </MenuItem>
                    {allIsapres.map((option, index) => (
                      <MenuItem
                        sx={{
                          backgroundColor:
                            index % 2 === 0
                              ? 'rgba(248, 249, 251, 1)'
                              : 'rgba(220, 227, 233, 1)',
                        }}
                        key={index}
                        value={option.name}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
            </>
          )}
          {dataDoctor.role !== 'medicalCenter' && (
            <div>
              <Box sx={{display: "flex", py: 2}}>
                <Typography variant="titleSmall">
                  Mis Especialidades
                </Typography>
              </Box>
              {localSpecialties.length >= 1 ? (
                <>
                  {localSpecialties.map((el, index) => (
                    <ListItem
                      key={index}
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      {el.value}
                      <ListItemIcon
                        onClick={() => deleteSpecialty(index)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <DeleteOutline />
                      </ListItemIcon>
                    </ListItem>
                  ))}
                </>
              ) : (
                <>No data</>
              )}
              <Select
                defaultValue={'1'}
                placeholder="Selecciona una especialidad"
                variant="filled"
                id="select-specialty"
                label="specialties"
                fullWidth
                onChange={handleNewSpecialty}
                onFocus={() => {
                  setAllOperations(
                    allOperations.filter(
                      (operation) =>
                        !localOperations.some(
                          (localOp) => localOp.value == operation.operation
                        )
                    )
                  );
                  setAllSpecialty(
                    allSpecialty.filter(
                      (specialty) =>
                        !localSpecialties.some(
                          (localSpec) => localSpec.value == specialty.name
                        )
                    )
                  );
                }}
                value={specialtySelectedForSelect}
              >
                <MenuItem disabled key={'selecciona specialty'} value={'1'}>
                  Selecciona una especialidad
                </MenuItem>
                {allSpecialty.map((option, index) => (
                  <MenuItem
                    sx={{
                      backgroundColor:
                        index % 2 === 0
                          ? 'rgba(248, 249, 251, 1)'
                          : 'rgba(220, 227, 233, 1)',
                    }}
                    key={index}
                    value={option.name}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </Grid>
      </Grid>
      </Box>
      <ToastContainer />
      <Box
        sx={{
          zIndex: "200",
          width: "100%",
          backgroundColor: "white",
          position: "sticky",
          bottom: { xs: "0px", sm: "0px", md: "60px" },
        }}
      >
        <Divider/>
        <CardActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          py: 3,
          px: 2,
          width: "100%",
        }}
        >
         <Button
            variant="contained"
            disableElevation
            onClick={() => {
              dataDoctor.role === 'medicalCenter'
                ? updateMedicalCenterData()
                : updateProfesionalData();
            }}
          >
            Guardar cambios
          </Button>
        </CardActions>
      </Box>

    </Box>
  );
  
  
}
