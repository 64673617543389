import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getMyRequestsByDayForChart } from '../database/request'
import loadingSVG from "../assets/logo_Loading.gif";
import Swal from "sweetalert2";
import { Bar, BarChart, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';


export default function DialogDailyGains({ open, close, date, myId }) {

    const [totals, setTotals] = useState([])
    const [totalEfectivo, setTotalEfectivo] = useState(0)
    const [totalTarjeta, setTotalTarjeta] = useState(0)
    const [totalFonasa, setTotalFonasa] = useState(0)
    const [totalIsapre, setTotalIsapre] = useState(0)
    const [gainsWeek, setGainsWeek] = useState(0)

    const CustomBar = (props) => {
        const { fill, x, y, width, height } = props;
        return (
            <g>
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={fill}
                    rx={5}
                    ry={5}
                />
            </g>
        );
    };

    useEffect(() => {
        async function getDailyGains() {
            Swal.fire({
                imageUrl: loadingSVG,
                imageWidth: 300,
                imageHeight: 300,
                title: "Cargando...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
            const response = await getMyRequestsByDayForChart(date, myId);
            let totalEfectivo = 0;
            let totalTarjeta = 0;
            let totalFonasa = 0;
            let totalIsapre = 0;
            let gainsWeek = 0;
            response.forEach((request) => {
                if (request.request.payData.typePay === "Particular" && request.request.payData.payMethod === "Efectivo") {
                    totalEfectivo += parseInt(request.request.payData.amount.replace(/\$|\.+/g, ""));
                } else if (request.request.payData.payMethod === "webpay" && request.request.payData.typePay === "Particular") {
                    totalTarjeta += parseInt(request.request.payData.amount.replace(/\$|\.+/g, ""));
                } else if (request.request.payData.typePay === "Fonasa") {
                    totalFonasa += parseInt(request.request.payData.amount.replace(/\$|\.+/g, ""));
                } else if (request.request.payData.typePay === "Isapre") {
                    totalIsapre += parseInt(request.request.payData.amount.replace(/\$|\.+/g, ""));
                }
            })
            const finalData = [
                { day: "Efectivo", Ganancias: totalEfectivo },
                { day: "Tarjeta", Ganancias: totalTarjeta },
                { day: "Fonasa", Ganancias: totalFonasa },
                { day: "Isapre", Ganancias: totalIsapre },
            ];
            setTotals(finalData)
            console.log(finalData);
            Swal.close();
        }
        getDailyGains();
    }, [])

    return (
        <>
            <Dialog
                open={open}
                onClose={close}
                PaperProps={{
                    style: {
                        width: "70vw",
                        backgroundColor: "#EEE8F4",
                        borderRadius: "25px 25px 25px 25px",
                        zIndex: 1
                    },
                }}
            >
                <Container
                    sx={{
                        color: "black",
                        // width: "70vw",
                        // height: "80vh",
                    }}
                >
                    <DialogTitle
                        sx={{
                            color: "black",
                            fontWeight: 300,
                            fontSize: "20px",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        Ganancias del día {date}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{
                            borderRadius: "25px 25px 25px 25px",
                            width: "100%",
                            backgroundColor: "white",
                            height: "27vh",
                        }}>
                            <ResponsiveContainer style={{
                                width: '100%'
                            }}>
                                <BarChart
                                    tooltip={{ trigger: 'item' }}
                                    data={totals}
                                    style={{
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        zIndex: 2
                                    }}
                                    margin={{ top: 1, right: 1, left: 1, bottom: 1 }}
                                >
                                    <XAxis dataKey="day" />
                                    <YAxis domain={[0, 'auto']} />
                                    <Tooltip PopperProps={{
                                        disablePortal: true,
                                    
                                    }}/>
                                    <Bar
                                        shape={CustomBar}
                                        background
                                        name=""
                                        dataKey="Ganancias"
                                        fill="#006686"
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={close}
                            sx={{
                                width: "100px",
                                height: "30px",
                                borderRadius: "25px 25px 25px 25px",
                                backgroundColor: "#006686",
                                color: "white",
                                '&:hover': {
                                    backgroundColor: "#004D64",

                                }
                            }}
                        >
                            Cerrar
                        </Button>
                    </DialogActions>
                </Container>
            </Dialog>
        </>
    )
}
