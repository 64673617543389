// CustomThemeProvider.js
import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const themeOptions = {
  palette: {
    primary: {
      main: '#166683',
      dark: '#8CCFF0',
      contrastText: '#9FF2E0'
    },
    secondary: {
      main: '#575992',
      dark: '#C0C1FF',
      contrastText: '#292A60'
    },
    tertiary: {
      main: '#83D5C4',
      dark: '#166683',
      contrastText: '#003730'
    },
    background: {
      default: '#C0E8FF',
      paper: 'white',
    },
  },
  typography: {
    fontFamily: 'Nunito',
    displayLarge: {
      fontSize: '3.563rem',
      fontWeight: '400',
      lineHeight: '1.2',
      letterSpacing: '-0.01562em',
    },
    displayMedium: {
      fontSize: '2.813rem',
      fontWeight: '400',
      lineHeight: '1.2',
      letterSpacing: '-0.00833em',
    },
    displaySmall: {
      fontSize: '2.25rem',
      fontWeight: '400',
      lineHeight: '1.2',
      letterSpacing: '0em',
    },
    headlineLarge: {
      fontSize: '2rem',
      fontWeight: '400',
      lineHeight: '1.2',
      letterSpacing: '0em',
    },
    headlineMedium: {
      fontSize: '1.75rem',
      fontWeight: '400',
      lineHeight: '1.2',
      letterSpacing: '0em',
    },
    headlineSmall: {
      fontSize: '1.5rem',
      fontWeight: '400',
      lineHeight: '1.2',
      letterSpacing: '0em',
    },
    titleLarge: {
      fontSize: '1.375rem',
      fontWeight: '700',
      lineHeight: '1.2',
      letterSpacing: '0em',
    },
    titleMedium: {
      fontSize: '1.125rem',
      fontWeight: '700',
      lineHeight: '1.2',
      letterSpacing: '0.0075em',
    },
    titleSmall: {
      fontSize: '1rem',
      fontWeight: '700',
      lineHeight: '1.2',
      letterSpacing: '0.0075em',
    },
    labelLarge: {
      fontSize: '0.875rem',
      fontWeight: '700',
      lineHeight: '1.2',
      letterSpacing: '0.01938em',
    },
    labelMedium: {
      fontSize: '0.75rem',
      fontWeight: '700',
      lineHeight: '1.2',
      letterSpacing: '0.025em',
    },
    labelSmall: {
      fontSize: '0.625rem',
      fontWeight: '700',
      lineHeight: '1.2',
      letterSpacing: '0.03125em',
    },
    bodyLarge: {
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.5',
      letterSpacing: '0.03125em',
    },
    bodyMedium: {
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5',
      letterSpacing: '0.01786em',
    },
    bodySmall: {
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '1.5',
      letterSpacing: '0.03333em',
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#689f38',
          color: '#fff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          textTransform: 'none',
          padding: '10px 20px',
        },
        containedPrimary: {
          backgroundColor: '#166683',
          '&:hover': {
            backgroundColor: '#14576a',
          },
        },
        outlinedSecondary: {
          borderColor: '#575992',
          color: '#575992',
          '&:hover': {
            borderColor: '#44466b',
            color: '#44466b',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#166683',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.875rem',
          backgroundColor: '#166683',
        },
        arrow: {
          color: '#166683',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.MuiTab-root': {
            fontSize: '0.75rem',
            fontWeight: '700',
            lineHeight: '1.2',
            letterSpacing: '0.025em',
          },
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          color: '#166683',
          borderRadius: '8px',
          borderWidth: '1px',
          borderColor: '#166683 !important',
          border: '2px solid',
          '&.Mui-selected': {
            backgroundColor: '#166683',
            color: '#83D5C4',
          },
          '&.Mui-focusVisible': {
            backgroundColor: '#166683',
            color: '#83D5C4',
          },
          '&.Mui-selected.Mui-focusVisible': {
            backgroundColor: '#166683',
            color: '#83D5C4',
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#166683',
            color: '#83D5C4',
          },
          '&.Mui-focusVisible': {
            backgroundColor: '#166683',
            color: '#83D5C4',
          },
          '&.Mui-selected.Mui-focusVisible': {
            backgroundColor: '#166683',
            color: '#83D5C4',
          },
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);

const CustomThemeProvider = ({ children }) => {
  useEffect(() => {
    const applySpecialCaseClass = () => {
      const formControls = document.querySelectorAll('.MuiFormControl-root');
      formControls.forEach((control) => {
        const label = control.querySelector('label');
        const input = control.querySelector('.MuiFilledInput-input');
        if (label && input) {
          input.classList.add('special-case');
        }
      });
    };

    // Apply the class on mount
    applySpecialCaseClass();

    // Observe changes to the DOM to reapply the class if needed
    const observer = new MutationObserver(applySpecialCaseClass);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
