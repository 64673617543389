import { Container, Typography } from "@mui/material";
import React from "react";

export default function AdminViewBankData({ doctorSelected }) {
  return (
    <Container>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #DCE3E9",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#71787D",
            fontSize: "1em",
          }}
        >
          Rut del titular
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "1em",
          }}
        >
          {doctorSelected.rut}
        </Typography>
      </Container>

      {doctorSelected.role !== "medicalCenter" && (
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid #DCE3E9",
            padding: "10px",
            marginTop: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#71787D",
              fontSize: "1em",
            }}
          >
            Fecha de nacimiento
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontSize: "1em",
            }}
          >
            {doctorSelected.birthdate}
          </Typography>
        </Container>
      )}
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #DCE3E9",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#71787D",
            fontSize: "1em",
          }}
        >
          Nombre
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "1em",
          }}
        >
          {doctorSelected.name}
        </Typography>
      </Container>
      {doctorSelected.role !== "medicalCenter" && (
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid #DCE3E9",
            padding: "10px",
            marginTop: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#71787D",
              fontSize: "1em",
            }}
          >
            Apellido
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontSize: "1em",
            }}
          >
            {doctorSelected.lastName}
          </Typography>
        </Container>
      )}

      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #DCE3E9",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#71787D",
            fontSize: "1em",
          }}
        >
          Email
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "1em",
          }}
        >
          {doctorSelected.email}
        </Typography>
      </Container>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #DCE3E9",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#71787D",
            fontSize: "1em",
          }}
        >
          Banco
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "1em",
          }}
        >
          {doctorSelected.bankAccount.bankSelected}
        </Typography>
      </Container>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #DCE3E9",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#71787D",
            fontSize: "1em",
          }}
        >
          Tipo de cuenta
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "1em",
          }}
        >
          {doctorSelected.bankAccount.typeAccountSelected}
        </Typography>
      </Container>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #DCE3E9",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#71787D",
            fontSize: "1em",
          }}
        >
          Número de cuenta
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "1em",
          }}
        >
          {doctorSelected.bankAccount.numAccount}
        </Typography>
      </Container>
    </Container>
  );
}
