import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import Swal from 'sweetalert2';
import loadingSVG from "../assets/logo_Loading.gif";


const DialogSendInvoice = ({ open, onClose, invoices, tokenSII }) => {
    const [inputValue, setInputValue] = useState('');

    const handleClose = () => {
        onClose();
        setInputValue('');
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleConfirm = async () => {
        if (inputValue !== '') {
            Swal.fire({
                imageUrl: loadingSVG,
                imageWidth: 300,
                imageHeight: 300,
                title: "Enviando boleta...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });

            const response = await axios.post('https://us-central1-dfinder-5f8ae.cloudfunctions.net/sendEmailInvoice', {
                email: inputValue,
                folio: invoices[0].data.folio,
                base64: invoices[0].data.pdfBase64,
            });


            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "¡Listo!",
                    text: "Se ha enviado la boleta exitosamente.",
                }).then(() => {
                    handleClose();
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "No se pudo enviar la boleta. Inténtalo nuevamente.",
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Debes ingresar un correo válido.",
            });
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Ingresar correo de destinatario: </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Correo"
                    type="email"
                    fullWidth
                    value={inputValue}
                    onChange={handleInputChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogSendInvoice;
