import { addDoc, collection, doc, serverTimestamp, updateDoc, getDocs } from "firebase/firestore";
import { db } from "./config";


export async function createRegisterRequest(data) {
    try {
        const docRef = collection(db, "register_requests");
        await addDoc(docRef, { status: 'pending', createdAt: serverTimestamp(),typeRequest :"doctor", data });
        return true;
    } catch (error) {
        console.error("Error creating register request:", error);
    }
}
// x
export async function getRegisterRequest() {
    const types = await getDocs(collection(db, "register_requests"));
    return types.docs.map((doc) => {
        const data = doc.data();
        const id = doc.id;
        return { id, ...data };
    });
}
export async function acceptRegisterRequest(idRegisterRequest) {
    try {
        const requestRef = doc(db, "register_requests", idRegisterRequest);
        await updateDoc(requestRef, {
            status: "accepted",
            password: ""
        });
        // console.log("hecho");
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}
export async function declinedRegisterRequest(idRegisterRequest) {
    try {
        const requestRef = doc(db, "register_requests", idRegisterRequest);
        await updateDoc(requestRef, {
            status: "declined",
            password: ""
        });
        // console.log("hecho");
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}